import { ApiServiceFactory } from 'mid-addin-lib/services/api.service';
import { ServiceTypes } from 'mid-addin-lib';
import { getAllAccAccounts, getAllProjects } from 'mid-addin-lib/utils/workspace';
import {
  AccAccount,
  AccProject,
  BIM360CurrentVersion,
  BIM360Derivative,
  BIM360DerivativeChild,
  BIM360Document,
  Environment,
  ENVIRONMENT,
  ForgeDMProjectFolder,
} from 'mid-types';
import {
  BIM360AccountsFetchError,
  BIM360FolderDocumentsFetchError,
  BIM360FoldersFetchError,
  BIM360ManifestFetchError,
  BIM360ProjectsFetchError,
  logError,
  ThumbnailError,
  BIM360CurrentVersionProcessResult,
  BIM360CurrentVersionProcessState,
} from 'mid-utils';
import text from '../global/text.json';
import { Paths, PathsConfigMap, PathTypes } from 'mid-addin-lib';

const bim360ServiceText = text.bim360Service;
const currentEnv = (process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;

export const getAccounts = async (token: string): Promise<AccAccount[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    token,
    env: currentEnv,
  });

  try {
    const accountAPIPath = `${Paths.EA_API_PATH}/${Paths.ACCOUNT_ENTITLEMENTS_PATH}?limit=100&offset=0`;
    const accounts = await getAllAccAccounts(apiService, accountAPIPath);
    return accounts;
  } catch (e) {
    logError(e);
    throw new BIM360AccountsFetchError(bim360ServiceText.accountsLoadError);
  }
};

export const getProjects = async (token: string): Promise<AccProject[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    token,
    env: currentEnv,
  });

  try {
    const projectsPath = `${Paths.EA_API_PATH}/${Paths.PROJECT_ENTITLEMENTS_PATH}?limit=200`;
    const projects = await getAllProjects(apiService, projectsPath);
    return projects;
  } catch (e) {
    logError(e);
    throw new BIM360ProjectsFetchError(bim360ServiceText.projectsLoadError);
  }
};

// return the ancestor tree for a specified folder
export const getFolderTree = async (
  token: string,
  projectId: string,
  folderUrn: string,
): Promise<ForgeDMProjectFolder[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    token,
    env: currentEnv,
  });
  const url = `${
    PathsConfigMap[PathTypes.DM_PROJECTS_PATH][currentEnv].path
  }/${projectId}/folders/${folderUrn}/folder_tree?include_permission=false`;
  const { response } = apiService.abortableGet(url);

  try {
    return (await response).data.folder_tree;
  } catch (e) {
    logError(e);
    throw new BIM360FoldersFetchError(bim360ServiceText.foldersTreeLoadError, { projectId, folderUrn });
  }
};

export const getFolders = async (token: string, projectId: string): Promise<ForgeDMProjectFolder[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    token,
    env: currentEnv,
  });
  const url = `${PathsConfigMap[PathTypes.DM_PROJECTS_PATH][currentEnv].path}/${projectId}/folders`;
  const { response } = apiService.abortableGet(url);

  try {
    return (await response).data.folders;
  } catch (e) {
    logError(e);
    throw new BIM360FoldersFetchError(bim360ServiceText.foldersLoadError, { projectId });
  }
};

export const getSubFolders = async (
  token: string,
  projectId: string,
  folderUrn: string,
): Promise<ForgeDMProjectFolder[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    token,
    env: currentEnv,
  });
  const url = `${PathsConfigMap[PathTypes.DM_PROJECTS_PATH][currentEnv].path}/${projectId}/folders/${folderUrn}`;
  const { response } = apiService.abortableGet(url);

  try {
    return (await response).data.folders;
  } catch (e) {
    logError(e);
    throw new BIM360FoldersFetchError(bim360ServiceText.subfoldersLoadError, { projectId, folderUrn });
  }
};

const isCurrentDocVersionReady = (currentVersion: BIM360CurrentVersion): Boolean =>
  currentVersion.process_state === BIM360CurrentVersionProcessState.PROCESSING_COMPLETE &&
  currentVersion.process_result === BIM360CurrentVersionProcessResult.PROCESSING_SUCCESS;

export const getFolderContent = async (token: string, projectId: string, folderUrn: string): Promise<BIM360Document[]> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    token,
    env: currentEnv,
  });
  const url = `${PathsConfigMap[PathTypes.DM_PROJECTS_PATH][currentEnv].path}/${projectId}/folders/${folderUrn}/documents`;

  const { response } = apiService.abortableGet(url);

  try {
    const processedDocuments = (await response).data.documents.filter((doc: BIM360Document) =>
      isCurrentDocVersionReady(doc.current_version),
    );

    return processedDocuments;
  } catch (e) {
    logError(e);
    throw new BIM360FolderDocumentsFetchError(bim360ServiceText.folderContentLoadError, { projectId, folderUrn });
  }
};

export const getThumbnail = async (token: string, documentId: string): Promise<Blob> => {
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    token,
    env: currentEnv,
  });
  const manifestUrl = `${Paths.MODEL_DERIVATIVE_PATH}/${documentId}/manifest`;

  const { response: getManifestResponse } = apiService.abortableGet(manifestUrl);

  let getManifestResult;

  try {
    getManifestResult = await getManifestResponse;
  } catch (e) {
    logError(e);
    throw new BIM360ManifestFetchError(bim360ServiceText.folderManifestLoadError, { documentId });
  }

  let guidPath;
  if (getManifestResult.data?.derivatives) {
    const { derivatives } = getManifestResult.data;
    const svfDerivatives = derivatives.find((derivative: BIM360Derivative) => derivative.outputType === 'svf');
    const svf3DDerivatives = svfDerivatives?.children.find(
      (svfDerivative: BIM360DerivativeChild) => svfDerivative.role === '3d',
    );
    guidPath = svf3DDerivatives.guid && `&guid=${svf3DDerivatives.guid}`;
  }

  const thumbnailUrl = `${Paths.DOCUMENT_THUMBNAIL_PATH}/${documentId}?type=large${guidPath}`;
  const { response: getThumbnailResponse } = apiService.abortableGet(thumbnailUrl, {
    fetchBlob: true,
  });

  try {
    const { data: thumbnailData } = await getThumbnailResponse;

    return new Blob([thumbnailData as BlobPart], { type: 'image/png' });
  } catch (e: unknown) {
    logError(e);
    throw new ThumbnailError(bim360ServiceText.documentThumbnailLoadError, {});
  }
};
