import { ENVIRONMENT } from 'mid-types';

export enum PathTypes {
  DM_PROJECTS_PATH = 'DM_PROJECTS_PATH',
}

export const Paths = {
  BIM360_API_PATH: 'bim360/admin/v1',
  ACC_API_PATH: 'construction/admin/v1',
  DM_API_PATH: 'dm/v1',
  DM_STG_API_PATH: 'dm-staging/v1',
  EA_API_PATH: 'ea-api/v1',
  ACCOUNT_ENTITLEMENTS_PATH: 'account_entitlements',
  PROJECT_ENTITLEMENTS_PATH: 'project_entitlements',
  PROJECTS: 'projects',
  MODEL_DERIVATIVE_PATH: 'modelderivative/v2/designdata',
  DOCUMENT_THUMBNAIL_PATH: 'derivativeservice/v2/thumbnails',
  IDENTITY_API_PATH: 'identity/v1',
  USERPROFILE_API_PATH: 'userprofile/v1',
};

export const PathsConfigMap = {
  [PathTypes.DM_PROJECTS_PATH]: {
    [ENVIRONMENT.PRD]: {
      path: `${Paths.DM_API_PATH}/${Paths.PROJECTS}`,
    },
    [ENVIRONMENT.STG]: {
      path: `${Paths.DM_STG_API_PATH}/${Paths.PROJECTS}`,
    },
    [ENVIRONMENT.DEV]: {
      path: `${Paths.DM_STG_API_PATH}/${Paths.PROJECTS}`,
    },
  },
};
