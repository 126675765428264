import { AccProject } from 'mid-types';
import { mockAccount, mockAccount2 } from './mockAccounts';

export const mockProject: AccProject = {
  account_id: mockAccount.account_id,
  account_display_name: mockAccount.account_display_name,
  project_id: '8e62b463-b77e-4e8d-af0f-f515970debd2',
  project_display_name: 'mock project name',
  project_url: 'https://mock-project.url',
  region: 'US',
  image_url:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
};

export const mockProject2: AccProject = {
  account_id: mockAccount.account_id,
  account_display_name: mockAccount.account_display_name,
  project_id: '11111111-b77e-4e8d-af0f-f515970debd2',
  project_display_name: 'mock project name 2',
  project_url: 'https://mock-project-2.url',
  region: 'US',
  image_url:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
};

export const mockProject3: AccProject = {
  account_id: mockAccount2.account_id,
  account_display_name: mockAccount2.account_display_name,
  project_id: '22222222-b77e-4e8d-af0f-f515970debd2',
  project_display_name: 'mock project name 3',
  project_url: 'https://mock-project-3.url',
  region: 'US',
  image_url:
    'https://bim360-ea-stg-ue1-storage.s3.amazonaws.com/enterprise_projects/images/default/medium/project-default-1.0.png',
};
