import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { HigSearch, StateSetter } from 'mid-react-common';
import {
  FolderContentDatagrid,
  ModelsFolderContentContainer,
  ModelsFolderContentDataGrid,
  ModelsFolderContentHeader,
  ModelsSearchTextField,
} from './ModelsFolderContent.style';
import { FolderContentRow } from './ModelsFolderContent.types';
import { dataGridDefaultSettings } from '../../../global/constants/products';
import text from '../../../global/text.json';
import useModelFolderContent from './useModelsFolderContent';
import { BIM360Document } from 'mid-types';

const modelsFolderText = text.modelsFolderContent;

export interface ModelsFolderContentProps {
  folderUrn: string;
  lmvModelFiles: BIM360Document[] | null;
  selectedModelFolder: FolderContentRow | null;
  setSelectedModelFolder: StateSetter<FolderContentRow | null>;
  setIsPreviewLoading: StateSetter<boolean>;
  handleSameRowClick: () => void;
}

export const ModelsFolderContent: React.FC<ModelsFolderContentProps> = (props) => {
  const {
    handleOpenFoldersInDocsClick,
    keywords,
    handleKeywordsChange,
    productFoldersContent,
    columns,
    handleRowClick,
    selectedModelId,
  } = useModelFolderContent(props);

  return (
    <ModelsFolderContentContainer>
      <ModelsFolderContentHeader>
        <ButtonGroup variant="outlined">
          <Button onClick={handleOpenFoldersInDocsClick}>
            <Typography variant="body2" noWrap>
              {modelsFolderText.openInDocs}
            </Typography>
          </Button>
        </ButtonGroup>
        <ModelsSearchTextField
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HigSearch />
              </InputAdornment>
            ),
          }}
          size="small"
          placeholder={modelsFolderText.searchFiles}
          value={keywords}
          onChange={handleKeywordsChange}
        />
      </ModelsFolderContentHeader>
      <ModelsFolderContentDataGrid>
        <FolderContentDatagrid
          rows={productFoldersContent}
          columns={columns}
          rowsPerPageOptions={dataGridDefaultSettings.ROWS_PER_PAGE}
          rowHeight={dataGridDefaultSettings.ROW_HEIGHT}
          headerHeight={dataGridDefaultSettings.HEADER_HEIGHT}
          onRowClick={handleRowClick}
          selectionModel={selectedModelId}
        />
      </ModelsFolderContentDataGrid>
    </ModelsFolderContentContainer>
  );
};
