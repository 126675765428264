/* eslint-disable @typescript-eslint/no-empty-interface */

import {
  BooleanInput,
  DynamicContentProduct,
  IPropertyInput,
  MultiValueNumericInput,
  MultiValueTextInput,
  NumericInput,
  TextInput,
} from 'mid-types';

export interface SerializedBlocklyWorkspaceState {
  [key: string]: any;
}

/**
 * Interface definition for deprecated draft template.
 */
export interface DeprecatedDraftTemplate {
  id?: string;
  name: string;
  lastUpdated: number;
  account: MetaInfo;
  project: MetaInfo;
  folder: MetaInfoPath;
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
  thumbnail: string;
  iProperties: DraftTemplateIProperty[];
  parameters: DraftTemplateInputParameter[];
  rules: InputRule[];
  codeBlocksWorkspace?: SerializedBlocklyWorkspaceState;
  outputs: DraftTemplateOutput[];
}

/**
 * Interface definition for draft templates.
 */
export interface DraftTemplate {
  id?: string;
  name: string;
  lastUpdated: number;
  account: MetaInfo;
  project: MetaInfo;
  folder: MetaInfoPath;
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
  thumbnail: string;
  inputs: DraftTemplateInput[];
  rules: InputRule[];
  codeBlocksWorkspace?: SerializedBlocklyWorkspaceState;
  outputs: DraftTemplateOutput[];
}

export interface DraftTemplateReducerMap extends Omit<DraftTemplate, 'inputs'> {
  inputs: Map<string, DraftTemplateInput>;
}

export interface DraftTemplateInputProperties {
  iProperties: DraftTemplateIProperty[];
  parameters: DraftTemplateInputParameter[];
}

export type DraftTemplateInputParameter =
  | BooleanInput
  | TextInput
  | NumericInput
  | MultiValueNumericInput
  | MultiValueTextInput;

export type DraftTemplateInput = DraftTemplateIProperty | DraftTemplateInputParameter;

export enum PublishStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  COMPLETE = 'complete',
  FAILURE = 'failed',
}

export interface MetaInfo {
  id: string;
  name: string;
}

export type MetaInfoPath = MetaInfo & { parentPath: MetaInfo[] }; // ParentPath is the full path to the MetaInfo.ID from Root

// Extend DC Template Param and IProperty interfaces.
// Add extra properties not required in DC Templates here.
export interface DraftTemplateIProperty extends IPropertyInput {
  id: string;
}

export interface InputRule {
  key: string;
  code: string;
  label?: string;
  errorMessage?: string;
}

export enum OutputType {
  RFA = 'RFA',
  BOM = 'BOM',
}

export interface DraftTemplateOutput {
  type: OutputType;
  options?: {
    modelStates?: string[];
    category?: string;
    family?: string;
  };
}

export interface DraftTemplatePublishResult {
  status: PublishStatus;
  errorMessage?: string;
  publishedProduct?: DynamicContentProduct;
}
