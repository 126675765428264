import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  projectId: string;
  productId: string;
  variantId?: string;
};

export class VariantFetchError extends CustomError {
  errorCode = ErrorCode.VariantFetchError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
