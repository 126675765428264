import { AccProject } from 'mid-types';
import { useContext } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import ProductContext from '../../../context/ProductStore/Product.context';
import { routes } from '../../../routes';

interface UseProjectsListProps {
  handleCloseProjectMenu: () => void;
}

interface UseProjectsListState {
  handleProjectSelectionClick: (project: AccProject) => void;
}

export const useProjectsList = ({ handleCloseProjectMenu }: UseProjectsListProps): UseProjectsListState => {
  const { setCurrentProject } = useContext(AccountProjectContext);
  const { resetModelSelectionStoreState } = useContext(ModelSelectionContext);
  const { resetProductStoreState } = useContext(ProductContext);
  const navigate = useNavigate();

  const handleProjectSelectionClick = (project: AccProject) => {
    setCurrentProject(project);
    const projectIdPath = generatePath(routes.projectId.path, { projectId: project.project_id });
    navigate(projectIdPath);
    handleCloseProjectMenu();

    resetModelSelectionStoreState();
    resetProductStoreState();
  };

  return {
    handleProjectSelectionClick,
  };
};
