import { TemplateInputType } from 'mid-types';
import { InventorProperties } from '../../interfaces/inventorProperties';
import { DraftTemplateInput, DraftTemplateInputProperties } from '../../interfaces/templates';
import { toDraftTemplateInputParameter, toDraftTemplateIProperty } from '../../utils/drafts';

export const mockProperties: InventorProperties = {
  iProperties: [
    {
      category: 'Summary',
      displayName: 'Author',
      id: '83bcbc06-0d89-afcd-d5bd-05ea81dff84d',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Comments',
      id: 'c7c21a82-d213-954d-1aa8-c61fe3bd44d4',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Keywords',
      id: '66d477bd-2ddb-6232-d396-5d058b381c57',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Subject',
      id: '459a6d46-07de-81cb-6d01-ab076ed11e58',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Title',
      id: '32bc9427-27a6-21ca-a2dc-0cff5b6a126a',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Category',
      id: '35835425-d9b4-cd15-c185-9ba6a1e90aca',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Company',
      id: '331d2b14-fcd4-80bc-ff30-59ac05d5612e',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Manager',
      id: '1e37548a-a89a-f8eb-9703-992fdc4bfc8b',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Authority',
      id: '0d072963-5db0-12ca-7fac-6878ab457b2e',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Catalog web link',
      id: '0ff99d14-ced4-c66e-ba06-74d49f5a5542',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Cost',
      id: '56d1b38c-f465-d8a5-325a-59362ab63004',
      value: '0.0000',
    },
    {
      category: 'Project',
      displayName: 'Cost Center',
      id: '78d81295-9b9f-ae3e-75b7-9ffd36134971',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Date Created',
      id: 'cb801520-b703-ce91-b2de-796c27fe9ef8',
      value: '07/22/2020',
    },
    {
      category: 'Project',
      displayName: 'Designer',
      id: '14dec525-f7d7-aff1-0720-089d27b8b0b3',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Description',
      id: 'd3d22576-005b-2e22-6874-8c70bf81466b',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Engineer',
      id: 'b63de099-0e11-186c-03d7-8ac5db21c1e0',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Part Number',
      id: '500987ad-e578-f6ee-66c1-818006b64e7d',
      value: 'Wall',
    },
    {
      category: 'Project',
      displayName: 'Project',
      id: '154542af-df1a-383f-70f8-d0a946e3e2b7',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Stock Number',
      id: '672dd7ee-042a-1f1d-7830-e3232bd51cd9',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Vendor',
      id: 'ce070f06-88d8-3ad4-b77d-751e5b5a06da',
      value: '',
    },
    {
      category: 'Physical',
      displayName: 'Material',
      id: '1660f30f-5e0f-ba46-927b-d78c8958a456',
      value: '',
    },
    {
      category: 'Physical',
      displayName: 'Mass',
      id: '37eb8ab9-959b-337e-a15a-a381fa994868',
      value: '0',
    },
  ],
  parameters: [
    {
      name: 'StudSpacing',
      parameterType: 'User',
      unitType: 'ft',
      value: '1.3333333333333333',
      isKey: false,
    },
    {
      name: 'StudCountRight',
      parameterType: 'User',
      unitType: 'ul',
      value: '9',
      isKey: false,
    },
    {
      name: 'WallHeight',
      parameterType: 'User',
      unitType: 'ft',
      value: '8',
      isKey: true,
    },
    {
      name: 'WallWidth',
      parameterType: 'User',
      unitType: 'ft',
      value: '15',
      isKey: true,
    },
    {
      name: 'EqualCheck',
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'True',
      isKey: false,
      options: ['True', 'False'],
    },
    {
      name: 'MV_Text',
      parameterType: 'User',
      unitType: 'Text',
      value: 'Red',
      isKey: true,
      options: ['Blue', 'Green', 'Red'],
    },
    {
      name: 'd22',
      parameterType: 'Model',
      unitType: 'in',
      value: '2',
      isKey: false,
    },

    {
      name: 'd24',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
      isKey: false,
    },

    {
      name: 'd50',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd51',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd52',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd53',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd54',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd55',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd56',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd57',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd58',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'Stock',
      parameterType: 'User',
      unitType: 'Text',
      value: '"2x6"',
      isKey: true,
    },

    {
      name: 'd59',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd60',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'd61',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
      isKey: false,
    },

    {
      name: 'StudSpacingMax',
      parameterType: 'User',
      unitType: 'in',
      value: '16',
      isKey: false,
    },

    {
      name: 'ProjectName',
      parameterType: 'User',
      unitType: 'Text',
      value: '"Office Building"',
      isKey: false,
    },
    {
      name: 'RevitElementID',
      parameterType: 'User',
      unitType: 'Text',
      value: '"W01679"',
      isKey: false,
    },
    {
      name: 'MV_Numeric',
      parameterType: 'User',
      unitType: 'ft',
      value: '1',
      isKey: true,
      options: [1, 2, 3],
    },
  ],
};

export const mockPropertiesString = JSON.stringify(mockProperties);

export const mockPropertiesInventor1: InventorProperties = {
  iProperties: [
    {
      category: 'Summary',
      displayName: 'Author',
      id: '83bcbc06-0d89-afcd-d5bd-05ea81dff84d',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Comments',
      id: 'c7c21a82-d213-954d-1aa8-c61fe3bd44d4',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Keywords',
      id: '66d477bd-2ddb-6232-d396-5d058b381c57',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Subject',
      id: '459a6d46-07de-81cb-6d01-ab076ed11e58',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Title',
      id: '32bc9427-27a6-21ca-a2dc-0cff5b6a126a',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Category',
      id: '35835425-d9b4-cd15-c185-9ba6a1e90aca',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Company',
      id: '331d2b14-fcd4-80bc-ff30-59ac05d5612e',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Manager',
      id: '1e37548a-a89a-f8eb-9703-992fdc4bfc8b',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Authority',
      id: '0d072963-5db0-12ca-7fac-6878ab457b2e',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Catalog web link',
      id: '0ff99d14-ced4-c66e-ba06-74d49f5a5542',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Cost',
      id: '56d1b38c-f465-d8a5-325a-59362ab63004',
      value: '0.0000',
    },
    {
      category: 'Project',
      displayName: 'Cost Center',
      id: '78d81295-9b9f-ae3e-75b7-9ffd36134971',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Date Created',
      id: 'cb801520-b703-ce91-b2de-796c27fe9ef8',
      value: '07/22/2020',
    },
    {
      category: 'Project',
      displayName: 'Designer',
      id: '14dec525-f7d7-aff1-0720-089d27b8b0b3',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Description',
      id: 'd3d22576-005b-2e22-6874-8c70bf81466b',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Engineer',
      id: 'b63de099-0e11-186c-03d7-8ac5db21c1e0',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Part Number',
      id: '500987ad-e578-f6ee-66c1-818006b64e7d',
      value: 'Wall',
    },
    {
      category: 'Project',
      displayName: 'Project',
      id: '154542af-df1a-383f-70f8-d0a946e3e2b7',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Stock Number',
      id: '672dd7ee-042a-1f1d-7830-e3232bd51cd9',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Vendor',
      id: 'ce070f06-88d8-3ad4-b77d-751e5b5a06da',
      value: '',
    },
    {
      category: 'Physical',
      displayName: 'Material',
      id: '1660f30f-5e0f-ba46-927b-d78c8958a456',
      value: '',
    },
    {
      category: 'Physical',
      displayName: 'Mass',
      id: '37eb8ab9-959b-337e-a15a-a381fa994868',
      value: '0',
    },
  ],
  parameters: [
    {
      name: 'StudSpacing',
      parameterType: 'User',
      unitType: 'ft',
      value: '1.3333333333333333',
      isKey: false,
    },
    {
      name: 'WallHeight',
      parameterType: 'User',
      unitType: 'ft',
      value: '10',
      isKey: true,
    },
    {
      name: 'WallWidth',
      parameterType: 'User',
      unitType: 'ft',
      value: '11',
      isKey: true,
    },
    {
      name: 'EqualCheck',
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'True',
      isKey: false,
      options: ['True', 'False'],
    },
    {
      name: 'MV_Text',
      parameterType: 'User',
      unitType: 'Text',
      value: 'Red',
      isKey: true,
      options: ['Blue', 'Green', 'Red'],
    },
  ],
};

export const mockPropertiesDraft1: DraftTemplateInputProperties = {
  iProperties: mockProperties.iProperties.slice(0, 3).map(toDraftTemplateIProperty),
  parameters: mockProperties.parameters.slice(3, 5).map(toDraftTemplateInputParameter),
};
export const mockPropertiesDraft1Inputs: DraftTemplateInput[] = [
  ...mockPropertiesDraft1.parameters,
  ...mockPropertiesDraft1.iProperties,
];

export const mockPropertiesDraft2: DraftTemplateInputProperties = {
  iProperties: mockProperties.iProperties.slice(4, 7).map(toDraftTemplateIProperty),
  parameters: mockProperties.parameters.slice(4, 9).map(toDraftTemplateInputParameter),
};

// Below is the mockProducts[0] inputs data (from MockProducts.ts) tranformed into Draft inputs data
// Used for testing DownloadDraft feature
export const mockPropertiesDraft3: DraftTemplateInputProperties = {
  parameters: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      readOnly: false,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
      onChange: [
        {
          dependsOn: {
            bindings: {
              dependentValue: 'Width.visible',
              dependsOn: 'HasDoorControllingInput.value',
            },
          },
        },
      ],
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: TemplateInputType.MultiValueText,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: TemplateInputType.MultiValueText,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
    },
  ],
  iProperties: [
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      id: 'MockIProperty',
      readOnly: true,
      type: TemplateInputType.IProperty,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
  ],
};

export const mockPropertiesInventor2: InventorProperties = {
  iProperties: [
    {
      category: 'Summary',
      displayName: 'Author',
      id: '298ffe4e-b7a7-d4a7-657e-4d5e90c4077f',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Comments',
      id: '86f53198-9b66-ec95-3dca-24539d163ac2',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Keywords',
      id: '3fe1e2d3-1932-1ce5-55bb-f28444604d91',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Subject',
      id: '14787537-b93c-9841-cb5a-17da0c665a51',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Title',
      id: 'ba226ab2-2551-c2c2-3bb3-fdd1d0c23d6b',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Category',
      id: '7cf747a3-9b9e-de7e-290f-ab397c5ac4ea',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Company',
      id: 'b717f1c8-efad-2caf-7440-191ccb561b3b',
      value: '',
    },
    {
      category: 'Summary',
      displayName: 'Manager',
      id: '66dac910-6df7-c7db-be97-15ab6f0dfbdb',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Authority',
      id: '1a6446ce-28b7-76c1-93b6-e8a6270613b8',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Web Link',
      id: '407078e0-8d59-b446-babe-24f0fe7a3c72',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Estimated Cost',
      id: '41f468a7-1c8e-4b3f-0a11-fe0ee74ea42a',
      value: '0.0000',
    },
    {
      category: 'Project',
      displayName: 'Creation Date',
      id: '614c3964-c003-cd89-4a04-154cf59fa2be',
      value: '08/15/2022',
    },
    {
      category: 'Project',
      displayName: 'Designer',
      id: 'b8cf563b-8ff4-11ba-ab87-8cddf43a9abe',
      value: 'reaumed',
    },
    {
      category: 'Project',
      displayName: 'Description',
      id: '51209b2d-e851-fd58-00d9-38d626a938a5',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Engineer',
      id: '22692473-c031-458b-d349-2e9be0395410',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Part Number',
      id: 'b0210773-0e8a-2000-22f0-1851716187c5',
      value: 'Shelving Unit',
    },
    {
      category: 'Project',
      displayName: 'Stock Number',
      id: '29cacc99-7b87-757b-0b69-f2d6543ad85d',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Project',
      id: '9b094c2f-b056-03c0-30e0-9457c9c47ec3',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Stock Number',
      id: '29cacc99-7b87-757b-0b69-f2d6543ad85d',
      value: '',
    },
    {
      category: 'Project',
      displayName: 'Vendor',
      id: 'd46a879f-ca65-e759-ef18-13ad3d875dda',
      value: '',
    },
    {
      category: 'Physical',
      displayName: 'Mass',
      id: '1767a5e1-fa62-7ca4-b24e-aa20aaf57732',
      value: '0',
    },
    {
      category: 'Physical',
      displayName: 'Material',
      id: '09aeed5e-18b8-1860-6e20-9bfc1ae321ff',
      value: '',
    },
  ],
  parameters: [
    {
      isKey: true,
      name: 'Height',
      options: ['30', '42', '54', '66'],
      parameterType: 'User',
      unitType: 'in',
      value: '54',
    },
    {
      isKey: true,
      name: 'Width',
      parameterType: 'User',
      unitType: 'in',
      value: '30',
    },
    {
      isKey: true,
      name: 'Depth',
      options: ['10', '11', '12', '13.000000000000002', '14', '15', '16', '17', '18'],
      parameterType: 'User',
      unitType: 'in',
      value: '14',
    },
    {
      isKey: false,
      name: 'Thickness',
      parameterType: 'User',
      unitType: 'in',
      value: '1',
    },
    {
      isKey: true,
      name: 'Material',
      options: [
        '"Laminated Veneer Lumber"',
        '"Particleboard"',
        '"Ash"',
        '"Birch"',
        '"Mahogany"',
        '"Maple"',
        '"Oak, Red"',
        '"Pine"',
        '"Plywood, Finish"',
        '"Spruce"',
        '"Walnut"',
      ],
      parameterType: 'User',
      unitType: 'Text',
      value: '"Oak, Red"',
    },
    {
      isKey: true,
      name: 'Shelves_Qty',
      options: ['1', '2', '3'],
      parameterType: 'User',
      unitType: 'ul',
      value: '3',
    },
    {
      isKey: false,
      name: 'd0',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd1',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd2',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd3',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd4',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd5',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd6',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd7',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd8',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd9',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd10',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd11',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd12',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd13',
      parameterType: 'Model',
      unitType: 'deg',
      value: '0',
    },
    {
      isKey: false,
      name: 'd14',
      parameterType: 'Model',
      unitType: 'deg',
      value: '0',
    },
    {
      isKey: false,
      name: 'd15',
      parameterType: 'Model',
      unitType: 'deg',
      value: '0',
    },
    {
      isKey: false,
      name: 'd16',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd17',
      parameterType: 'Model',
      unitType: 'in',
      value: '0',
    },
    {
      isKey: false,
      name: 'd18',
      parameterType: 'Model',
      unitType: 'in',
      value: '2',
    },
    {
      isKey: false,
      name: 'd19',
      parameterType: 'Model',
      unitType: 'in',
      value: '12',
    },
    {
      isKey: false,
      name: 'd20',
      parameterType: 'Model',
      unitType: 'ul',
      value: '1',
    },
    {
      isKey: false,
      name: 'MID_Publish_Mode',
      options: ['True', 'False'],
      parameterType: 'User',
      unitType: 'Boolean',
      value: 'True',
    },
  ],
};
