import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import noProjectImage from '../../../images/3d-settings-icon-light-slate.png';
import SearchIcon from '@mui/icons-material/Search';
import {
  AccountLogo,
  AccountLogoTitleContainer,
  ProjectSelectorPanelPopoverContainer,
  Search,
  SearchIconWrapper,
  SelectedProjectHeaderContainer,
  SelectorsContainer,
  StyledInputBase,
  AccountArrowIcon,
} from './ProjectSelectorPanel.style';
import AccountsList from '../AccountsList';
import ProjectsList from '../ProjectsList';
import { useProjectSelectorPanel } from './useProjectSelectorPanel';
import text from '../../../global/text.json';

const projectSelectorText = text.projectSelectorPanel;
export const ProjectSelectorPanel = (): JSX.Element => {
  const {
    accountId,
    accountDisplayName,
    accountImageURL,
    accounts,
    projectId,
    projectsByAccount,
    renderAccountProjectName,
    anchorElProject,
    handleOpenProjectMenu,
    handleCloseProjectMenu,
    handleSearchProjects,
  } = useProjectSelectorPanel();

  return (
    <>
      <SelectedProjectHeaderContainer onClick={handleOpenProjectMenu}>
        <AccountLogoTitleContainer>
          <AccountLogo src={projectId && accountImageURL ? accountImageURL : noProjectImage} />
          {renderAccountProjectName()}
        </AccountLogoTitleContainer>
        <AccountArrowIcon anchorElProject={anchorElProject} />
      </SelectedProjectHeaderContainer>
      <Popover
        open={Boolean(anchorElProject)}
        anchorEl={anchorElProject}
        onClose={handleCloseProjectMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ProjectSelectorPanelPopoverContainer>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              data-testid="project-search"
              placeholder={projectSelectorText.search}
              onChange={handleSearchProjects}
              disabled={!accountId}
            />
          </Search>
          <Divider />

          <SelectorsContainer>
            <AccountsList accounts={accounts} visible={!accountId} />
            <ProjectsList
              projects={projectsByAccount}
              visible={!!accountId}
              handleCloseProjectMenu={handleCloseProjectMenu}
              accountName={accountDisplayName}
              accountImageURL={accountImageURL}
            />
          </SelectorsContainer>
        </ProjectSelectorPanelPopoverContainer>
      </Popover>
    </>
  );
};
