import { GridRowId } from '@mui/x-data-grid';
import { StateSetter } from 'mid-react-common';
import { useState } from 'react';
import { Tree } from '../../components/ProductsPage/ModelsFolderBrowser/useModelFolderBrowser';
import { FolderContentRow } from '../../components/ProductsPage/ModelsFolderContent/ModelsFolderContent.types';
import { BIM360Document } from 'mid-types';

export interface ModelSelectionStore {
  modelFoldersTree: Tree | undefined;
  setProductFoldersTree: StateSetter<Tree | undefined>;
  expandedModelTreeIds: string[];
  setExpandedTreeNodeIds: StateSetter<string[]>;
  selectedModelFolder: FolderContentRow | null;
  setSelectedModelFolder: StateSetter<FolderContentRow | null>;
  productFoldersContent: FolderContentRow[];
  setProductFoldersContent: StateSetter<FolderContentRow[]>;
  selectedModelId: GridRowId | undefined;
  setSelectedModelId: StateSetter<GridRowId | undefined>;
  currentlyOpenModel: FolderContentRow | null;
  setCurrentlyOpenModel: StateSetter<FolderContentRow | null>;
  resetModelSelectionStoreState: () => void;
  selectedFolderUrn: string;
  setSelectedFolderUrn: StateSetter<string>;
  lmvModelFile: BIM360Document[] | null;
  setLmvModelFile: StateSetter<BIM360Document[] | null>;
}
export const useModelSelectionStore = (): ModelSelectionStore => {
  const [selectedModelFolder, setSelectedModelFolder] = useState<FolderContentRow | null>(null);
  const [selectedFolderUrn, setSelectedFolderUrn] = useState('');
  const [productFoldersContent, setProductFoldersContent] = useState<FolderContentRow[]>([]);
  const [expandedModelTreeIds, setExpandedTreeNodeIds] = useState<string[]>([]);
  const [modelFoldersTree, setProductFoldersTree] = useState<Tree | undefined>(undefined);
  const [selectedModelId, setSelectedModelId] = useState<GridRowId | undefined>(undefined);
  const [currentlyOpenModel, setCurrentlyOpenModel] = useState<FolderContentRow | null>(null);
  const [lmvModelFile, setLmvModelFile] = useState<BIM360Document[] | null>(null);

  const resetModelSelectionStoreState = () => {
    setProductFoldersTree(undefined);
    setSelectedModelFolder(null);
    setProductFoldersContent([]);
    setExpandedTreeNodeIds([]);
    setSelectedModelId(undefined);
    setCurrentlyOpenModel(null);
    setSelectedFolderUrn('');
    setLmvModelFile(null);
  };

  return {
    selectedFolderUrn,
    setSelectedFolderUrn,
    modelFoldersTree,
    setProductFoldersTree,
    expandedModelTreeIds,
    setExpandedTreeNodeIds,
    productFoldersContent,
    setProductFoldersContent,
    selectedModelFolder,
    setSelectedModelFolder,
    selectedModelId,
    setSelectedModelId,
    currentlyOpenModel,
    setCurrentlyOpenModel,
    lmvModelFile,
    setLmvModelFile,
    resetModelSelectionStoreState,
  };
};
