import Button from '@adsk/alloy-react-button';
import ProgressRing from '@adsk/alloy-react-progress-ring';
import { TableBodyCell } from '@adsk/alloy-react-table';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { css, styled } from '@mui/material/styles';

export const GlobalStyle = css(`
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  html {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  #root {
    padding: 0;
    margin: 0;
    height: 100%;
  }
`);

export const LeftButton = styled(Button)`
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const RightButton = styled(Button)<{ width?: number }>`
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
  ${(props) =>
    props.width &&
    css(`
      width: ${props.width}px;
    `)}
  white-space: nowrap;
`;

export const MiddleSection = styled('div')`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const MiddleButton = styled(Button)`
  margin: 0 ${({ theme }) => `${theme.var.marginBase / 2}px`};
  white-space: nowrap;
`;

export const Label = styled('label')`
  display: block;
  ${({ theme }) => theme.typography.labelLarge}
  margin-top: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const LabelText = styled('span')`
  display: block;
  color: ${({ theme }) => theme.colors.primary.charcoal700};
  margin-bottom: ${({ theme }) => `${theme.var.marginBase / 2}px`};
  ${({ theme }) => theme.typography.labelSmall}
`;

export const LabelTextRequired = styled('span')`
  color: ${({ theme }) => theme.colors.secondary.red600};
  margin-left: 3px;
`;

export const TooltipWrapper = styled('span')`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${({ theme }) => `${theme.var.marginBase / 2}px`};
`;

export const NumericInputTooltipWrapper = styled(TooltipWrapper)`
  line-height: ${({ theme }) => theme.var.inputHeight}px;
`;

export const ThemedIconWrapper = styled('span')`
  color: ${({ theme }) => theme.colors.primary.black};
`;

export const MIDLabelTooltipWrapper = styled(TooltipWrapper)`
  vertical-align: bottom;
`;

export const MIDLabelIconWrapper = styled(ThemedIconWrapper)`
  display: block;
  height: ${({ theme }) => theme.var.labelHeight}px;
`;

export const SourceContentFormField = styled(FormControl)`
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const TabContainer = styled('div')`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: ${({ theme }) => `${theme.var.tabsVerticalMargin}px`} ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const TabFormContainer = styled('form')`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: ${({ theme }) => `${theme.var.tabsVerticalMargin}px`} ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const FieldSetContainer = styled('fieldset')`
  margin: 0 0 ${({ theme }) => `${theme.var.marginBase}px`} 0;
  padding: ${({ theme }) => `${theme.var.paddingBase * 3}px`};
  border: none;
  background-color: ${({ theme }) => theme.colors.primary.charcoal100};
`;

export const RowItem = styled('div')<{ justifyContent?: string }>`
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  flex: 1;
  display: flex;
`;

export const FieldsetRow = styled('div')`
  ${({ theme }) => theme.typography.bodyMedium}
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  display: flex;
`;

export const FieldsetGrid = styled('div')`
  display: grid;

  // Basic Grid 2x2. Should be extended in order to customize that
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;

  column-gap: ${({ theme }) => `${theme.var.marginBase}px`};
  row-gap: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const FieldsetGridLabel = styled(InputLabel)`
  align-self: center;
`;

export const InputFormControl = styled(FormControl)<{ width: string }>`
  width: ${({ width }) => width};
  margin-right: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const TablesCells = styled(TableBodyCell)<{
  index: number;
  isSelected: boolean;
}>`
  background-color: ${(row) =>
    row.index % 2 === 0
      ? row.isSelected
        ? `${row.theme.colors.primary.autodeskBlue500Alpha15} !important`
        : 'inherit'
      : row.isSelected
      ? `${row.theme.colors.primary.autodeskBlue100} !important`
      : `${row.theme.colors.primary.charcoal100}`};
`;

export const SpinnerForButton = styled(ProgressRing)`
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const FlexContainer = styled('div')<{
  alignItems?: string;
  justifyContent?: string;
}>`
  display: flex;
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
`;

export const GridContainer = styled('div')`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  width: 75%;
  gap: ${({ theme }) => `${theme.var.marginBase}px`};
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const GridTwoColumns = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
`;

export const LocationContainer = styled('div')`
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
`;

export const LocationInformation = styled('h2')`
  margin: 0;
  padding: ${({ theme }) => `${theme.var.paddingBase * 0.5}px`} 0 ${({ theme }) => `${theme.var.paddingBase * 2}px`}
    ${({ theme }) => `${theme.var.paddingBase}px`};
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const SidebarComponent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 200px;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.largeLabelHeight * 4}px`}
  );
`;

export const SidebarButton = styled(Button)`
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.var.marginBase / 2}px`};
`;

export const SidebarList = styled(List)<{ height?: number }>`
  overflow: auto;
  margin-top: ${({ theme }) => `${theme.var.marginBase / 2}px`};
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.primary.charcoal200};
  height: fit-content;
  max-height: ${(props) => `${props.height}%`};
`;

export const SidebarListItem = styled(ListItemButton)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.autodeskBlue300};
  }
  background-color: ${({ theme, selected }) => (selected ? theme.colors.primary.autodeskBlue500 : undefined)} !important;
`;

export const ContentWrapper = styled('div')`
  margin: 0 ${({ theme }) => `${theme.var.marginBase * 2}px`};
  width: 80%;
  display: block;
`;

export const ContentTitle = styled('h1')`
  height: ${({ theme }) => `${theme.var.heading2}px`};
  margin: 0;
  ${({ theme }) => theme.typography.heading2}
`;

export const TableTitle = styled('h2')`
  ${({ theme }) => theme.typography.heading2};
  margin: ${({ theme }) => `${theme.var.marginBase / 2}px`} 0;
`;

export const ConfirmSelectionsButton = styled(Button)`
  margin: 0 0 ${({ theme }) => `${theme.var.marginBase}px`} ${({ theme }) => `${theme.var.marginBase * 3}px`};
  justify-items: flex-end;
  align-self: flex-end;
  white-space: nowrap;
`;

export const PreviewWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  min-height: 250px;
  max-height: 300px;
`;

export const PreviewLoader = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const PreviewInProgressMessageWrap = styled('p')`
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

export const PreviewImage = styled('img')`
  height: 100%;
  width: auto;
`;

export const EmptyStateImage = styled('img')`
  margin-bottom: 24px;
  height: 100%;
  width: auto;
`;

export const EmptyStateSubTitle = styled('div')`
  ${({ theme }) => theme.typography.bodyMedium};
  color: ${({ theme }) => theme.colors.primary.charcoal700};
  margin-top: 8px;
`;

export const EmptyStateContainer = styled('div')`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 40px;
`;

export const TableEmptyStateWrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
