export const revitInventorNames = {
  revitCategory: 'Revit Category',
  revitFamily: 'Revit Family',
  revitFamilyType: 'Revit Family Type',
  revitFamilyName: 'Revit Family Name',
  revitWallsCategory: 'Revit Walls',
  revitCurtainPanels: 'Revit Curtain Panels',
  revitWindowsCategory: 'Revit Windows',
  iCWallsType: 'IC Walls',
  genericType: 'Generic Walls',
  revitDocument: 'Revit Document',
};
