import { AsyncProviderConfig } from 'launchdarkly-react-client-sdk';

export const LDProviderConfig: AsyncProviderConfig = {
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID!,
  options: {
    streaming: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
};

export const LD_ANALYTICS_KIND = 'analytics';

export const LDProviderConfigWithAnonymousAnalytics: AsyncProviderConfig = {
  ...LDProviderConfig,
  context: { kind: LD_ANALYTICS_KIND, anonymous: true },
};
