import DownloadDoneOutlined from '@mui/icons-material/DownloadDoneOutlined';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { Button, CircularProgress } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { NotificationContext } from 'mid-react-common';
import { EmptyBOMError, logError } from 'mid-utils';
import React, { useContext, useState } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import UserContext from '../../../context/UserStore/User.context';
import text from '../../../global/text.json';
import { fetchFileUrl } from '../../../services/products';

const reviewPanelText = text.reviewPanel;

// value is the objectKey used to fetch BOM file URL
const DownloadOutputsCell: React.FC<GridRenderCellParams> = ({ value }: GridRenderCellParams) => {
  const [isLoading, setLoading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const { token } = useContext(UserContext);
  const { projectId } = useContext(AccountProjectContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const objectKey = value;

  // If no value, it means we don't have objectKey for that output.
  // Could be because of any other Output Status than SUCCESS
  if (!projectId || !value) {
    return null;
  }

  const fetchBOM = async () => {
    try {
      const bomUrl = await fetchFileUrl(token, projectId, objectKey);

      if (!bomUrl) {
        throw new EmptyBOMError(text.reviewPanel.emptyBOMUrlFromAPI);
      }

      const anchor = document.createElement('a');
      anchor.href = bomUrl;
      document.body.appendChild(anchor);

      // Programmatically clicking to download file
      anchor.click();

      // Remove element from DOM after file has been programmatically downloaded
      document.body.removeChild(anchor);
    } catch (err: unknown) {
      logError(err);
    }
  };

  const handleDownloadBOMClick = async () => {
    setLoading(true);

    try {
      await fetchBOM();
      setDownloaded(true);
    } catch (error) {
      logAndShowNotification({
        message: reviewPanelText.failToGetBom,
        error,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button variant="outlined" onClick={handleDownloadBOMClick} data-testid="download-button">
      {isLoading ? (
        <CircularProgress size={25} data-testid="circular-progress" />
      ) : downloaded ? (
        <DownloadDoneOutlined data-testid="download-done" />
      ) : (
        <DownloadOutlined />
      )}
    </Button>
  );
};

export default DownloadOutputsCell;
