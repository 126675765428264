import App from './App';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import ProductsPage from './pages/ProductsPage';
import OutputsPage from './pages/OutputsPage';
import InsightsPage from './pages/InsightsPage';
import ReviewPanel from './components/OutputsPage/ReviewPanel';
import SettingsPanel from './components/OutputsPage/SettingsPanel';
import InstancesPanel from './components/ProductsPage/InstancesPanel';
import { ModelsPanel } from './components/ProductsPage/ModelsPanel/ModelsPanel';

export const routes = {
  index: {
    path: '/',
  },
  projectId: {
    path: '/projects/:projectId',
    id: 'projectId',
  },
  products: {
    path: 'products',
    id: 'productsId',
  },
  models: {
    path: 'models',
  },
  instances: {
    path: 'instances',
  },
  outputs: {
    path: 'outputs',
    id: 'outputsId',
  },
  review: {
    path: 'review',
  },
  settings: {
    path: 'settings',
  },
  insights: {
    path: 'insights',
  },
  folders: {
    path: 'folders/:folderUrn',
  },
  folderItems: {
    path: 'items/:itemUrn/:details?',
  },
};

export const routerConfig = [
  {
    path: routes.projectId.path,
    element: <App />,
    id: routes.projectId.id,
    children: [
      {
        path: routes.products.path,
        element: <ProductsPage />,
        id: routes.products.id,
        children: [
          {
            path: routes.models.path,
            element: <ModelsPanel />,
            children: [
              {
                path: routes.folders.path,
                element: <ModelsPanel />,
                children: [
                  {
                    path: routes.folderItems.path,
                    element: <ModelsPanel />,
                  },
                ],
              },
            ],
          },
          {
            path: routes.instances.path,
            element: <InstancesPanel />,
          },
        ],
      },
      {
        path: routes.outputs.path,
        element: <OutputsPage />,
        id: routes.outputs.id,
        children: [
          {
            path: routes.review.path,
            element: <ReviewPanel />,
          },
          {
            path: routes.settings.path,
            element: <SettingsPanel />,
          },
        ],
      },
      {
        path: routes.insights.path,
        element: <InsightsPage />,
      },
    ],
  },
  {
    path: routes.index.path,
    element: <App />,
  },
  // handle the unknown routes, just navigate to the root (404 case)
  {
    path: '*',
    element: <Navigate to={routes.index.path} replace />,
  },
];

export const router = createBrowserRouter(routerConfig);
