import React, { useContext, useRef } from 'react';
import { ReviewPanelContainer, ReviewPanelContent, ReviewPanelHeader } from './ReviewPanel.styles';
import { useState } from 'react';
import { LinearProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DataGrid, GridFilterModel } from '@mui/x-data-grid';
import ProductContext from '../../../context/ProductStore/Product.context';
import UserContext from '../../../context/UserStore/User.context';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import text from '../../../global/text.json';
import { columns } from './ReviewPanelColumns';
import { useVariantStatusPolling } from './useVariantStatusPolling';
import { OutputsReviewTableDataModel } from './Types';
import useOpenModelURLHandling from './useOpenModelURLHandling';
import { VariantOutputStatus } from 'mid-types';

export const ReviewPanel: React.FC = () => {
  const reviewPanelText = text.reviewPanel;
  const [status, setStatus] = useState(reviewPanelText.all);

  const { instances } = useContext(ProductContext);
  const { token } = useContext(UserContext);
  const { projectId } = useContext(AccountProjectContext);

  const { polledAllVariantsOutputs, variantOutputsLoading } = useVariantStatusPolling({
    instances,
    token,
    projectId,
  });

  const lmvContainer = useRef<HTMLDivElement>(null);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const handleStatusFilterChange = (_event: React.MouseEvent<HTMLElement>, value: string) => {
    setStatus(value);
    if (value === reviewPanelText.all) {
      setFilterModel({ items: [] });
    } else {
      setFilterModel({
        items: [
          {
            columnField: 'status',
            operatorValue: 'equals',
            value,
          },
        ],
      });
    }
  };

  const getRowId = (row: OutputsReviewTableDataModel): string => row.id;

  useOpenModelURLHandling(lmvContainer);
  return (
    <ReviewPanelContainer>
      <ReviewPanelHeader>
        <ToggleButtonGroup value={status} exclusive onChange={handleStatusFilterChange} size="small">
          <ToggleButton value={reviewPanelText.all}>{reviewPanelText.all}</ToggleButton>
          <ToggleButton value={VariantOutputStatus.PENDING}>{reviewPanelText.pending}</ToggleButton>
          <ToggleButton value={VariantOutputStatus.SUCCESS}>{reviewPanelText.success}</ToggleButton>
          <ToggleButton value={VariantOutputStatus.FAILED}>{reviewPanelText.failed}</ToggleButton>
        </ToggleButtonGroup>
      </ReviewPanelHeader>
      <ReviewPanelContent>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          getRowId={getRowId}
          rows={polledAllVariantsOutputs || []}
          columns={columns}
          rowHeight={40}
          headerHeight={34}
          loading={variantOutputsLoading}
          filterModel={filterModel}
        />
      </ReviewPanelContent>
      {/* Container for the LMV to be rendered. This LMV is needed just to get some data form the model to render outputs.
      It should be invisible for the user. */}
      <div ref={lmvContainer} style={{ display: 'none' }} />
    </ReviewPanelContainer>
  );
};
