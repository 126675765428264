import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import { DetailsSidebar } from '../../Commons/DetailsSidebar';

export const InstanceThumbnailSpinner = styled(CircularProgress)(() => ({
  display: 'flex',
  margin: 'auto',
}));

export const InstanceThumbnailSpinnerContainer = styled(Box)(() => ({
  margin: '0px 12px',
  textAlign: 'center',
  padding: '12px',
  backgroundColor: 'white',
  borderRadius: '3px',
  border: '1px solid #aaa',
  width: '350px',
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
}));

export const InstanceDetailsSidebar = styled(DetailsSidebar)(() => ({
  backgroundColor: 'white',
}));
