import { AxiosResponse } from 'axios';
import {
  ENVIRONMENT,
  AccAccount,
  AccProject,
  Environment,
  ForgeAccountsQueryResponse,
  ForgeDMProjectDetails,
  ForgeDMProjectFolder,
  ForgeDMProjectFoldersResponse,
  ForgeProjectsQueryResponse,
  ProjectDetails,
  ProjectFolder,
  UserAnalytics,
  UserProfile,
} from 'mid-types';
import { ApiError, AuthenticationError, EnvError } from 'mid-utils';
import text from '../mid-addin-lib.text.json';
import { ApiService, ApiServiceFactory } from '../services/api.service';
import { getAuthToken } from './auth';
import browserApiService from '../services/browserApiService';
import { ServiceTypes } from '../services/serviceAPIConfig';
import { Paths, PathsConfigMap, PathTypes } from '../services/apiPathConfig';

export const getAuthTokenAndEnv = async (): Promise<{ token: string; env: Environment }> => {
  const token = await getAuthToken();
  if (!token) {
    throw new AuthenticationError(text.unauthorizedAccessMessage);
  }
  const env = await browserApiService.getEnvironment();
  if (!env) {
    throw new EnvError("Couldn't retrieve environment from host application.");
  }

  return { token, env };
};

/**
 * Retrieves all forge accounts for the user
 * @returns a collection of BIM360Account
 */
export const getAccAccounts = async (): Promise<AccAccount[]> => {
  const { token, env } = await getAuthTokenAndEnv();

  try {
    const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, { env, token });
    const accountAPIPath = `${Paths.EA_API_PATH}/${Paths.ACCOUNT_ENTITLEMENTS_PATH}?limit=100&offset=0`;
    const accounts = await getAllAccAccounts(apiService, accountAPIPath);
    return accounts;
  } catch (error: unknown) {
    throw new ApiError(text.apiRequestError, { error });
  }
};

/**
 * Recursively queries all forge accounts for the user
 * @param apiService the apiService
 * @param url the url
 * @returns a collection of BIM360Account
 */
export const getAllAccAccounts = async (apiService: ApiService, url?: string): Promise<AccAccount[]> => {
  if (!url || !apiService) {
    return [];
  }

  const { data } = await apiService.get(url);
  const result = [...(data as ForgeAccountsQueryResponse).accounts];

  if (data.next) {
    const restData = await getAllAccAccounts(apiService, `${Paths.EA_API_PATH}${data.next}`);
    result.push(...restData);
  }

  return result;
};
/**
 * Retrieves all forge projects for the user
 * @returns a collection of BIM360Projects
 */
export const getProjects = async (accountId: string): Promise<AccProject[]> => {
  const { token, env } = await getAuthTokenAndEnv();

  // the ACC Api expects a non-prefixed account id,
  // so remove the "b." prefix if there is one
  const forgeAccountId = accountId.startsWith('b.') ? accountId.slice(2) : accountId;

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    env,
    token,
  });
  // use maximum limit to reduce number of required http requests
  try {
    const projectsPath = `${Paths.EA_API_PATH}/${Paths.PROJECT_ENTITLEMENTS_PATH}?limit=200&account_id=${forgeAccountId}`;
    return await getAllProjects(apiService, projectsPath);
  } catch (error: unknown) {
    throw new ApiError(text.apiRequestError, { error });
  }
};

/**
 * helper function for getProjects to recursively traverse all pages
 * @param apiService
 * @param url
 */
export const getAllProjects = async (apiService: ApiService, url?: string): Promise<AccProject[]> => {
  if (!url || !apiService) {
    return [];
  }

  // extract projects from results
  const { data } = await apiService.get(url);
  const result = [...(data as ForgeProjectsQueryResponse).projects];

  // recursively collect data from all pages and append to result list
  if (data.next) {
    const restData = await getAllProjects(apiService, `${Paths.EA_API_PATH}${data.next}`);
    result.push(...restData);
  }

  return result;
};

/**
 * Retrieves all folders of the given project for the user.
 * When parentUrn is provided, retrieves all sub-folders of the
 * parentUrn of the given project for the user.
 * @param projectId the project id
 * @param parentUrn the urn of the parent folder (empty for top-level folders)
 * @returns a collection of ProjectFolder
 */
export const getProjectFolders = async (projectId: string, parentUrn?: string): Promise<ProjectFolder[]> => {
  const { token, env } = await getAuthTokenAndEnv();

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    env,
    token,
  });
  const apiPath = PathsConfigMap[PathTypes.DM_PROJECTS_PATH][env].path;
  const foldersPath = parentUrn ? `${apiPath}/${projectId}/folders/${parentUrn}` : `${apiPath}/${projectId}/folders`;
  return getAllProjectFolders(apiService, foldersPath, 0);
};

/**
 * Recursively queries for all project folders. Increases offsetPage on every call
 * @param apiService the apiService
 * @param url the api url
 * @param offsetPage the number of pages to offset
 * @returns a collection of ProjectFolder
 */
const getAllProjectFolders = async (apiService: ApiService, url: string, offsetPage: number): Promise<ProjectFolder[]> => {
  if (!url || !apiService) {
    return [];
  }

  const queryPath = `${url}?limit=200&offset=${offsetPage}`;
  const { data } = (await apiService.get(queryPath)) as {
    data: ForgeDMProjectFoldersResponse;
  };
  const result = data.folders?.map(toProjectFolder) ?? [];

  if (data.has_next_page) {
    const restData = await getAllProjectFolders(apiService, url, offsetPage + 1);
    result.push(...restData);
  }

  return result;
};

/**
 * Converts a ForgeDMProjectFolder to a ProjectFolder
 * @param data the data to transform
 * @returns a ProjectFolder
 */
export const toProjectFolder = (data: ForgeDMProjectFolder): ProjectFolder => {
  const { urn, title, hidden, deleted, path, ...restOfData } = data;

  return {
    urn,
    title,
    path,
    hidden,
    projectId: restOfData.project_id,
    parentUrn: restOfData.parent_urn,
    hasSubfolders: restOfData.has_subfolders,
    deleted,
    folderType: restOfData.folder_type,
    isRoot: restOfData.is_root,
    viewOption: restOfData.view_option,
    permissionType: restOfData.permission_type,
    isSystemFolder: restOfData.is_system_folder,
  } as ProjectFolder;
};

/**
 * Converts a ForgeDMProjectDetails to a ProjectDetails
 * @param data the data to transform
 * @returns a ProjectDetails
 */
export const toForgeProjectDetails = (data: ForgeDMProjectDetails): ProjectDetails => {
  const { id, name, account_id, account_display_name } = data;

  return {
    projectId: id,
    projectName: name,
    accountId: account_id,
    accountName: account_display_name,
  } as ProjectDetails;
};

export const createNewFolder = async (projectId: string, parentUrn: string, title: string): Promise<ProjectFolder> => {
  const { token, env } = await getAuthTokenAndEnv();

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    env,
    token,
  });
  const apiPath = PathsConfigMap[PathTypes.DM_PROJECTS_PATH][env].path;
  const foldersPath = `${apiPath}/${projectId}/folders`;

  const payload = {
    title,
    parent_folder_urn: parentUrn,
  };
  const response: AxiosResponse<ForgeDMProjectFolder> = await apiService.post(foldersPath, payload);

  return toProjectFolder(response.data);
};

export const getUserProfile = async (): Promise<UserProfile> => {
  const { token, env } = await getAuthTokenAndEnv();

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, {
    env,
    token,
  });
  const userPath = `${Paths.USERPROFILE_API_PATH}/users/@me`;
  const userResponse: AxiosResponse<UserProfile> = await apiService.get(userPath);

  return userResponse.data;
};

export const getUserAnalyticsId = async (userId: string, webToken?: string): Promise<string> => {
  interface GetUserAnalyticsIdOptions {
    env: Environment;
    token: string;
  }
  const options: GetUserAnalyticsIdOptions = {
    env: (process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV,
    token: webToken || '',
  };
  if (!webToken) {
    const { token, env } = await getAuthTokenAndEnv();
    options.env = env;
    options.token = token;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.FORGE_API, options);

  const analyticsPath = `${Paths.IDENTITY_API_PATH}/users/${userId}/analytics`;
  const analyticsResponse: AxiosResponse<UserAnalytics> = await apiService.get(analyticsPath);

  return analyticsResponse.data.analyticsId;
};
