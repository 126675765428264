import ClickAwayListener from '@mui/base/ClickAwayListener';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useCallback, useEffect, useState } from 'react';
import text from '../../global/text.json';
import { extractVersionNumber } from '../../global/utils';
import { getMSIVersionInfo } from '../../services/artifacts';
import { AddinsVersionInfo } from '../../types/artifacts';
import { IconWrapper, StyledHelpOutlineIcon, StyledPaper, StyledPopper, VersionInfoWrapper } from './MIDWVersionInfo.styles';
import { MIDWVersionInfoNotAvailable } from './MIDWVersionInfoNotAvailable';

const versionInfoText = text.versionInfoPanel;

interface VersionInfoProps {
  MIDW_VERSION?: string;
}

export const MIDWVersionInfo: React.FC<VersionInfoProps> = ({ MIDW_VERSION }): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [versionInfo, setVersionInfo] = useState<AddinsVersionInfo | null>();

  const handleVersionInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorElement(null);
  };

  const getAddinsVersionInfo = useCallback(async () => {
    const latestVersionInfo = await getMSIVersionInfo();
    setVersionInfo(latestVersionInfo);
  }, []);

  useEffect(() => {
    getAddinsVersionInfo();
  }, [getAddinsVersionInfo]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <VersionInfoWrapper>
        <IconButton onClick={handleVersionInfoClick} data-testid="version-info-button">
          <StyledHelpOutlineIcon />
        </IconButton>
        {anchorElement ? (
          <StyledPopper
            open={Boolean(anchorElement)}
            anchorEl={anchorElement}
            placement="bottom"
            data-testid="version-info-data"
          >
            <StyledPaper>
              <List>
                <ListItem>
                  <ListItemText
                    primary={`${versionInfoText.midWebapp} ${MIDW_VERSION ? extractVersionNumber(MIDW_VERSION) : null}`}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={<strong>{versionInfoText.downloadAddins}</strong>} />
                </ListItem>
                {versionInfo ? (
                  <>
                    <ListItem
                      secondaryAction={
                        <a href={versionInfo.inventorFileURL}>
                          <IconButton edge="end">
                            <FileDownloadIcon />
                          </IconButton>
                        </a>
                      }
                    >
                      <IconWrapper>
                        <InsertDriveFile />
                      </IconWrapper>
                      <ListItemText primary={`${versionInfoText.midForInventor} ${versionInfo?.inventorVersion}`} />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <a href={versionInfo.revitFileURL}>
                          <IconButton edge="end">
                            <FileDownloadIcon />
                          </IconButton>
                        </a>
                      }
                    >
                      <IconWrapper>
                        <InsertDriveFile />
                      </IconWrapper>
                      <ListItemText primary={`${versionInfoText.midForRevit} ${versionInfo.revitVersion}`} />
                    </ListItem>
                  </>
                ) : (
                  <MIDWVersionInfoNotAvailable />
                )}
              </List>
            </StyledPaper>
          </StyledPopper>
        ) : null}
      </VersionInfoWrapper>
    </ClickAwayListener>
  );
};
