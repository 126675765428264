import browserApiService from '../services/browserApiService';
import { trim } from 'lodash';
import { InputRule } from '../interfaces/templates';
import { DynamicContentInput, TemplateInputType, TemplateRule } from 'mid-types';

export const getAssemblyVersion = (): Promise<string> => browserApiService?.getAssemblyVersion();

export const openMidWebapp = async (): Promise<boolean> => {
  const result = await browserApiService?.openExternalUrl(await browserApiService?.getMIDWebAppUrl());
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const getApplicationVersionNumber = (): Promise<string> => browserApiService?.getApplicationVersionNumber();

export const removeDoubleQuotesFromStringArray = (array: string[]): string[] =>
  array.map((value) => removeDoubleQuotes(value));
export const removeDoubleQuotes = (value: string): string => trim(value, '"');

export const addDoubleQuotesToStringArray = (array: string[]): string[] => array.map((value) => addDoubleQuotes(value));
export const addDoubleQuotes = (value: string): string => (value.startsWith('"') ? value : `"${value}"`);

export const addQuotesToTextParameters = <Input extends DynamicContentInput>(parameters: Array<Input>): Array<Input> =>
  parameters.map((parameter) => {
    if (parameter.type === TemplateInputType.MultiValueText) {
      const unTrimmedValue = parameter.value ? addDoubleQuotes(parameter.value) : parameter.value;
      const unTrimmedArray = addDoubleQuotesToStringArray(parameter.values);
      return { ...parameter, value: unTrimmedValue, values: unTrimmedArray };
    }

    if (parameter.type === TemplateInputType.Text) {
      const unTrimmedValue = addDoubleQuotes(parameter.value);
      return { ...parameter, value: unTrimmedValue };
    }
    return parameter;
  });

export const removeQuotesFromTextParameters = <Input extends DynamicContentInput>(parameters: Array<Input>): Array<Input> =>
  parameters.map((parameter) => {
    if (parameter.type === TemplateInputType.MultiValueText) {
      const trimmedValue = parameter.value ? removeDoubleQuotes(parameter.value) : parameter.value;
      const trimmedArray = removeDoubleQuotesFromStringArray(parameter.values);
      return { ...parameter, value: trimmedValue, values: trimmedArray };
    }

    if (parameter.type === TemplateInputType.Text) {
      const trimmedValue = removeDoubleQuotes(parameter.value);
      return { ...parameter, value: trimmedValue };
    }
    return parameter;
  });

export const saveToFile = async (content: string, fileName: string, fileExtension: string): Promise<string> => {
  const result = await browserApiService?.saveToFile(content, fileName, fileExtension);
  if (!result.value) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const inputsRulesToTemplateRules = (rules: InputRule[]): TemplateRule =>
  rules.reduce<TemplateRule>(
    (templateRule, rule) => (
      (templateRule[rule.key] = { code: rule.code, errorMessage: rule.errorMessage, ruleLabel: rule.label }), templateRule
    ),
    {},
  );
