import PhotoIcon from '@adsk/alloy-react-icon/es/lib/PhotoIcon';
import { styled } from '@mui/material/styles';

export const EmptyStateWrapper = styled('div')`
  width: ${({ theme }) => `${theme.var.folderBrowserTreeWidth}px`};
  border: 1px solid ${({ theme }) => theme.colors.primary.charcoal300};
  text-align: center;
  height: 100%;
  margin: ${({ theme }) => `${theme.var.marginBase + 4}px`} 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => `${theme.var.productFolderBrowserComponentHeight}px`};
  padding-left: ${({ theme }) => `${theme.var.paddingBase}px`};
`;

export const ProductFolderFlexContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 20px;
`;

export const ProductFolderBrowserContainer = styled('div')`
  height: ${({ theme }) => `${theme.var.productFolderBrowserComponentHeight}px`};
`;
export const TreeContainer = styled('div')`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ProductListContainer = styled('div')`
  width: ${({ theme }) => `${theme.var.folderBrowserTreeWidth}px`};
  margin-left: ${({ theme }) => `${theme.var.marginBase}px`};
  display: flex;
  flex-direction: column;
`;

export const ProductsList = styled('div')`
  margin: ${({ theme }) => `${theme.var.marginBase + 4}px`} 0 0 0;
  border: 1px solid ${({ theme }) => theme.colors.primary.charcoal300};
  max-height: ${({ theme }) => `${theme.var.productFolderBrowserComponentHeight}px`};
  overflow-y: auto;
`;

export const ProductCell = styled('div')<{ selected?: boolean; selectable?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'not-allowed')};
  min-height: ${({ theme }) => `${theme.var.treeCellMinHeight}px`};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.selectedTreeCellBackgroundColor : 'inherit')};
  ${({ theme }) => theme.typography.bodyMedium}
`;

export const ProductIcon = styled(PhotoIcon)`
  margin-left: ${({ theme }) => `${theme.var.marginBase * 2}px`};
`;

export const Text = styled('p')`
  ${({ theme }) => theme.typography.bodyMedium}
  margin: ${({ theme }) => `${theme.var.marginBase}px`};
  padding: 0;
  cursor: inherit;
`;

export const Title = styled(Text)`
  margin: ${({ theme }) => `${theme.var.marginBase}px`} 0 0 ${({ theme }) => `${theme.var.marginBase}px`};
`;
