import { Box, ListItemIcon, Paper, Popper, styled } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(() => ({
  fontSize: 15,
}));

export const StyledPaper = styled(Paper)(() => ({
  minWidth: '350px',
}));

export const StyledPopper = styled(Popper)(() => ({
  zIndex: 100,
}));

export const VersionInfoWrapper = styled(Box)(() => ({
  alignSelf: 'center',
}));

export const IconWrapper = styled(ListItemIcon)(() => ({
  minWidth: 'auto',
  paddingRight: '5px',
  alignSelf: 'center',
}));
