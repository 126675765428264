import React, { useCallback, useContext, useEffect } from 'react';
import InstanceDetailsPanel from '../InstanceDetailsPanel';
import ProductsInstancesDataGrid from '../ProductsInstancesDataGrid';
import viewerService from '../../../services/viewer/viewerService';
import { ModelViewer } from '../ModelViewer/ModelViewer';

import {
  InstanceSidebarHeader,
  InstanceSidebarHeaderGroup,
  InstanceSidebarHeaderRow,
  InstancesSidebar,
  ModelViewContainer,
} from './InstancesPanel.style';
import {
  SelectionFilterToggle,
  ExpandToggleButton,
  GenerateOutputsButton,
  ProductSelectDropDown,
} from './InstancePanelComponents';
import { Instance } from '../../../types/product';
import ProductContext from '../../../context/ProductStore/Product.context';
import useGenerateOutputs from './hooks/useGenerateOutputs';
import useInstancePanelData from './hooks/useInstancePanelData';
import useInstancePanelUI from './hooks/useInstancePanelUI';
import useInstanceSelectionFilter from './hooks/useInstanceSelectionFilter';
import useProductSelection from './hooks/useProductSelection';
import useMIDElementsFromViewer from './hooks/useMIDElementsFromViewer';
import useInstancesSelection from './hooks/useInstancesSelection';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FolderContentRow } from '../ModelsFolderContent/ModelsFolderContent.types';
import { NotificationContext } from 'mid-react-common';
import { useNavigationRoutines } from '../../../global/hooks/hooks';
import text from '../../../global/text.json';
import { DynamicContentProduct } from 'mid-types';

export const InstancesPanel: React.FC = () => {
  const { logAndShowNotification } = useContext(NotificationContext);
  const { instances, products, variants, productsLoading, filteredProductId } = useContext(ProductContext);
  const { currentlyOpenModel, selectedFolderUrn, setCurrentlyOpenModel, setSelectedFolderUrn, setSelectedModelId } =
    useContext(ModelSelectionContext);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { parseOpenModelURLParameter, serializeOpenModelDataAsURLParameter } = useNavigationRoutines();
  const openModelURLParameter = searchParams.get('openModel');

  const productsList: DynamicContentProduct[] = [...products.values()];

  // Data
  const {
    dataGridInstances,
    selectedInstances,
    setSelectedInstances,
    selectedInstancesIds,
    hasDataGridInstancesBeenInitialized,
  } = useInstancePanelData();

  // UI
  const { expanded, handleToggleExpanded, showInstanceDetailsPanel, setShowInstanceDetailsPanel, dataGridColumns } =
    useInstancePanelUI({
      dataGridInstances,
    });

  // Selection Filter
  const { visibilityFilter, instancesGridFilters, handleInstanceSelectionFilterChange } = useInstanceSelectionFilter();

  // Generate Outputs
  const { handleGenerateOutputsButtonClick, disabled } = useGenerateOutputs(selectedInstances || []);

  // Product Selection Dropdown
  const { handleSelectedProductIdChange } = useProductSelection();

  // MID elements instances from Viewer
  const { instancesLoading } = useMIDElementsFromViewer();

  const handleInstanceDetailsPanelVisibility = useCallback(
    (showInstanceDetailsPanel: boolean) => {
      setShowInstanceDetailsPanel(showInstanceDetailsPanel);
    },
    [setShowInstanceDetailsPanel],
  );

  // Instances Selection
  const { handleInstancesSelection } = useInstancesSelection({
    dataGridInstances,
    selectedInstances,
    setSelectedInstances,
    handleInstanceDetailsPanelVisibility,
  });

  const getInstancesByProduct = (instancesToFilter: Instance[] | undefined) => {
    const filteredInstances = instancesToFilter?.filter((element) =>
      dataGridInstances.some((instance) => element.contentId === instance.contentId),
    );
    return filteredInstances || [];
  };

  useEffect(() => {
    viewerService.resizeViewer();
  }, [showInstanceDetailsPanel]);

  useEffect(() => {
    const shouldPreventInstancesTabOpening = !currentlyOpenModel && !openModelURLParameter;
    const shouldRestoreOpenModelStateByURLParameter = !currentlyOpenModel && openModelURLParameter;
    const shouldStoreOpenModelStateInURLParameter = currentlyOpenModel && !openModelURLParameter;

    if (shouldPreventInstancesTabOpening) {
      navigate('..');
    }

    if (shouldRestoreOpenModelStateByURLParameter) {
      try {
        const parsedOpenModel = parseOpenModelURLParameter(openModelURLParameter);

        setCurrentlyOpenModel({
          lmvModelFileId: parsedOpenModel.lmvModelFileId,
          id: parsedOpenModel.itemUrn,
        } as FolderContentRow);

        setSelectedFolderUrn(parsedOpenModel.folderUrn);
        setSelectedModelId(parsedOpenModel.itemUrn);
      } catch (error) {
        logAndShowNotification({ error, message: text.common.malformedOpenModel });
        navigate('..');
      }
    }

    if (shouldStoreOpenModelStateInURLParameter) {
      searchParams.set('openModel', serializeOpenModelDataAsURLParameter(currentlyOpenModel, selectedFolderUrn));
      setSearchParams(searchParams);
    }
  }, [
    currentlyOpenModel,
    openModelURLParameter,
    selectedFolderUrn,
    navigate,
    setCurrentlyOpenModel,
    setSelectedFolderUrn,
    setSelectedModelId,
    searchParams,
    setSearchParams,
    logAndShowNotification,
    parseOpenModelURLParameter,
    serializeOpenModelDataAsURLParameter,
  ]);
  return (
    <>
      <InstancesSidebar expanded={expanded}>
        <InstanceSidebarHeader>
          <InstanceSidebarHeaderRow>
            <InstanceSidebarHeaderGroup>
              <ProductSelectDropDown
                value={filteredProductId || ''}
                onChange={handleSelectedProductIdChange}
                productList={productsList}
                expanded={expanded}
              />
              <GenerateOutputsButton
                disabled={disabled}
                handleGenerateOutputsButtonClick={handleGenerateOutputsButtonClick}
              />
              <ExpandToggleButton expanded={expanded} onToggle={handleToggleExpanded} />
            </InstanceSidebarHeaderGroup>
          </InstanceSidebarHeaderRow>
          <SelectionFilterToggle
            value={visibilityFilter}
            onToggle={handleInstanceSelectionFilterChange}
            allInstances={getInstancesByProduct(instances)}
            selectedInstances={getInstancesByProduct(selectedInstances)}
          />
        </InstanceSidebarHeader>

        <ProductsInstancesDataGrid
          midModelInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds}
          handleInstancesSelection={handleInstancesSelection}
          columns={dataGridColumns}
          tableLoading={instancesLoading || productsLoading || !hasDataGridInstancesBeenInitialized}
          instancesGridFilters={instancesGridFilters}
        />
      </InstancesSidebar>

      {showInstanceDetailsPanel && filteredProductId && (
        <InstanceDetailsPanel
          visibleInstances={dataGridInstances}
          selectedInstancesIds={selectedInstancesIds || []}
          variants={variants}
          currentProduct={products.get(filteredProductId)}
          handleInstanceDetailsPanelVisibility={handleInstanceDetailsPanelVisibility}
        />
      )}
      {!expanded && (
        <ModelViewContainer>
          <ModelViewer />
        </ModelViewContainer>
      )}
    </>
  );
};
