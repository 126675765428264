import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { MouseEventHandler } from 'react';
import text from '../../../global/text.json';
import {
  ConfirmationDialogBody,
  ConfirmationDialogContainer,
  ConfirmationDialogFooter,
  ConfirmationDialogTitle,
} from './ConfirmationDialog.styles';

const dialogText = text.confirmDialog;

type DataAttributes = {
  [key in `data-${string}`]?: string;
};

export interface ConfirmationDialogProps {
  open: boolean;
  message: string;
  title?: string;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
}

export const ConfirmationDialog = ({
  open,
  message,
  title,
  onCancel,
  onConfirm,
  ...dataAttributes
}: ConfirmationDialogProps & DataAttributes): JSX.Element => (
  <Modal keepMounted open={open} onClose={onCancel} {...dataAttributes}>
    <ConfirmationDialogContainer>
      <ConfirmationDialogTitle>
        <Typography variant="h6" component="h2">
          {title || dialogText.confirmation}
        </Typography>
      </ConfirmationDialogTitle>
      <Divider />
      <ConfirmationDialogBody>
        <Typography variant="body1" component="p">
          {message}
        </Typography>
      </ConfirmationDialogBody>
      <ConfirmationDialogFooter>
        <Button variant="outlined" onClick={onCancel}>
          {dialogText.cancel}
        </Button>
        <Button variant="contained" onClick={onConfirm} data-testid="modal-confirm-button">
          {dialogText.confirm}
        </Button>
      </ConfirmationDialogFooter>
    </ConfirmationDialogContainer>
  </Modal>
);
