import { PageContainer } from '../pages.styles';
import { Outlet } from 'react-router-dom';
import { NavIcon, NavVertical, SidebarBox } from '../../App.style';
import text from '../../global/text.json';
import { useDefaultNavigation } from '../../global/hooks/hooks';
import { routes } from '../../routes';

export const OutputsPage = (): JSX.Element => {
  useDefaultNavigation(routes.outputs.id, routes.review.path);

  return (
    <PageContainer>
      <SidebarBox>
        <NavVertical to={routes.review.path}>
          <NavIcon />
          {text.outputsSubNavs.review}
        </NavVertical>
        <NavVertical to={routes.settings.path}>
          <NavIcon />
          {text.outputsSubNavs.settings}
        </NavVertical>
      </SidebarBox>
      <Outlet />
    </PageContainer>
  );
};
