import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useContext } from 'react';
import NotificationContext from '../../context/NotificationStore/Notification.context';

const MIDNotification: React.FC = () => {
  const { open, message, severity, handleClose, anchor, isAutoHideDurationEnable } = useContext(NotificationContext);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={anchor}
      key={message}
      {...(isAutoHideDurationEnable && { autoHideDuration: 6000 })}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MIDNotification;
