import { ProductSelectDropdown, ProductSelectDropDownContainer } from './InstancesPanel.style';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { instancesVisibilityFilter } from '../../../global/constants/common';
import { LoadingButton } from '@mui/lab';
import { UseGenerateOutputsState } from './hooks/useGenerateOutputs';
import { Instance } from '../../../types/product';
import text from '../../../global/text.json';
import { Tooltip } from '@mui/material';
import { DynamicContentProduct } from 'mid-types';

const intancePanelText = text.instancePanel;

interface ProductSelectDropDownProps {
  value: string | undefined;
  onChange: (event: SelectChangeEvent) => void;
  productList: DynamicContentProduct[];
  expanded: boolean;
}

export const ProductSelectDropDown = ({
  value,
  onChange,
  productList,
  expanded,
}: ProductSelectDropDownProps): JSX.Element => (
  <ProductSelectDropDownContainer>
    <Typography variant="body2">Product</Typography>
    <ProductSelectDropdown size="small" value={value} onChange={onChange} expanded={expanded}>
      {productList.map((product) => (
        <MenuItem key={`product-${product.contentId}`} value={product.contentId}>
          <Typography variant="body2" noWrap>
            {product.name}
          </Typography>
        </MenuItem>
      ))}
    </ProductSelectDropdown>
  </ProductSelectDropDownContainer>
);

interface ExpandToggleButtonProps {
  expanded: boolean;
  onToggle: (event: React.MouseEvent<HTMLElement>) => void;
}

export const ExpandToggleButton = ({ expanded, onToggle }: ExpandToggleButtonProps): JSX.Element => (
  <IconButton onClick={onToggle}>
    {expanded && <KeyboardDoubleArrowLeft />}
    {!expanded && <KeyboardDoubleArrowRight />}
  </IconButton>
);

export const GenerateOutputsButton = ({
  disabled,
  handleGenerateOutputsButtonClick,
}: UseGenerateOutputsState): JSX.Element => (
  <Tooltip title={intancePanelText.generateOutputs}>
    <LoadingButton variant="outlined" onClick={handleGenerateOutputsButtonClick} disabled={disabled}>
      <Typography variant="inherit" noWrap>
        {intancePanelText.generateOutputs}
      </Typography>
    </LoadingButton>
  </Tooltip>
);

interface SelectionFilterToggleProps {
  value: string;
  onToggle: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  allInstances: Instance[] | undefined;
  selectedInstances?: Instance[];
}

export const SelectionFilterToggle = ({
  value,
  onToggle,
  allInstances,
  selectedInstances,
}: SelectionFilterToggleProps): JSX.Element => {
  const countSelected = selectedInstances?.length || 0;
  const allInstancesCount = allInstances?.length || 0;
  const countUnselected = allInstancesCount - countSelected;
  const countAll = allInstances?.length || 0;

  return (
    <ToggleButtonGroup color="primary" value={value} exclusive onChange={onToggle} size="small">
      <ToggleButton value={instancesVisibilityFilter.SELECTED} data-testid="selection-filter-toggle-selected">
        <Typography variant="body2" noWrap>
          {`${intancePanelText.selected} (${countSelected})`}
        </Typography>
      </ToggleButton>
      <ToggleButton value={instancesVisibilityFilter.NOT_SELECTED}>
        <Typography variant="body2" noWrap>
          {`${intancePanelText.notSelected} (${countUnselected})`}
        </Typography>
      </ToggleButton>
      <ToggleButton value={instancesVisibilityFilter.ALL}>
        <Typography variant="body2" noWrap>
          {`${intancePanelText.all} (${countAll})`}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
