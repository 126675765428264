class AuthServiceMock {
  public token = 'dummytoken';

  public async authenticate() {
    return new Promise<string>((resolve) => {
      resolve(this.token);
    });
  }
}

export default new AuthServiceMock();
