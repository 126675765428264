import ListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { AccAccount } from 'mid-types';
import text from '../../../global/text.json';
import { AccountImage, AccountListContainer, AccountsListTitle, AccountsMenuList } from './AccountsList.style';
import { useAccountsList } from './useAccountsList';

interface AccountsListProps {
  visible: boolean;
  accounts: AccAccount[] | undefined;
}

export const AccountsList = ({ visible, accounts }: AccountsListProps): JSX.Element => {
  const { handleAccountItemClick } = useAccountsList();
  const accountsText = text.accountsList;

  return (
    <AccountListContainer visible={visible}>
      <AccountsListTitle>
        <Typography variant="h6">{accountsText.accounts}</Typography>
      </AccountsListTitle>
      {accounts && (
        <AccountsMenuList>
          {accounts.map((account) => (
            <MenuItem key={account.account_id} onClick={() => handleAccountItemClick(account)}>
              <ListItemAvatar>
                <AccountImage src={account.image_url} />
              </ListItemAvatar>
              <Typography variant="body2">{account.account_display_name}</Typography>
            </MenuItem>
          ))}
        </AccountsMenuList>
      )}
    </AccountListContainer>
  );
};
