import React from 'react';
import text from '../../mid-react-common.text.json';
import { ContentTitle, EmptyStateSubTitle, EmptyStateContainer, EmptyStateImage } from '../../global/styles/Common.styles';
import buildConstructionGreySvg from '../../assets/svg/building_construction_grey.svg';
import desktopComputerGreySvg from '../../assets/svg/desktop_computer_grey.svg';
import pagesPhotoSvg from '../../assets/svg/pages_photo.svg';
import pagesTextGreySvg from '../../assets/svg/pages_text_grey.svg';
import filingCabinetGreySvg from '../../assets/svg/filing_cabinet_grey.svg';

const illustrations = {
  BUILDING_CONSTRUCTION_GREY: buildConstructionGreySvg,
  SYSTEM_ERROR: desktopComputerGreySvg,
  PAGES_PHOTO: pagesPhotoSvg,
  NO_RESULTS: pagesTextGreySvg,
  FILING_CABINET_GREY: filingCabinetGreySvg,
};

type IllustrationType = keyof typeof illustrations;

type Illustrations = {
  [key in IllustrationType]: key;
};

export const EMPTY_STATE_ILLUSTRATION_TYPES: Illustrations = {
  BUILDING_CONSTRUCTION_GREY: 'BUILDING_CONSTRUCTION_GREY',
  SYSTEM_ERROR: 'SYSTEM_ERROR',
  PAGES_PHOTO: 'PAGES_PHOTO',
  NO_RESULTS: 'NO_RESULTS',
  FILING_CABINET_GREY: 'FILING_CABINET_GREY',
};

export interface MIDEmptyStateProps {
  title?: string;
  description?: string;
  illustrationType?: IllustrationType;
  hideIllustration?: boolean;
}

export const MIDEmptyState: React.FC<MIDEmptyStateProps> = ({
  title = text.EmptyStateNoData,
  illustrationType = EMPTY_STATE_ILLUSTRATION_TYPES.FILING_CABINET_GREY,
  description,
  hideIllustration,
}): JSX.Element => (
  <EmptyStateContainer>
    {!hideIllustration && <EmptyStateImage src={illustrations[illustrationType]} alt={text.emptyStateAltText} />}
    <ContentTitle>{title}</ContentTitle>
    {description && <EmptyStateSubTitle>{description}</EmptyStateSubTitle>}
  </EmptyStateContainer>
);

export default MIDEmptyState;
