import { createTheme } from '@mui/material/styles';
import colors from './colors';

const themeScreenSizes = {
  tablet: '768px',
  laptop: '1024px',
  desktop: '1200px',
};

const boxShadowBase = 5;
const marginBase = 10;
const paddingBase = 10;

const mainMargin = marginBase * 2;
const headersHeight = 60; // Header and subheader combined
const footerHeight = 60;
const tabsHeight = 26;
const tabsVerticalMargin = marginBase * 2;
const labelHeight = 16;
const largeLabelHeight = 20;
const inputHeight = 36;
const navIconSize = 80;
const navBorderWidth = 2;

// Components
const draftHeader = 50;
const heading2 = 26;
const smallButtonHeight = 24;
const largeButtonHeight = 36;
const editInputsItemCountHeight = 48;
const wideInputControl = 300;
const narrowInputControl = 150;
const borderHeight = 1;
const draftConfigurationHeaderHeight = 50;
const midTreeMinWidth = 400;
const midTreeHeight = 500;
const formDisabledOpacity = 0.5;
const muiTableRowHeight = 52;

// ProductFolderBrowser
const folderBrowserTreeWidth = midTreeMinWidth;
const productFolderBrowserComponentHeight = 500;
const treeCellMinHeight = 40;
const selectedTreeCellBackgroundColor = colors.primary.charcoal300;

const themeVar = {
  inputHeight,
  largeLabelHeight,
  labelHeight,
  boxShadowBase,
  mainMargin,
  marginBase,
  paddingBase,
  headersHeight,
  footerHeight,
  topBars: headersHeight + tabsHeight + mainMargin * 2,
  smallButtonHeight,
  largeButtonHeight,
  tabsVerticalMargin,
  muiTableRowHeight,
  heading2,
  draftHeader,
  wideInputControl,
  narrowInputControl,
  editInputsMenuHeight:
    tabsVerticalMargin * 2 +
    smallButtonHeight * 2 +
    marginBase * 3.5 +
    editInputsItemCountHeight +
    borderHeight * 2 +
    draftConfigurationHeaderHeight,
  editInputsItemCountHeight,
  editInputsContainerHeight: tabsVerticalMargin * 2 + heading2 + marginBase * 3 + draftConfigurationHeaderHeight,
  editInputsTableHeight:
    tabsVerticalMargin * 2 + largeButtonHeight + marginBase * 3 + borderHeight * 2 + draftConfigurationHeaderHeight,
  draftsTableHeight: tabsVerticalMargin * 2 + draftHeader + borderHeight * 2,
  outputsAccordionHeight: tabsVerticalMargin * 2 + draftHeader + borderHeight * 2,
  draftConfigurationHeaderHeight,
  midTreeMinWidth,
  midTreeHeight,
  formDisabledOpacity,
  productFolderBrowserComponentHeight,
  folderBrowserTreeWidth,
  treeCellMinHeight,
  selectedTreeCellBackgroundColor,
  navIconSize,
  navBorderWidth,
};

const fontFamilyMixin = {
  fontFamily: 'Artifakt Element,sans-serif',
};

const themeTypography = {
  bodyMedium: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    ...fontFamilyMixin,
  },
  labelLarge: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    ...fontFamilyMixin,
  },
  labelMedium: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    ...fontFamilyMixin,
  },
  labelSmall: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    ...fontFamilyMixin,
  },
  heading2: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '26px',
    ...fontFamilyMixin,
  },
  heading3: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    ...fontFamilyMixin,
  },
  heading4: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    ...fontFamilyMixin,
  },
  caption: {
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '15px',
    ...fontFamilyMixin,
  },
  bodyMediumBold: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    ...fontFamilyMixin,
  },
  button: {
    textTransform: 'none',
  },
};

const themeColors = {
  ...colors,
  selectedTreeCellBackgroundColor,
};

// have to specify the module augmentation right in this file (not in a separate d.ts file), otherwise other monorepo apps
// won't see them
declare module '@mui/material/styles' {
  interface Theme {
    var: typeof themeVar;
    colors: typeof themeColors;
    typography: typeof themeTypography;
    screenSizes: typeof themeScreenSizes;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    var: typeof themeVar;
    colors: typeof themeColors;
    screenSizes: typeof themeScreenSizes;
  }
}

declare module '@mui/material/styles/createPalette' {
  // enhance the pallet.background type
  interface TypeBackground {
    sidebar: string;
  }
}

const theme = createTheme({
  typography: themeTypography,
  var: themeVar,
  colors: themeColors,
  screenSizes: themeScreenSizes,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          ...themeTypography.bodyMedium,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& svg': {
            width: '.9em',
            height: '.9em',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: 'white',
        },
      },
    },
  },
});

export default theme;
