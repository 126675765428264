import { NotificationContext } from 'mid-react-common';
import { useContext, useEffect } from 'react';
import { useNavigate, useLocation, useParams, generatePath, useSearchParams } from 'react-router-dom';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import { useNavigationRoutines } from '../../../global/hooks/hooks';
import { routes } from '../../../routes';
import { FolderContentRow } from '../ModelsFolderContent/ModelsFolderContent.types';
import text from '../../../global/text.json';

type UseMaintainRoutingState = {
  folderUrnFromURL: string | undefined;
  setRouteToFolder: (folderUrn: string) => void;
  removeDetailsSegmentFromRoute: () => void;
};

const useMaintainRouting = (): UseMaintainRoutingState => {
  const {
    setSelectedModelId,
    setSelectedModelFolder,
    selectedFolderUrn,
    selectedModelId,
    selectedModelFolder,
    currentlyOpenModel,
    setCurrentlyOpenModel,
  } = useContext(ModelSelectionContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { parseOpenModelURLParameter, serializeOpenModelDataAsURLParameter } = useNavigationRoutines();
  const { folderUrn, itemUrn, details } = useParams<{ folderUrn: string; itemUrn: string; details: string }>();

  const setRouteToFolder = (newFolderUrn: string) => {
    setSelectedModelId(undefined);
    setSelectedModelFolder(null);

    // don't navigate to the same route
    if (location.pathname.endsWith(folderUrn || newFolderUrn)) {
      return;
    }

    const foldersPath = generatePath(routes.folders.path, {
      folderUrn: folderUrn || newFolderUrn,
    });

    navigate(foldersPath, {
      replace: true,
    });
  };

  const removeDetailsSegmentFromRoute = () => {
    if (!selectedFolderUrn || !itemUrn) {
      return;
    }

    const path = generatePath([routes.folders.path, routes.folderItems.path].join('/'), {
      folderUrn: selectedFolderUrn,
      itemUrn,
    });

    if (!location.pathname.endsWith(path)) {
      navigate(path, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    // itemUrn may come from URL or from the model data
    const selectedItemUrn = selectedModelId || itemUrn;

    const folderAndItemDataAvailable = !!(selectedFolderUrn && selectedItemUrn);
    const folderDataAvailable = !!selectedFolderUrn;
    const shouldStoreOpenModelStateInURLParameter = !!currentlyOpenModel?.lmvModelFileId;

    if (shouldStoreOpenModelStateInURLParameter) {
      searchParams.set('openModel', serializeOpenModelDataAsURLParameter(currentlyOpenModel, selectedFolderUrn));
    }

    // if both folder and item ids are available, navigate to the screen with preselected item
    if (folderAndItemDataAvailable) {
      let path = generatePath([routes.folders.path, routes.folderItems.path].join('/'), {
        folderUrn: selectedFolderUrn,
        itemUrn: selectedItemUrn,
        // determine if the details path segment should be presented
        // details data can come from the URL
        // if the model has been selected by the user, it should be reflected in URL
        details: details || selectedModelFolder ? 'details' : '',
      });

      if (shouldStoreOpenModelStateInURLParameter) {
        path += '?' + searchParams;
      }

      // prevent infinite loop
      if (!location.pathname.endsWith(path)) {
        navigate(path, {
          replace: true,
        });
      }
      // if there is only folder available in URL
    } else if (folderDataAvailable) {
      let path = generatePath(routes.folders.path, {
        folderUrn: selectedFolderUrn,
      });

      if (shouldStoreOpenModelStateInURLParameter) {
        path += '?' + searchParams;
      }

      // prevent infinite loop
      if (!location.pathname.endsWith(path)) {
        navigate(path, {
          replace: true,
        });
      }
    }
  }, [
    selectedFolderUrn,
    selectedModelId,
    itemUrn,
    location.pathname,
    selectedModelFolder,
    details,
    navigate,
    currentlyOpenModel?.lmvModelFileId,
    currentlyOpenModel?.id,
    searchParams,
    currentlyOpenModel,
    serializeOpenModelDataAsURLParameter,
  ]);

  useEffect(() => {
    const openModelURLParameter = searchParams.get('openModel');
    const shouldRestoreOpenModelStateByURLParameter = openModelURLParameter && !currentlyOpenModel;

    if (shouldRestoreOpenModelStateByURLParameter) {
      try {
        const parsedOpenModel = parseOpenModelURLParameter(openModelURLParameter);
        setCurrentlyOpenModel({
          lmvModelFileId: parsedOpenModel.lmvModelFileId,
          id: parsedOpenModel.itemUrn,
        } as FolderContentRow);
      } catch (error) {
        logAndShowNotification({ error, message: text.common.malformedOpenModel });
        navigate('..');
      }
    }
  }, [
    currentlyOpenModel,
    logAndShowNotification,
    navigate,
    parseOpenModelURLParameter,
    searchParams,
    selectedFolderUrn,
    selectedModelFolder?.id,
    setCurrentlyOpenModel,
    setSearchParams,
  ]);

  return {
    folderUrnFromURL: folderUrn,
    setRouteToFolder,
    removeDetailsSegmentFromRoute,
  };
};

export default useMaintainRouting;
