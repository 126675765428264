import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const DetailsSidebar = styled(Box)(() => ({
  padding: '8px 0px 8px 8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  minWidth: '390px',
  maxWidth: '450px',
}));

export const DetailsSidebarHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: '1px solid #ddd',
}));

export const DetailsSidebarHeaderStart = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '12px',
}));

export const DetailsSidebarContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflow: 'auto',
  flexGrow: '10',
}));
