import { DisabledNavVertical, NavIcon, NavVertical, SidebarBox } from '../../App.style';
import { generatePath, Outlet } from 'react-router-dom';
import { ReactElement, useContext } from 'react';
import ModelSelectionContext from '../../context/ModelSelectionStore/ModelSelection.context';
import text from '../../global/text.json';
import { useDefaultNavigation } from '../../global/hooks/hooks';
import { PageContainer } from '../pages.styles';
import { routes } from '../../routes';

const ProductsPage = (): JSX.Element => {
  const { currentlyOpenModel, selectedFolderUrn, selectedModelId } = useContext(ModelSelectionContext);

  useDefaultNavigation(routes.products.id, routes.models.path);

  // dynamically generate route to the Models page
  const getModelsRoute = () => {
    if (selectedFolderUrn && selectedModelId) {
      return generatePath([routes.models.path, routes.folders.path, routes.folderItems.path].join('/'), {
        folderUrn: selectedFolderUrn,
        itemUrn: selectedModelId,
      });
    }

    return routes.models.path;
  };

  const createInstancesNav = (children: ReactElement) =>
    currentlyOpenModel ? (
      <NavVertical to={routes.instances.path}>{children}</NavVertical>
    ) : (
      <DisabledNavVertical>{children}</DisabledNavVertical>
    );

  return (
    <PageContainer>
      <SidebarBox>
        <NavVertical to={getModelsRoute()}>
          <NavIcon data-testid="models-nav" />
          {text.productsSubNavs.models}
        </NavVertical>
        {createInstancesNav(
          <>
            <NavIcon data-testid="instances-nav" />
            {text.productsSubNavs.instances}
          </>,
        )}
      </SidebarBox>
      <Outlet />
    </PageContainer>
  );
};

export default ProductsPage;
