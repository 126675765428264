import { mockAccount, mockAccount2, mockProject, mockProject2 } from 'mid-utils';
import { DraftTemplate, DraftTemplateInputParameter, DraftTemplateIProperty, OutputType } from '../../interfaces/templates';

import { mockPropertiesDraft1, mockPropertiesDraft2 } from './mockParametersAndIProperties';

import { thumbnails } from './mockThumbnailInBase64';
import { codeBlocksWorkspaceMock } from './codeblocksWorkspaceMock';
import { TemplateInputType } from 'mid-types';

export const mockDraft1Parameters: DraftTemplateInputParameter[] = mockPropertiesDraft1.parameters;
export const mockDraft1IProperties: DraftTemplateIProperty[] = mockPropertiesDraft1.iProperties;

export const mockNewDraft1: DraftTemplate = {
  name: 'Wall2000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.account_id,
    name: mockAccount.account_display_name,
  },
  project: {
    id: mockProject.project_id,
    name: mockProject.project_display_name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  inputs: [...mockDraft1Parameters, ...mockDraft1IProperties],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockDraft1: DraftTemplate = {
  id: '6ca6948f-8646-449a-b1a6-b12ea1dbc990',
  name: 'Wall2000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount.account_id,
    name: mockAccount.account_display_name,
  },
  project: {
    id: mockProject.project_id,
    name: mockProject.project_display_name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  inputs: [...mockDraft1IProperties, ...mockDraft1Parameters],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockDraft2Parameters: DraftTemplateInputParameter[] = mockPropertiesDraft2.parameters;
export const mockDraft2IProperties: DraftTemplateIProperty[] = mockPropertiesDraft2.iProperties;
export const mockNewDraft2: DraftTemplate = {
  name: 'Wall3000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount2.account_id,
    name: mockAccount2.account_display_name,
  },
  project: {
    id: mockProject2.project_id,
    name: mockProject2.project_display_name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  inputs: [...mockDraft2IProperties, ...mockDraft2Parameters],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
  ],
};

export const mockDraft2: DraftTemplate = {
  id: '843c1a80-4706-4657-9a69-eb58dbec475f',
  name: 'Wall3000',
  lastUpdated: 1644443044753,
  account: {
    id: mockAccount2.account_id,
    name: mockAccount2.account_display_name,
  },
  project: {
    id: mockProject2.project_id,
    name: mockProject2.project_display_name,
  },
  folder: {
    id: '9876',
    name: 'folderName',
    parentPath: [],
  },
  topLevelFolder: 'C:/path/to/folder',
  inventorProject: '/project1/project.ipj',
  assembly: '/project2/assembly.iam',
  thumbnail: thumbnails[0],
  inputs: [...mockDraft2IProperties, ...mockDraft2Parameters],
  rules: [],
  outputs: [
    {
      type: OutputType.RFA,
      options: {
        modelStates: ['Master'],
        family: 'Wall',
        category: 'Wall',
      },
    },
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['BIM'],
      },
    },
  ],
};

export const mockDraft3: DraftTemplate = {
  id: '9f45c660-69be-4529-8e2d-e963e9f94cd1',
  name: 'MID Shelving Unit',
  lastUpdated: 1683162626704,
  account: {
    id: '',
    name: '',
  },
  project: {
    id: '',
    name: '',
  },
  folder: {
    id: '',
    name: '',
    parentPath: [],
  },
  topLevelFolder: 'C:\\Users\\username\\MID Shelving Unit',
  inventorProject: '\\MID Shelving Unit.ipj',
  assembly: '\\MID Shelving Unit.iam',
  thumbnail: thumbnails[0],
  inputs: [
    {
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Height',
      values: [30, 42, 54, 66],
      unit: 'in',
      value: 54,
      onChange: [],
      allowCustomValue: true,
    },
    {
      type: TemplateInputType.MultiValueText,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Material',
      values: [
        '"Laminated Veneer Lumber"',
        '"Particleboard"',
        '"Ash"',
        '"Birch"',
        '"Mahogany"',
        '"Maple"',
        '"Oak, Red"',
        '"Pine"',
        '"Plywood, Finish"',
        '"Spruce"',
        '"Walnut"',
      ],
      unit: 'Text',
      value: '"Oak, Red"',
    },
    {
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      readOnly: false,
      label: '',
      name: 'Shelves_Qty',
      values: [1, 2, 3],
      unit: 'ul',
      value: 3,
      onChange: [],
      allowCustomValue: true,
    },
  ],
  rules: [
    {
      key: 'currentRule',
      code: "parameters['Height'].values = [30, 42, 54, 66];parameters['Material'].values = ['Laminated Veneer Lumber', 'Particleboard', 'Ash', 'Birch', 'Mahogany', 'Maple', 'Oak, Red', 'Pine', 'Plywood, Finish', 'Spruce', 'Walnut'];parameters['Shelves_Qty'].values = [1, 2, 3, 4];if ((parameters['Height'].value) == 30) {\n  parameters['Shelves_Qty'].values = [1];} else if ((parameters['Height'].value) == 42) {\n  parameters['Shelves_Qty'].values = [1, 2];} else if ((parameters['Height'].value) == 54) {\n  parameters['Shelves_Qty'].values = [1, 2, 3];} else if ((parameters['Height'].value) == 66) {\n  parameters['Shelves_Qty'].values = [1, 2, 3, 4];parameters['Material'].values = ['Oak, Red', 'Maple', 'Mahogany', 'Birch'];}\n",
    },
  ],
  outputs: [
    {
      type: OutputType.BOM,
      options: {
        modelStates: ['[Primary]'],
      },
    },
    {
      type: OutputType.RFA,
      options: {
        category: 'Furniture',
        family: 'MID Shelving Unit',
        modelStates: ['[Primary]'],
      },
    },
  ],
  codeBlocksWorkspace: codeBlocksWorkspaceMock,
};

export const mockDrafts: DraftTemplate[] = [mockDraft1, mockDraft2, mockDraft3];
