import { PostVariantPayload } from '../types/variants';
import { AuthenticationError, logError, VariantFetchError, VariantOutputFetchError, VariantPostError } from 'mid-utils';
import text from '../global/text.json';
import { ApiServiceFactory } from 'mid-addin-lib/services/api.service';
import { DynamicContentVariant, DynamicContentVariantOutput, ENVIRONMENT, Environment } from 'mid-types';
import { ServiceTypes } from 'mid-addin-lib';

const variantsServiceText = text.variantsService;
const apiServiceText = text.apiService;
const currentEnv = (process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
export const postVariantToAPI = async (
  token: string,
  projectId: string,
  productId: string,
  variantPayload: PostVariantPayload,
): Promise<DynamicContentVariant> => {
  if (!token) {
    throw new AuthenticationError(apiServiceText.unauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });

  const path = `projects/${projectId}/products/${productId}/variants`;
  const { response } = apiService.abortablePost(path, variantPayload);

  try {
    return (await response).data;
  } catch (e) {
    logError(e);
    throw new VariantPostError(variantsServiceText.variantPostError, { projectId, productId, variantPayload });
  }
};

export const getVariantFromAPI = async (
  token: string,
  projectId: string,
  productId: string,
  variantId: string,
): Promise<DynamicContentVariant> => {
  if (!token) {
    throw new AuthenticationError(apiServiceText.unauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });

  const path = `projects/${projectId}/products/${productId}/variants/${variantId}`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data;
  } catch (e) {
    logError(e);
    throw new VariantFetchError(variantsServiceText.variantLoadError, { productId, projectId, variantId });
  }
};

export const getVariantOutputs = async (
  token: string,
  projectId: string,
  productId: string,
  variantId: string,
): Promise<DynamicContentVariantOutput[]> => {
  if (!token) {
    throw new AuthenticationError(apiServiceText.unauthorizedAccessError);
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });

  const path = `projects/${projectId}/products/${productId}/variants/${variantId}/outputs`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data;
  } catch (e) {
    logError(e);
    throw new VariantOutputFetchError(variantsServiceText.variantOutputsLoadError, { productId, projectId, variantId });
  }
};
