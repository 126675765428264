import { Box, ThemeProvider } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CookiesUtils } from 'mid-addin-lib';
import {
  ErrorBoundary,
  ErrorFallback,
  MIDEmptyState,
  MidWebAppMoniker,
  NotificationsProvider,
  UnderMaintenance,
  UserAnalyticsProvider,
  createBugsnagErrorBoundaryComponent,
  useNotificationStore,
} from 'mid-react-common';
import React, { ReactElement, useContext } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import './App.css';
import { AppContainer, EmptyStateWrapper, NavHorizontal } from './App.style';
import Gatekeeper from './components/GateKeeper/Gatekeeper';
import HeaderAppBar from './components/Header/';
import AccountProjectContext from './context/AccountProjectStore/AccountProject.context';
import { useAccountProjectsStore } from './context/AccountProjectStore/accountProjectStore';
import ModelSelectionContext from './context/ModelSelectionStore/ModelSelection.context';
import { useModelSelectionStore } from './context/ModelSelectionStore/modelSelectionStore';
import ProductContext from './context/ProductStore/Product.context';
import { useProductStore } from './context/ProductStore/productStore';
import UserContext from './context/UserStore/User.context';
import { useUserStore } from './context/UserStore/userStore';
import { useDefaultNavigation } from './global/hooks/hooks';
import text from './global/text.json';
import { routes } from './routes';
import { lightTheme } from './utils/theme';

const handleResetAppState = (): void => {
  // Reloads the page & takes you back to the root URL
  window.location.href = window.location.origin;
};

const BugsnagErrorBoundaryComponent = createBugsnagErrorBoundaryComponent({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY_ADDINS || '',
  moniker: MidWebAppMoniker,
  appVersion: process.env.REACT_APP_MIDW_VERSION || 'Not provided',
});

const AppWithNotifications: React.FC = () => {
  const notificationStore = useNotificationStore();
  const errorBoundaryText = text.errorBoundary;
  const { enableMaintenanceMode } = useFlags();

  if (enableMaintenanceMode) {
    return (
      <ThemeProvider theme={lightTheme}>
        <UnderMaintenance />
      </ThemeProvider>
    );
  }

  return BugsnagErrorBoundaryComponent ? (
    <BugsnagErrorBoundaryComponent
      FallbackComponent={({ error }) => <ErrorFallback error={error} clearError={handleResetAppState} />}
    >
      <NotificationsProvider store={notificationStore}>
        <App />
      </NotificationsProvider>
    </BugsnagErrorBoundaryComponent>
  ) : (
    <ErrorBoundary handleResetAppState={handleResetAppState} resetButtonText={errorBoundaryText.goBackToHomePage}>
      <NotificationsProvider store={notificationStore}>
        <App />
      </NotificationsProvider>
    </ErrorBoundary>
  );
};

const ContentOrEmptyState: React.FC<{ children: ReactElement }> = ({ children }) => {
  const { projectId } = useContext(AccountProjectContext);
  const isProjectIdPresent = useMatch(routes.projectId.path + '/*');

  const shouldShowEmptyState = !projectId && !isProjectIdPresent;

  if (shouldShowEmptyState) {
    return (
      <EmptyStateWrapper>
        <MIDEmptyState title={text.projectSelectorPanel.selectAccountAndProject} />
      </EmptyStateWrapper>
    );
  }

  return children;
};

const App: React.FC = () => {
  const userStore = useUserStore();
  const accountProjectsStore = useAccountProjectsStore();
  const productStore = useProductStore({
    token: userStore.token,
    projectId: accountProjectsStore.projectId,
  });
  const modelSelectionStore = useModelSelectionStore();

  useDefaultNavigation(routes.projectId.id, routes.products.path);

  return (
    <Gatekeeper initialize={userStore.initialize}>
      <UserContext.Provider value={userStore}>
        <UserAnalyticsProvider token={userStore.token} userId={CookiesUtils.getCookie('identity-sso')}>
          <AccountProjectContext.Provider value={accountProjectsStore}>
            <ThemeProvider theme={lightTheme}>
              <ProductContext.Provider value={productStore}>
                <ModelSelectionContext.Provider value={modelSelectionStore}>
                  <AppContainer>
                    <HeaderAppBar />
                    <ContentOrEmptyState>
                      <>
                        <Box>
                          <NavHorizontal to={routes.products.path} data-testid="products-nav">
                            {text.mainNavs.products}
                          </NavHorizontal>
                          <NavHorizontal to={routes.outputs.path} data-testid="outputs-nav">
                            {text.mainNavs.outputs}
                          </NavHorizontal>
                          <NavHorizontal to={routes.insights.path} data-testid="insights-nav">
                            {text.mainNavs.insights}
                          </NavHorizontal>
                        </Box>
                        <Outlet />
                      </>
                    </ContentOrEmptyState>
                  </AppContainer>
                </ModelSelectionContext.Provider>
              </ProductContext.Provider>
            </ThemeProvider>
          </AccountProjectContext.Provider>
        </UserAnalyticsProvider>
      </UserContext.Provider>
    </Gatekeeper>
  );
};

export default AppWithNotifications;
