import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export const DashboardContents = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  minHeight: '100%',
}));

export const DashboardPanelContainer = styled(Container)(() => ({
  display: 'flex',
  margin: '24px auto',
  flexDirection: 'column',
  width: '100%',
  gap: '20px',
}));

export const DashboardRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: '16px',
}));
