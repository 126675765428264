export enum ErrorCode {
  ApiError = 'apiError',
  AuthenticationError = 'authenticationError',
  EnvError = 'envError',
  ThumbnailError = 'thumbnailError',
  FileError = 'fileError',
  FileUrlError = 'fileUrlError',
  ApiUploadFileError = 'apiUploadFileError',
  SaveDraftError = 'saveDraftError',
  CreateProductDefinitionError = 'createProductDefinitionError',
  UpdateProductDefinitionError = 'updateProductDefinitionError',
  DeleteProductDefinitionError = 'deleteProductDefinitionError',
  DeleteDraftError = 'deleteDraftError',
  InvalidDraftOutputError = 'invalidDraftOutputError',
  CacheError = 'cacheError',
  GenerateOutputError = 'generateOutputError',
  ProductError = 'productError',
  ExtractZipFileError = 'extractZipFileError',
  EmptyTopLevelError = 'emptyTopLevelError',
  EditDraftError = 'editDraftError',
  InvalidURLError = 'invalidURLError',
  ServiceUnavailableError = 'serviceUnavailableError',
  AxiosResponseError = 'axiosResponseError',
  UnexpectedError = 'unexpectedError',
  UserInfoError = 'userInfoError',
  EmptyBOMError = 'emptyBOMError',
  ProductsFetchError = 'productsFetchError',
  VariantFetchError = 'variantFetchError',
  VariantPostError = 'variantPostError',
  VariantOutputsFetchError = 'variantOutputsFetchError',
  BIM360AccountsFetchError = 'bim360AccountsFetchError',
  BIM360ProjectsFetchError = 'bim360ProjectsFetchError',
  BIM360FoldersFetchError = 'bim360FoldersFetchError',
  BIM360FolderDocumentsFetchError = 'BIM360FolderDocumentsFetchError',
  BIM360FolderCreationError = 'BIM360FolderCreationError',
  BIM360ManifestFetchError = 'bim360ManifestFetchError',
  InsufficientArguments = 'insufficientArguments',
  VersionInfoLoadError = 'versionInfoLoadError',
}
