import { createTheme } from '@mui/material';
import { midTheme } from 'mid-react-common';
import appColors from 'mid-react-common/global/colors';

export const lightTheme = createTheme({
  ...midTheme,
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    primary: {
      main: appColors.primary.autodeskBlue600,
    },
    secondary: {
      main: appColors.secondary.red700,
    },
    background: {
      default: appColors.surface.lightGray.level10,
      paper: appColors.surface.lightGray.level30,
      sidebar: appColors.surface.lightGray.level25,
    },
    info: {
      main: appColors.primary.autodeskBlue600,
    },
    error: {
      main: appColors.theme.lightGray.error,
    },
    warning: {
      main: appColors.theme.lightGray.warning,
    },
    success: {
      main: appColors.theme.lightGray.success,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export const darkTheme = createTheme({
  ...midTheme,
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: appColors.primary.autodeskBlue400,
    },
    secondary: {
      main: appColors.secondary.red500,
    },
    info: {
      main: appColors.primary.autodeskBlue600,
    },
    error: {
      main: appColors.theme.dark.error,
    },
    warning: {
      main: appColors.theme.dark.warning,
    },
    success: {
      main: appColors.theme.dark.success,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
