import { ENVIRONMENT } from 'mid-types';

const PRD_FORGE_API_URL = 'https://developer.api.autodesk.com';
const NON_PRD_FORGE_API_URL = 'https://developer-stg.api.autodesk.com';
const PRD_OFFSITE_API_URL = 'https://api.dc.autodesk.com/v1';
const STG_OFFSITE_API_URL = 'https://api.stg.dc.autodesk.com/v1';
const DEV_OFFSITE_API_URL = 'https://api.dev.dc.autodesk.com/v1';
const PRD_MID_ARTIFACTS = 'https://artifacts.mid.autodesk.com';
const STG_MID_ARTIFACTS = 'https://artifacts.stg.mid.autodesk.com';
const DEV_MID_ARTIFACTS = 'https://artifacts.dev.mid.autodesk.com';
const NON_PRD_PROFILE_SETTING_URL = 'https://accounts-staging.autodesk.com';
const PRD_PROFILE_SETTING_URL = 'https://accounts.autodesk.com';
const PRD_DOCUMENTS_MANAGER_URL = 'https://docs.b360.autodesk.com/projects';
const NON_PRD_DOCUMENTS_MANAGER_URL = 'https://docs.b360-staging.autodesk.com/projects';
const NON_PRD_DERIVATIVE_CDN_URL = 'https://cdn-stg.derivative.autodesk.com';
const PRD_DERIVATIVE_CDN_URL = 'https://cdn.derivative.autodesk.com';
export enum ServiceTypes {
  FORGE_API = 'FORGE_API',
  OFFSITE_API = 'OFFSITE_SERVICES_API',
  MID_ARTIFACTS = 'MID_ARTIFACTS',
  PROFILE_SETTING_API = 'PROFILE_SETTING_API',
  DOCUMENTS_MANAGER_API = 'DOCUMENTS_MANAGER_API',
  DERIVATIVE_CDN = 'DERIVATIVE_CDN',
}

export const ServiceConfigMap = {
  [ServiceTypes.FORGE_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_FORGE_API_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_FORGE_API_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_FORGE_API_URL,
    },
  },
  [ServiceTypes.DERIVATIVE_CDN]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_DERIVATIVE_CDN_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_DERIVATIVE_CDN_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_DERIVATIVE_CDN_URL,
    },
  },
  [ServiceTypes.OFFSITE_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_OFFSITE_API_URL,
    },
    [ENVIRONMENT.STG]: {
      api: STG_OFFSITE_API_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: DEV_OFFSITE_API_URL,
    },
  },
  [ServiceTypes.MID_ARTIFACTS]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_MID_ARTIFACTS,
    },
    [ENVIRONMENT.STG]: {
      api: STG_MID_ARTIFACTS,
    },
    [ENVIRONMENT.DEV]: {
      api: DEV_MID_ARTIFACTS,
    },
  },
  [ServiceTypes.PROFILE_SETTING_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_PROFILE_SETTING_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_PROFILE_SETTING_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_PROFILE_SETTING_URL,
    },
  },
  [ServiceTypes.DOCUMENTS_MANAGER_API]: {
    [ENVIRONMENT.PRD]: {
      api: PRD_DOCUMENTS_MANAGER_URL,
    },
    [ENVIRONMENT.STG]: {
      api: NON_PRD_DOCUMENTS_MANAGER_URL,
    },
    [ENVIRONMENT.DEV]: {
      api: NON_PRD_DOCUMENTS_MANAGER_URL,
    },
  },
};
