import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const CustomizationWrapper = styled('div')`
  height: ${({ theme }) => `${theme.var.largeButtonHeight}px`};
  display: flex;
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const ParameterCustomizationWrapper = styled('div')(() => ({
  display: 'flex',
}));

export const TextFieldWrapper = styled(TextField)`
  width: ${({ theme }) => `${theme.var.wideInputControl}px`};
`;

export const CustomizationFormWrapper = styled('form')`
  max-height: calc(
    100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.largeButtonHeight}px`} -
      ${({ theme }) => `${theme.var.footerHeight}px`}
  );
  overflow: auto;
  min-width: ${({ theme }) => `${theme.var.wideInputControl}px`};
`;

export const FormLoadingSpinner = styled(CircularProgress)`
  align-self: center;
`;

export const FormButtonsContainer = styled('div')``;
