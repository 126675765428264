import { ServiceConfigMap } from 'mid-addin-lib';
import { ENVIRONMENT, Environment } from 'mid-types';
import { logError, UserInfoError } from 'mid-utils';
import { useCallback, useState } from 'react';
import { ExtendedUserInfo } from '../../types/user';
import text from '../../global/text.json';
import authService from 'mid-addin-lib/services/auth';

export interface UserStore {
  token: string;
  userInfoLoading: boolean;
  userInfo?: ExtendedUserInfo;
  setProfile: () => Promise<void>;
  handleLogout: () => void;
  handleProfileSettingClick: () => void;
  initialize: () => Promise<void>;
}

export const useUserStore = (): UserStore => {
  const [token, setToken] = useState('');
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<ExtendedUserInfo | undefined>(undefined);
  const userStoreText = text.userStore;

  const authenticate = async (): Promise<void> => {
    try {
      const token = await authService.authenticate();

      setToken(token);
    } catch (e) {
      logError(e);
      throw new Error(userStoreText.failedToAuthenticate);
    }
  };

  const initialize = async (): Promise<void> => {
    try {
      await authenticate();
    } catch (e) {
      logError(e);

      throw new Error(userStoreText.failedToInitialize);
    }
  };

  const setProfile = useCallback(async (): Promise<void> => {
    try {
      setUserInfoLoading(true);
      const res = await authService.getUserInfo();
      setUserInfo({
        ...res,
        name: `${res.firstName} ${res.lastName}`,
        profileImg50: res.profileImages.sizeX50,
      });
      setUserInfoLoading(false);
    } catch (e) {
      throw new UserInfoError(userStoreText.failedToSetProfile, { error: e });
    }
  }, [userStoreText.failedToSetProfile]);

  const handleLogout = (): void => {
    authService.logout();
  };

  const handleProfileSettingClick = (): void => {
    const currentEnv = (process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
    window.location.href = ServiceConfigMap.PROFILE_SETTING_API[currentEnv].api;
  };

  return {
    token,
    userInfoLoading,
    userInfo,
    setProfile,
    handleLogout,
    handleProfileSettingClick,
    initialize,
  };
};
