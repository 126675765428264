import { DialogContent, DialogContentText } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContentWrapper = styled(DialogContent)`
  margin-inline: ${({ theme }) => theme.var.marginBase * 2}px;
`;

export const DialogContentTextWrapper = styled(DialogContentText)`
  margin-block: ${({ theme }) => theme.var.marginBase}px;
`;
