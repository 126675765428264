import { ComingSoon } from '../../Commons/ComingSoon/ComingSoon';
import DashboardCell from '../DashboardCell';
import { HorizontalBarChart } from '../ExampleCharts/HorizontalBarChart';
import { HorizontalBarChart2 } from '../ExampleCharts/HorizontalBarChart2';
import { LineChart } from '../ExampleCharts/LineChart';
import { VerticalBarChart } from '../ExampleCharts/VerticalBarChart';
import SingleValueMetricPanel from '../SingleValueMetricPanel';
import { DashboardContents, DashboardPanelContainer, DashboardRow } from './DashboardPanel.styles';

export const DashboardPanel = (): JSX.Element => (
  <DashboardContents>
    <DashboardPanelContainer>
      <DashboardRow>
        <ComingSoon>MID Insights is a powerful tool for data analysis and decision making support.</ComingSoon>
      </DashboardRow>
      <DashboardRow>
        <DashboardCell>
          <SingleValueMetricPanel title="Number of products" value="37" />
        </DashboardCell>
        <DashboardCell>
          <SingleValueMetricPanel title="Number of Variants" value="543" />
        </DashboardCell>
        <DashboardCell>
          <SingleValueMetricPanel title="Number of Instances" value="3,423" />
        </DashboardCell>
        <DashboardCell>
          <SingleValueMetricPanel title="Instances per week" value="65.8" />
        </DashboardCell>
      </DashboardRow>
      <DashboardRow>
        <DashboardCell width="50%">
          <VerticalBarChart />
        </DashboardCell>
        <DashboardCell width="50%">
          <LineChart />
        </DashboardCell>
      </DashboardRow>
      <DashboardRow>
        <DashboardCell width="50%">
          <HorizontalBarChart />
        </DashboardCell>
        <DashboardCell width="50%">
          <HorizontalBarChart2 />
        </DashboardCell>
      </DashboardRow>
    </DashboardPanelContainer>
  </DashboardContents>
);
