import ModelDetails from '../ModelDetails';
import ModelsFolderBrowser from '../ModelsFolderBrowser';
import ModelsFolderContent from '../ModelsFolderContent';
import useModelsPanel from './useModelsPanel';

export const ModelsPanel: React.FC = () => {
  const {
    folderUrn,
    lmvModelFile,
    selectedModelFolder,
    isPreviewLoading,
    setIsPreviewLoading,
    setSelectedModelFolder,
    selectFolder,
    handleFolderClick,
    removeDetailsSegmentFromRoute,
  } = useModelsPanel();

  return (
    <>
      <ModelsFolderBrowser handleFolderClick={handleFolderClick} selectFolder={selectFolder} folderUrn={folderUrn} />
      <ModelsFolderContent
        folderUrn={folderUrn}
        lmvModelFiles={lmvModelFile}
        selectedModelFolder={selectedModelFolder}
        handleSameRowClick={removeDetailsSegmentFromRoute}
        setIsPreviewLoading={setIsPreviewLoading}
        setSelectedModelFolder={setSelectedModelFolder}
      />
      {(selectedModelFolder || isPreviewLoading) && (
        <ModelDetails
          handleModelDetailsClose={removeDetailsSegmentFromRoute}
          isPreviewLoading={isPreviewLoading}
          folderUrn={folderUrn}
          selectedModelFolder={selectedModelFolder}
          setSelectedModelFolder={setSelectedModelFolder}
        />
      )}
    </>
  );
};
