import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import text from '../../../global/text.json';
import noProjectImageUrl from '../../../images/3d-settings-icon-light-slate.png';
import { AccountDetailsContainer, AccountImage, AccountInfo, AccountTitleContainer } from './AccountDetails.style';
import { useAccountDetails } from './useAccountDetails';

interface AccountDetailsProps {
  projectsQty: number | undefined;
  accountImageURL: string | undefined;
  accountName: string | undefined;
}

export const AccountDetails: React.FC<AccountDetailsProps> = ({ projectsQty, accountName, accountImageURL }) => {
  const { handleChangeAccount } = useAccountDetails();
  const accountText = text.accountDetails;

  return (
    <AccountDetailsContainer>
      <AccountImage src={accountImageURL ? accountImageURL : noProjectImageUrl} alt="" />
      <AccountTitleContainer>
        <Typography variant="body2" noWrap component="div">
          {accountName}
        </Typography>
        <AccountInfo>
          <Typography variant="body2" noWrap component="div">
            {projectsQty} {accountText.projects}
          </Typography>
          <Button onClick={handleChangeAccount}>{accountText.changeAccount}</Button>
        </AccountInfo>
      </AccountTitleContainer>
    </AccountDetailsContainer>
  );
};
