import { styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';

export const StyledTreeView = styled(TreeView)`
  margin-top: 14px;
  padding-left: ${({ theme }) => theme.var.paddingBase}px;
  border: 1px solid ${({ theme }) => theme.colors.primary.charcoal300};
  height: ${({ theme }) => theme.var.midTreeHeight}px;
  flex-grow: 1;
  min-width: ${({ theme }) => theme.var.midTreeMinWidth}px;
  overflow-y: auto;
  & .MuiTreeItem-content {
    // eliminating the horizontal scrollbar caused by some weird default MUI padding for the tree item
    width: auto;
  }
`;
