import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Paper } from '@mui/material';

export const ReviewPanelContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
}));

export const ReviewPanelHeader = styled(Paper)(() => ({
  padding: '8px 16px',
}));

export const ReviewPanelContent = styled(Box)(() => ({
  flexGrow: '1',
}));

export const DownloadButton = styled(Button)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
}));
