import { CircularProgress } from '@mui/material';
import React, { ReactNode } from 'react';
import { useGateKeeper } from './useGateKeeper';
export interface GatekeeperProps {
  children: ReactNode;
  initialize: () => Promise<void>;
}

const Gatekeeper: React.FC<GatekeeperProps> = ({ children, initialize }) => {
  const { initializing } = useGateKeeper({ initialize });
  return initializing ? <CircularProgress /> : <>{children}</>;
};

export default Gatekeeper;
