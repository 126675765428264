import { useContext } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import ProductContext from '../../../context/ProductStore/Product.context';

interface UseAccountDetailsState {
  handleChangeAccount: () => void;
}

export const useAccountDetails = (): UseAccountDetailsState => {
  const { clearAccountData, clearProjectData } = useContext(AccountProjectContext);
  const { resetModelSelectionStoreState } = useContext(ModelSelectionContext);
  const { resetProductStoreState } = useContext(ProductContext);

  const handleChangeAccount = () => {
    clearAccountData();
    clearProjectData({ updateLocalStorage: true });
    resetModelSelectionStoreState();
    resetProductStoreState();
  };

  return {
    handleChangeAccount,
  };
};
