let stubFn;

// prevent error when mock data (stores) use stubs for their empty functions (since jest.fn()
// is not available under Cypress runtime)
if (typeof Cypress !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/explicit-module-boundary-types
  stubFn = () => () => {};
} else if (typeof jest !== 'undefined') {
  stubFn = jest.fn;
} else {
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/explicit-module-boundary-types
  stubFn = () => () => {};
}

export default {
  stubFn,
};
