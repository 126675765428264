import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

export const StyledPaper = styled(Paper)`
  padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  ${({ theme }) => theme.typography.bodyMedium};
`;

export const StyledPopper = styled(Popper)`
  zindex: 100;
`;
