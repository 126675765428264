import { DynamicContentProduct, PostProductPayload, TemplateInputType } from 'mid-types';
import { mockFolder1, mockFolder1a, mockFolder1b, mockFolder2, mockFolder2a } from './mockFolders';

export const rulesObjectKey = 'mock-object-key-for-rules';
export const ruleObjectKeyWError = 'mock-rules-key-with-error';
export const mockProductWithRules: DynamicContentProduct = {
  contentId: '22222222-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '22222222cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      increment: 2,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 34,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      readOnly: true,
      type: TemplateInputType.IProperty,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      unit: 'in',
      values: [1, 2, 3, 4, 5, 6],
      value: 4,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: TemplateInputType.MultiValueText,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text3',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: TemplateInputType.MultiValueText,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
    },
  ],
  name: '1 - AD - Balcony Assembly - Inventor 2022',
  outputs: [
    {
      options: {
        modelStates: ['Master'],
      },
      type: 'BOM',
    },
    {
      options: {
        category: 'Balcony 1',
        family: 'Balcony 1',
        modelStates: ['BIM', 'Master'],
      },
      type: 'RFA',
    },
  ],
  rules: {
    currentRule: {
      code: `if ((parameters['Width'].value) == 44) {    parameters['HasDoor'].value = false;}`,
    },
  },
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
};

export const mockProducts: DynamicContentProduct[] = [
  {
    contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
        location: 'BIMDOCS',
      },
    },
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: false,
        type: TemplateInputType.Boolean,
        value: true,
        visible: true,
      },
      {
        label: 'Has Door - Controlling Input',
        name: 'HasDoorControllingInput',
        readOnly: false,
        type: TemplateInputType.Boolean,
        value: true,
        visible: true,
        onChange: [
          {
            dependsOn: {
              bindings: {
                dependentValue: 'Width.visible',
                dependsOn: 'HasDoorControllingInput.value',
              },
            },
          },
        ],
      },
      {
        label: 'Has Door - Read Only',
        name: 'HasDoorReadOnly',
        readOnly: true,
        type: TemplateInputType.Boolean,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: false,
        type: TemplateInputType.Numeric,
        unit: 'in',
        value: 38,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width - Read Only',
        max: 48,
        min: 32,
        name: 'WidthReadOnly',
        readOnly: true,
        type: TemplateInputType.Numeric,
        unit: 'in',
        value: 38,
        visible: true,
      },
      {
        label: 'Mock IProperty',
        name: 'MockIProperty',
        readOnly: true,
        type: TemplateInputType.IProperty,
        visible: true,
        category: 'mock iProperty category',
        value: 'mock iProperty value',
      },
      {
        label: 'Mock MultiValue Numeric',
        name: 'MockMultiValueNumeric',
        readOnly: false,
        type: TemplateInputType.MultiValueNumeric,
        visible: true,
        unit: 'in',
        values: [1, 2, 3],
        value: 2,
        allowCustomValue: true,
        onChange: [],
      },
      {
        label: 'Mock MultiValue Numeric - Read Only',
        name: 'MockMultiValueNumericReadOnly',
        readOnly: true,
        type: TemplateInputType.MultiValueNumeric,
        visible: true,
        unit: 'in',
        values: [1, 2, 3],
        value: 2,
        allowCustomValue: true,
        onChange: [],
      },
      {
        label: 'Mock MultiValue Text',
        name: 'MockMultiValueText',
        readOnly: false,
        type: TemplateInputType.MultiValueText,
        visible: true,
        unit: 'in',
        values: ['text1', 'text2', 'text3'],
        value: 'text2',
      },
      {
        label: 'Mock MultiValue Text - Read Only',
        name: 'MockMultiValueTextReadOnly',
        readOnly: true,
        type: TemplateInputType.MultiValueText,
        visible: true,
        unit: 'in',
        values: ['text1', 'text2', 'text3'],
        value: 'text2',
      },
    ],
    name: '0 - AD - Balcony Assembly - Inventor 2022',
    outputs: [
      {
        options: {
          modelStates: ['Primary'],
        },
        type: 'BOM',
      },
      {
        options: {
          category: 'Balcony 0',
          family: 'Balcony  0',
          modelStates: ['BIM', 'Master'],
        },
        type: 'RFA',
      },
    ],
    rulesKey: rulesObjectKey,
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
  },
  mockProductWithRules,
  {
    contentId: '33333333-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '33333333cc9c44a4ad79b509e1c3225f',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
        location: 'BIMDOCS',
      },
    },
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: true,
        type: TemplateInputType.Boolean,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: true,
        type: TemplateInputType.Numeric,
        unit: 'in',
        value: 38,
        visible: true,
      },
    ],
    name: '2 - AD - Balcony Assembly - Inventor 2022',
    outputs: [
      {
        options: {
          modelStates: ['Primary'],
        },
        type: 'BOM',
      },
      {
        options: {
          category: 'Balcony 2',
          family: 'Balcony 2',
          modelStates: ['BIM', 'Master'],
        },
        type: 'RFA',
      },
    ],
    rulesKey: rulesObjectKey,
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
  },
  {
    contentId: '44444444-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '44444444cc9c44a4ad79b509e1c3225f',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
        location: 'BIMDOCS',
      },
    },
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: true,
        type: TemplateInputType.Boolean,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: true,
        type: TemplateInputType.Numeric,
        unit: 'in',
        value: 38,
        visible: true,
      },
    ],
    name: '3 - AD - Balcony Assembly - Inventor 2022',
    outputs: [
      {
        options: {
          modelStates: ['Master'],
        },
        type: 'BOM',
      },
      {
        options: {
          category: 'Balcony 3',
          family: 'Balcony 3',
          modelStates: ['BIM', 'Master'],
        },
        type: 'RFA',
      },
    ],
    rules: {},
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
  },
  {
    contentId: '55555555-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
        location: 'BIMDOCS',
      },
    },
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: true,
        type: TemplateInputType.Boolean,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: true,
        type: TemplateInputType.Numeric,
        unit: 'in',
        value: 38,
        visible: true,
      },
    ],
    name: '4 - AD - Balcony Assembly - Inventor 2022',
    outputs: [
      {
        options: {
          category: 'Balcony 4',
          family: 'Balcony 4',
          modelStates: ['BIM', 'Master'],
        },
        type: 'RFA',
      },
      {
        options: {
          modelStates: ['Master'],
        },
        type: 'BOM',
      },
    ],
    rules: {},
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
  },
  {
    contentId: '777777777-cc9c-44a4-ad79-b509e1c3225f',
    thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
    context: {
      topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
      workspace: {
        folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
        location: 'BIMDOCS',
      },
    },
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    inputs: [
      {
        label: 'Has Door',
        name: 'HasDoor',
        readOnly: true,
        type: TemplateInputType.Boolean,
        value: true,
        visible: true,
      },
      {
        increment: 6,
        label: 'Door Width',
        max: 48,
        min: 32,
        name: 'Width',
        readOnly: true,
        type: TemplateInputType.Numeric,
        unit: 'in',
        value: 38,
        visible: true,
      },
    ],
    name: '5 - AD - Balcony Assembly - Inventor 2022 - W/Error',
    outputs: [
      {
        options: {
          category: 'Balcony 5',
          family: 'Balcony 5',
          modelStates: ['BIM', 'Master'],
        },
        type: 'RFA',
      },
    ],
    rulesKey: ruleObjectKeyWError,
    codeBlocksWorkspace: '{"test": "mock-code-block"}',
    schemaVersion: 1,
    tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
  },
  {
    dataSetLocation:
      'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
    name: 'MID Shelving Unit',
    thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
    rules: {
      currentRule: {
        ruleLabel: 'currentRule',
        code: "parameters['Height'].values = [30, 42, 54, 66];parameters['Material'].values = ['Laminated Veneer Lumber', 'Particleboard', 'Ash', 'Birch', 'Mahogany', 'Maple', 'Oak, Red', 'Pine', 'Plywood, Finish', 'Spruce', 'Walnut'];parameters['Shelves_Qty'].values = [1, 2, 3, 4];if ((parameters['Height'].value) == 30) {\n  parameters['Shelves_Qty'].values = [1];} else if ((parameters['Height'].value) == 42) {\n  parameters['Shelves_Qty'].values = [1, 2];} else if ((parameters['Height'].value) == 54) {\n  parameters['Shelves_Qty'].values = [1, 2, 3];} else if ((parameters['Height'].value) == 66) {\n  parameters['Shelves_Qty'].values = [1, 2, 3, 4];parameters['Material'].values = ['Oak, Red', 'Maple', 'Mahogany', 'Birch'];}\n",
      },
    },
    schemaVersion: 1,
    codeBlocksWorkspaceKey: 'mock-key',
    tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
    contentId: 'c3e2ea91-6f4d-4462-9436-f8a183c8e510',
    inputs: [
      {
        name: 'Height',
        type: TemplateInputType.MultiValueNumeric,
        visible: true,
        readOnly: false,
        label: 'Height',
        unit: 'in',
        value: 54,
        values: [30, 42, 54, 66],
        allowCustomValue: true,
        onChange: [],
      },
      {
        name: 'Material',
        type: TemplateInputType.MultiValueText,
        visible: true,
        readOnly: false,
        label: 'Material',
        unit: 'Text',
        value: '"Oak, Red"',
        values: [
          '"Laminated Veneer Lumber"',
          '"Particleboard"',
          '"Ash"',
          '"Birch"',
          '"Mahogany"',
          '"Maple"',
          '"Oak, Red"',
          '"Pine"',
          '"Plywood, Finish"',
          '"Spruce"',
          '"Walnut"',
        ],
      },
      {
        name: 'Shelves_Qty',
        type: TemplateInputType.MultiValueNumeric,
        visible: true,
        readOnly: false,
        label: 'Shelves_Qty',
        unit: 'ul',
        value: 3,
        values: [1, 2, 3],
        allowCustomValue: true,
        onChange: [],
      },
    ],
    outputs: [
      {
        type: 'RFA',
        options: {
          modelStates: ['[Primary]'],
          category: 'Furniture',
          family: 'MID Shelving Unit',
        },
      },
      {
        type: 'BOM',
        options: {
          modelStates: ['[Primary]'],
        },
      },
    ],
    context: {
      projectFile: '\\MID Shelving Unit.ipj',
      topLevelAssembly: 'MID Shelving Unit\\MID Shelving Unit.iam',
      engine: {
        location: 'DA4I',
        version: '2023',
      },
      workspace: {
        location: 'BIMDOCS',
        folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
      },
    },
  },
];

export const midShelfCacheKey = 'a0qjm9906t5mvd7febbgvpo6teo6v9io4u3bnkbknk64huti8lobqfmfc4bhj0f9q3q69k4m650ld';

export const mockIncompatibleProduct: DynamicContentProduct = {
  contentId: '66666666-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '66666666cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2024',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: true,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: true,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 38,
      visible: true,
    },
  ],
  name: '4 - AD - Balcony Assembly - Inventor 2024',
  outputs: [
    {
      options: {
        category: 'Balcony 4',
        family: 'Balcony 4',
        modelStates: ['BIM', 'Master'],
      },
      type: 'RFA',
    },
    {
      options: {
        modelStates: ['Master'],
      },
      type: 'BOM',
    },
  ],
  rules: {},
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  schemaVersion: 1,
  tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
};

export const mockPostProductPayload: PostProductPayload = {
  schemaVersion: 1,
  name: 'AD - Balcony Assembly - Inventor 2022',
  thumbnail: 'mock-thumbnail-object-key',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: TemplateInputType.Boolean,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: TemplateInputType.Numeric,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
    },
  ],
  outputs: [
    {
      type: 'RFA',
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
  rulesKey: rulesObjectKey,
  codeBlocksWorkspaceKey: 'mock-object-key-for-codeBlock',
};

export const mockPostProductResponse: DynamicContentProduct = {
  schemaVersion: 1,
  name: 'AD - Balcony Assembly - Inventor 2022',
  thumbnail: 'mock-thumbnail-for-post-product-response',
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  context: {
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    workspace: {
      folderPath:
        'urn:adsk.wipstg:fs.folder:co.xc_QXPE1QpSRF2GTS9YUCA/urn:adsk.wipstg:fs.folder:co.hR1-lUPySo2lTolJISRKLQ/urn:adsk.wipstg:fs.folder:co.G3oFEmrUTJu0xynUxevCSA',
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      name: 'HasDoor',
      type: TemplateInputType.Boolean,
      label: 'Has Door',
      visible: true,
      readOnly: false,
      value: true,
    },
    {
      name: 'Width',
      type: TemplateInputType.Numeric,
      label: 'Door Width',
      unit: 'in',
      visible: true,
      readOnly: false,
      value: 38,
      min: 32,
      max: 48,
      increment: 6,
    },
  ],
  outputs: [
    {
      type: 'RFA',
      options: {
        modelStates: ['Master', 'BIM'],
        family: 'Balcony',
        category: 'Balcony',
      },
    },
  ],
  rules: {},
  codeBlocksWorkspace: '{"test": "mock-code-block"}',
  tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
};

export const mockProductWithRuleKey: DynamicContentProduct = {
  contentId: '55555555-cc9c-44a4-ad79-b509e1c3225f',
  thumbnail: '55555555cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder2.urn}/${mockFolder2a.urn}`,
      location: 'BIMDOCS',
    },
  },
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: true,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: true,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 38,
      visible: true,
    },
  ],
  name: '4 - AD - Balcony Assembly - Inventor 2022',
  outputs: [
    {
      options: {
        category: 'Balcony 4',
        family: 'Balcony 4',
        modelStates: ['BIM', 'Master'],
      },
      type: 'RFA',
    },
    {
      options: {
        modelStates: ['Master'],
      },
      type: 'BOM',
    },
  ],
  rulesKey: 'mock-rules-key',
  codeBlocksWorkspaceKey: 'mock-rules-key',
  schemaVersion: 1,
  tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
};

export const mockProductsMap = new Map<string, DynamicContentProduct>(mockProducts.map((p) => [p.contentId!, p]));

export const mockProjectIdWithProducts = '50353b25-2037-4b6f-b4ef-72d70fe706eb';
export const mockProjectIdWithError = '50353b25-2037-4b6f-b4ef-error';
