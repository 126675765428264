import { ForgeDMProjectFolder, ProjectFolder } from 'mid-types';

/**
 * Converts a ForgeDMProjectFolder to a ProjectFolder
 * @param data the data to transform
 * @returns a ProjectFolder
 */
const toProjectFolder = (data: ForgeDMProjectFolder): ProjectFolder => {
  const { urn, title, hidden, deleted, path, ...restOfData } = data;

  return {
    urn,
    title,
    path,
    hidden,
    projectId: restOfData.project_id,
    parentUrn: restOfData.parent_urn,
    hasSubfolders: restOfData.has_subfolders,
    deleted,
    folderType: restOfData.folder_type,
    isRoot: restOfData.is_root,
    viewOption: restOfData.view_option,
    permissionType: restOfData.permission_type,
    isSystemFolder: restOfData.is_system_folder,
  } as ProjectFolder;
};

export const mockFolder1b: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: 'prefix.mock-folder1a-urn',
  title: 'Plans 1b',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder1b-urn',
  deleted: false,
  folder_type: 'plan',
  is_root: false,
  path: 'mock-folder1-urn/mock-folder1b-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockFolder1a: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: 'prefix.mock-folder1-urn',
  title: 'Plans 1a',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder1a-urn',
  deleted: false,
  folder_type: 'plan',
  is_root: false,
  path: 'mock-folder1-urn/mock-folder1a-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: true,
  folders: [mockFolder1b],
};

export const mockFolder1: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: '',
  title: 'Plans 1',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder1-urn',
  deleted: false,
  folder_type: 'plan',
  is_root: true,
  path: 'mock-folder1-path',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: true,
  folders: [mockFolder1a, mockFolder1b],
};

export const mockFolder2a: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: 'prefix.mock-folder2-urn',
  title: 'Plans 2a',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder2a-urn',
  deleted: false,
  folder_type: 'plan',
  is_root: false,
  path: 'mock-folder2-urn/mock-folder2a-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockFolder1c: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: mockFolder1a.urn,
  title: 'Plans 1c',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'mock-folder1c-urn',
  deleted: false,
  folder_type: 'plan',
  is_root: false,
  path: 'mock-folder1c-path',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockFolder2: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: '',
  title: 'Plans 2',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'prefix.mock-folder2-urn',
  deleted: false,
  folder_type: 'plan',
  is_root: true,
  path: 'mock-folder2-urn',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: true,
  folders: [mockFolder2a],
};

export const mockNewFolder: ForgeDMProjectFolder = {
  permission_type: 'control',
  parent_urn: 'mock-folder1-urn',
  title: 'Mock New Folder',
  doc_type: 'project.folder',
  project_id: 'mock-project-id',
  urn: 'mock-new-folder-urn',
  deleted: false,
  folder_type: 'normal',
  is_root: false,
  path: 'mock-folder1-path',
  hidden: false,
  view_option: 'LMV',
  permission_actions: ['publish', 'view', 'download', 'collaborate', 'edit', 'control'],
  is_system_folder: true,
  has_subfolders: false,
  folders: [],
};

export const mockProjectFolder1: ProjectFolder = toProjectFolder(mockFolder1);
export const mockProjectFolder1a: ProjectFolder = toProjectFolder(mockFolder1a);
export const mockProjectFolder1b: ProjectFolder = toProjectFolder(mockFolder1b);
export const mockProjectFolder1c: ProjectFolder = toProjectFolder(mockFolder1c);
export const mockProjectFolder2: ProjectFolder = toProjectFolder(mockFolder2);
export const mockProjectFolder2a: ProjectFolder = toProjectFolder(mockFolder2a);
export const mockProjectFolders: ProjectFolder[] = [mockProjectFolder1, mockProjectFolder2];

export const mockFolderTree1: ForgeDMProjectFolder = {
  ...mockFolder1,
  folders: [
    mockFolder1a,
    {
      ...mockFolder1b,
      folders: [mockFolder2, mockFolder2a],
    },
  ],
};
