import Bugsnag, { OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import { ENVIRONMENT, Environment } from 'mid-types';
import { midLogger } from 'mid-utils';
import React from 'react';

export type ReleaseStages = typeof releaseStagesMap[keyof typeof releaseStagesMap];
export const releaseStagesMap = {
  [ENVIRONMENT.DEV]: 'development',
  [ENVIRONMENT.STG]: 'staging',
  [ENVIRONMENT.PRD]: 'production',
} as const;

interface createBugsnagErrorBoundaryComponentArgs {
  apiKey: string;
  moniker: string;
  appVersion: string;
  analyticsId?: string;
}

export const createBugsnagErrorBoundaryComponent = ({
  apiKey,
  moniker,
  appVersion,
  analyticsId,
}: createBugsnagErrorBoundaryComponentArgs): BugsnagErrorBoundary | undefined => {
  const onErrorCaughtByBoundary: OnErrorCallback = (event) => {
    event.addMetadata('Moniker', { moniker });
    if (analyticsId) {
      event.setUser(analyticsId);
    }
  };

  const releaseStage: ReleaseStages =
    releaseStagesMap[(process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV];

  Bugsnag.start({
    apiKey,
    plugins: [new BugsnagPluginReact(React)],
    onError: onErrorCaughtByBoundary,
    appVersion,
    releaseStage,
    enabledReleaseStages: [releaseStagesMap.stg, releaseStagesMap.prd],
    logger: midLogger,
  });

  const BugsnagErrorBoundaryComponent = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

  return BugsnagErrorBoundaryComponent;
};
