import { ServiceConfigMap, ServiceTypes } from 'mid-addin-lib/services/serviceAPIConfig';

export const metadataJSON = [
  {
    name: 'Manufacturing Informed Design Add-In 0.0.0.1 for Inventor 2023.msi',
    timestamp: '2022-09-27T23:58:03+00:00',
    addinType: 'inventor',
    version: '0.0.0.1',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 0.0.0.1 for Revit 2023.msi',
    timestamp: '2022-09-27T23:59:39+00:00',
    addinType: 'revit',
    version: '0.0.0.1',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.10.0.0 for Inventor 2023.msi',
    timestamp: '2022-11-08T16:29:31+00:00',
    addinType: 'inventor',
    version: '1.10.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.11.0.0 for Inventor 2023.msi',
    timestamp: '2022-11-09T21:13:18+00:00',
    addinType: 'inventor',
    version: '1.11.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.12.0.0 for Inventor 2023.msi',
    timestamp: '2022-11-11T22:03:52+00:00',
    addinType: 'inventor',
    version: '1.12.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.13.0.0 for Inventor 2023.msi',
    timestamp: '2022-11-16T17:30:44+00:00',
    addinType: 'inventor',
    version: '1.13.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.14.0.0 for Inventor 2023.msi',
    timestamp: '2022-11-21T19:20:55+00:00',
    addinType: 'inventor',
    version: '1.14.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.15.0.0 for Inventor 2023.msi',
    timestamp: '2022-11-23T14:24:32+00:00',
    addinType: 'inventor',
    version: '1.15.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.16.0.0 for Inventor 2023.msi',
    timestamp: '2022-11-23T19:42:59+00:00',
    addinType: 'inventor',
    version: '1.16.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.17.0.0 for Inventor 2023.msi',
    timestamp: '2022-12-02T22:57:38+00:00',
    addinType: 'inventor',
    version: '1.17.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.17.1.0 for Inventor 2023.msi',
    timestamp: '2022-12-06T16:46:42+00:00',
    addinType: 'inventor',
    version: '1.17.1.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.18.0.0 for Inventor 2023.msi',
    timestamp: '2022-12-09T15:59:18+00:00',
    addinType: 'inventor',
    version: '1.18.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.18.1.0 for Inventor 2023.msi',
    timestamp: '2022-12-13T19:10:09+00:00',
    addinType: 'inventor',
    version: '1.18.1.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.18.2.0 for Inventor 2023.msi',
    timestamp: '2022-12-16T21:22:43+00:00',
    addinType: 'inventor',
    version: '1.18.2.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.18.3.0 for Inventor 2023.msi',
    timestamp: '2023-01-13T15:44:08+00:00',
    addinType: 'inventor',
    version: '1.18.3.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.19.0.0 for Inventor 2023.msi',
    timestamp: '2023-01-17T15:46:16+00:00',
    addinType: 'inventor',
    version: '1.19.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.19.1.0 for Inventor 2023.msi',
    timestamp: '2023-01-26T15:39:30+00:00',
    addinType: 'inventor',
    version: '1.19.1.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 2.0.0.0 for Inventor 2024.msi',
    timestamp: '2024-01-26T15:39:30+00:00',
    addinType: 'inventor',
    version: '2.0.0.0',
    hostApplicationVersion: '2024',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.8.0.0 for Revit 2023.msi',
    timestamp: '2022-12-06T16:48:16+00:00',
    addinType: 'revit',
    version: '1.8.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.8.1.0 for Revit 2023.msi',
    timestamp: '2022-10-13T19:44:43+00:00',
    addinType: 'revit',
    version: '1.8.1.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.9.0.0 for Inventor 2023.msi',
    timestamp: '2022-10-20T19:10:22+00:00',
    addinType: 'inventor',
    version: '1.9.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.9.0.0 for Revit 2023.msi',
    timestamp: '2022-12-16T21:24:14+00:00',
    addinType: 'revit',
    version: '1.9.0.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.9.1.0 for Inventor 2023.msi',
    timestamp: '2022-11-07T19:31:20+00:00',
    addinType: 'inventor',
    version: '1.9.1.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.9.1.0 for Revit 2023.msi',
    timestamp: '2023-01-17T15:48:12+00:00',
    addinType: 'revit',
    version: '1.9.1.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 1.9.2.0 for Revit 2023.msi',
    timestamp: '2023-01-26T15:42:04+00:00',
    addinType: 'revit',
    version: '1.9.2.0',
    hostApplicationVersion: '2023',
  },
  {
    name: 'Manufacturing Informed Design Add-In 2.0.0.0 for Revit 2024.msi',
    timestamp: '2024-01-26T15:39:30+00:00',
    addinType: 'revit',
    version: '2.0.0.0',
    hostApplicationVersion: '2024',
  },
];

const artifactsUrl = ServiceConfigMap[ServiceTypes.MID_ARTIFACTS].stg.api;

export const latestInventorMSIVersion = {
  inventorVersion: metadataJSON[16].version,
  inventorFilename: metadataJSON[16].name,
  inventorFileURL: artifactsUrl + '/' + metadataJSON[16].name,
};

export const latestRevitMSIVersion = {
  revitVersion: metadataJSON[24].version,
  revitFilename: metadataJSON[24].name,
  revitFileURL: artifactsUrl + '/' + metadataJSON[24].name,
};
