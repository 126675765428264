import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LDProviderConfigWithAnonymousAnalytics } from 'mid-react-common';
import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { router } from './routes';

(async () => {
  const LDProvider = await asyncWithLDProvider(LDProviderConfigWithAnonymousAnalytics);
  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <RouterProvider router={router} />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
