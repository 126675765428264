import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type CustomAccordionContentProps = {
  expanded: boolean;
};

export const CustomAccordionContainer = styled(Box)(() => ({}));

export const CustomAccordionHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const CustomAccordionTitle = styled(Typography)(() => ({}));

export const CustomAccordionContent = styled(Box, {
  shouldForwardProp: (props) => props !== 'expanded',
})<CustomAccordionContentProps>(({ expanded }) => ({
  maxHeight: !expanded ? '0px' : '100%',
  padding: '0px 24px 0px 0px',
  transition: 'all .2s ease-in-out',
  overflow: 'hidden',
}));
