import { NotificationContext } from 'mid-react-common';
import { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DEEP_LINK_LOCAL_STORAGE_KEYS } from '../../global/constants/deepLink';

interface UseGateKeeperProps {
  initialize: () => Promise<void>;
}

interface UseGateKeeperState {
  initializing: boolean;
}

export const useGateKeeper = ({ initialize }: UseGateKeeperProps): UseGateKeeperState => {
  const [initializing, setInitializing] = useState(true);
  const { logAndShowNotification } = useContext(NotificationContext);
  const { projectId: projectIdURLParam } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!projectIdURLParam) {
      return;
    }

    // We preserve the entry URL, so it can be
    // navigated to after the Forge Auth redirect
    window.localStorage.setItem(
      DEEP_LINK_LOCAL_STORAGE_KEYS.WEBAPP_ENTRY_POINT,
      `${window.location.pathname}${window.location.search}`,
    );
  }, [projectIdURLParam]);

  useEffect(() => {
    if (!initializing) {
      return;
    }

    initialize()
      .then(() => {
        const appEntryURL = window.localStorage.getItem(DEEP_LINK_LOCAL_STORAGE_KEYS.WEBAPP_ENTRY_POINT);
        if (appEntryURL) {
          navigate(appEntryURL);
        }
      })
      .catch((error) => {
        logAndShowNotification({ error });
      })
      .finally(() => {
        // we remove the entry point after it is used
        // as the app does not manage or sanity check
        // this local storage key
        window.localStorage.removeItem(DEEP_LINK_LOCAL_STORAGE_KEYS.WEBAPP_ENTRY_POINT);
        setInitializing(false);
      });
  }, [initialize, initializing, logAndShowNotification, navigate, projectIdURLParam]);

  return { initializing };
};
