import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { adskColors } from '../InsightsPage.styles';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Particip. by Category (%)',
    },
  },
};

const labels = ["Q1'21", "Q2'21", "Q3'21", "Q4'21", "Q1'22", "Q1'22"];

export const data = {
  labels,
  datasets: [
    {
      label: 'Steel Stairs',
      data: [0, 0, 10, 15, 35, 47],
      borderColor: adskColors[0],
      backgroundColor: adskColors[0],
      height: '100px',
    },
    {
      label: 'Wooden Stairs',
      data: [64, 65, 53, 45, 31, 29],
      borderColor: adskColors[1],
      backgroundColor: adskColors[1],
      height: '100px',
    },
    {
      label: 'Balconies',
      data: [5, 7, 10, 9, 15, 17],
      borderColor: adskColors[2],
      backgroundColor: adskColors[2],
      height: '100px',
    },
    {
      label: 'Railings',
      data: [31, 28, 27, 31, 18, 7],
      borderColor: adskColors[3],
      backgroundColor: adskColors[3],
      height: '100px',
    },
  ],
};

export const LineChart = (): JSX.Element => <Line options={options} data={data} />;
