import { format } from 'date-fns';
import { NotificationContext, NOTIFICATION_STATUSES } from 'mid-react-common';
import { logError } from 'mid-utils';
import { useContext, useEffect, useState } from 'react';
import { useAsyncPollingWithArgs } from '../../../global/hooks/hooks';
import text from '../../../global/text.json';
import { getVariantFromAPI } from '../../../services/variants';
import { Instance } from '../../../types/product';
import {
  OutputsReviewMap,
  OutputsReviewTableDataModel,
  OutputStatusMetadata,
  useVariantStatusPollingParams,
  useVariantStatusPollingReturn,
} from './Types';
import { DynamicContentVariant, VariantOutputStatus, VariantOutputType } from 'mid-types';

const isVariantOutputPollingRequired = (status: VariantOutputStatus | undefined) =>
  !(
    status === undefined ||
    [VariantOutputStatus.SUCCESS, VariantOutputStatus.CANCELLED, VariantOutputStatus.FAILED].includes(status)
  );

const shouldVariantOutputsPollingContinue = (variantsOutputs: OutputsReviewTableDataModel[]): boolean =>
  variantsOutputs.some((variantOutputs) => isVariantOutputPollingRequired(variantOutputs.status));

export function useVariantStatusPolling({
  instances,
  token,
  projectId,
}: useVariantStatusPollingParams): useVariantStatusPollingReturn {
  const [variantOutputsLoading, setVariantOutputsLoading] = useState(true);
  const { showNotification } = useContext(NotificationContext);

  const reviewPanelText = text.reviewPanel;

  const { data: polledAllVariantsOutputs, startPolling: startPollingVariantStatus } = useAsyncPollingWithArgs<
    OutputsReviewTableDataModel[]
  >(async (prevCallbackResult: OutputStatusMetadata[]): Promise<OutputsReviewTableDataModel[]> => {
    if (projectId && instances) {
      const transformedInstances: OutputsReviewMap = instances.reduce(
        (prev: OutputsReviewMap, current: Instance): OutputsReviewMap => {
          if (!prev[current.variantId]) {
            prev[current.variantId] = {
              id: current.variantId,
              productId: current.contentId,
              status: undefined,
              name: current.variantName,
              productName: current.productName,
              instanceCount: 1,
              modifiedAt: '',
            };
          } else {
            prev[current.variantId] = {
              ...prev[current.variantId],
              instanceCount: prev[current.variantId].instanceCount + 1,
            };
          }
          return prev;
        },
        {},
      );
      // Retrieve output status for each variant
      const variantsOutputStatusPromise: Promise<OutputStatusMetadata>[] = Object.keys(transformedInstances).map(
        async (variantId, idx): Promise<OutputStatusMetadata> => {
          // prevent superfluous calls for the already loaded items in a successful state by caching the prev result
          if (
            prevCallbackResult &&
            prevCallbackResult[idx] &&
            !isVariantOutputPollingRequired(prevCallbackResult[idx].status)
          ) {
            return {
              status: prevCallbackResult[idx].status,
              modifiedAt: prevCallbackResult[idx].modifiedAt,
              objectKey: prevCallbackResult[idx].objectKey,
            };
          }

          const productId = transformedInstances[variantId].productId;
          try {
            // Fetch output status
            const variant: DynamicContentVariant = await getVariantFromAPI(token, projectId, productId, variantId);
            const variantBOMOutput = variant.outputs.find((output) => output.type === VariantOutputType.BOM);

            return {
              status: variantBOMOutput?.status,
              modifiedAt: format(new Date(variant.updatedAt), 'MMM d, yyyy hh:mm a'),
              objectKey: variantBOMOutput?.urn,
            };
          } catch (err) {
            logError(reviewPanelText.failToGetVariant, {
              err,
              projectId,
              productId,
              variantId,
            });
            showNotification({
              message: reviewPanelText.failToGetVariant,
              severity: NOTIFICATION_STATUSES.ERROR,
            });
            return {
              status: undefined,
              modifiedAt: '',
              objectKey: undefined,
            };
          }
        },
      );
      const allVariantsOutputStatus = await Promise.all(variantsOutputStatusPromise);
      const mergedTableData = Object.values(transformedInstances).map((tableData, index) => ({
        ...tableData,
        ...allVariantsOutputStatus[index],
      }));
      return mergedTableData;
    }
    return [];
  }, shouldVariantOutputsPollingContinue);

  useEffect(() => {
    // it makes no sense to start polling if no instances available (this is the case when user refreshes the
    // screen with the openModel URL Parameter and the logic to retrieve MID elements is not started yet)
    if (!instances) {
      return;
    }

    if (polledAllVariantsOutputs === null) {
      setVariantOutputsLoading(true);
      startPollingVariantStatus();
    } else {
      setVariantOutputsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polledAllVariantsOutputs, instances]);

  return { variantOutputsLoading, polledAllVariantsOutputs };
}
