import HigCaretLeftImage from '../../assets/images/hig-icons/caret-left-24.png';
import HigCaretRightImage from '../../assets/images/hig-icons/caret-right-24.png';
import HigEditProfileImage from '../../assets/images/hig-icons/edit-profile-24.svg';
import HigFileImage from '../../assets/images/hig-icons/file-generic-24.png';
import HigFolderImage from '../../assets/images/hig-icons/folder-24.png';
import HigFolderOpenImage from '../../assets/images/hig-icons/folder-open-24.png';
import HigSearchImage from '../../assets/images/hig-icons/search-24.png';

import { CustomIcon } from './CustomIcon.styles';

interface HigIconsProps {
  width?: string;
}

const generateHigIcon =
  (icon: string) =>
  ({ width = '24' }: HigIconsProps): JSX.Element =>
    <CustomIcon src={icon} width={width} height={width} />;

export const HigCaretLeft = generateHigIcon(HigCaretLeftImage);
export const HigCaretRight = generateHigIcon(HigCaretRightImage);
export const HigEditProfile = generateHigIcon(HigEditProfileImage);
export const HigFile = generateHigIcon(HigFileImage);
export const HigFolder = generateHigIcon(HigFolderImage);
export const HigFolderOpen = generateHigIcon(HigFolderOpenImage);
export const HigSearch = generateHigIcon(HigSearchImage);
