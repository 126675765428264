import { styled, css } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import { ThemableCSSProp } from 'mid-react-common';
import { CustomIcon } from 'mid-react-common';
import AdskIcon from './images/autodesk-collections-icon-dark-slate.png';

const NavVerticalCSS = ({ theme }: ThemableCSSProp) => css`
  color: ${theme.colors.primary.charcoal700};
  text-align: center;
  background-color: ${theme.palette.background.sidebar};
  padding-bottom: ${theme.var.paddingBase}px;
  width: calc(100% - ${theme.var.navBorderWidth}px);
  box-sizing: content-box;
`;

const NavCommonCSS = ({ theme }: ThemableCSSProp) => css`
  :link {
    text-decoration: none;
  }

  :visited {
    text-decoration: none;
  }

  &.active {
    text-decoration: none;
    color: ${theme.colors.primary.autodeskBlue600};
  }
`;

export const AppContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: ${({ theme }) => theme.screenSizes.laptop};
  overflow: hidden;
`;

export const NavHorizontal = styled(NavLink)`
  ${NavCommonCSS}

  padding: ${({ theme }) => `${theme.var.paddingBase}px 17px`};
  color: ${({ theme }) => theme.colors.primary.charcoal700};
  display: inline-block;

  &.active {
    border-bottom: ${({ theme }) => theme.var.navBorderWidth}px solid ${({ theme }) => theme.colors.primary.autodeskBlue700};
  }
`;

export const NavVertical = styled(NavLink)`
  ${NavVerticalCSS}
  ${NavCommonCSS}

  &.active {
    border-right: ${({ theme }) => theme.var.navBorderWidth}px solid ${({ theme }) => theme.colors.primary.autodeskBlue700};
  }
`;

export const DisabledNavVertical = styled('div')`
  ${NavVerticalCSS}

  opacity: 0.7;
  cursor: not-allowed;
`;

export const SidebarBox = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 100px;
`;

export const EmptyStateWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
`;

export const NavIcon = styled(CustomIcon)`
  width: ${({ theme }) => theme.var.navIconSize}px;
  height: ${({ theme }) => theme.var.navIconSize}px;
  display: block;
  margin: 0 auto;
`;

NavIcon.defaultProps = {
  src: AdskIcon,
};
