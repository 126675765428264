export const BIM360CurrentVersionProcessState = {
  PROCESSING_COMPLETE: 'PROCESSING_COMPLETE',
  EXTRACTION_PENDING: 'EXTRACTION_PENDING',
};

export const BIM360CurrentVersionProcessResult = {
  PROCESSING_SUCCESS: 'PROCESSING_SUCCESS',
};

export const BIM360LocalStorageKeys = {
  SELECTED_PROJECT_ID: 'selected-project-id',
};
