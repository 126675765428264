import { ENVIRONMENT, Environment } from 'mid-types';
import { ViewerOptions } from '../types/viewerService';

export const ViewerEnv = {
  PRD_VIEWER_ENV: 'AutodeskProduction',
  NON_PRD_VIEWER_ENV: 'AutodeskStaging',
};

export const viewerConfig: ViewerOptions = {
  isURN: true,
  env:
    (process.env.REACT_APP_ENVIRONMENT as Environment) === ENVIRONMENT.PRD
      ? ViewerEnv.PRD_VIEWER_ENV
      : ViewerEnv.NON_PRD_VIEWER_ENV,
  api: undefined,
  useCredentials: true,
  useCookie: false,
  shouldInitializeAuth: true,
  documentId: undefined,
  loadLargestView: true,

  config3d: {
    extensions: [],
    loadAsHidden: false, // Need to be visible to initialize camera et al.
    isAEC: true, // Use light blue background instead of flat grey
    globalOffset: { x: 0, y: 0, z: 0 }, // Make camera operations more sane by not having to offset everything
  },
};
