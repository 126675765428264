import { IForgeConfiguration } from '@adsk/forge-appfw-forge-configuration';
import { ENVIRONMENT, Environment } from 'mid-types';
import { ServiceConfigMap, ServiceTypes } from './serviceAPIConfig';

export const forgeConfig: IForgeConfiguration = {
  environmentBaseUrl:
    ServiceConfigMap[ServiceTypes.FORGE_API][(process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV].api,
};

export const authenticationConfig = {
  clientId: process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID || 'CONFIGURE_IN_ENV',
  authenticationEndpoint: `${forgeConfig.environmentBaseUrl}/authentication/v1/authorize`,
  callbackUrl: (): string => window.location.origin + '/',
  scope: 'data:read data:write data:create data:search bucket:create bucket:read bucket:update bucket:delete account:read',
};
