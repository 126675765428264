import React, { useContext, useState } from 'react';
import {
  OutputTypesSettings,
  SecondLayerFormControlLabel,
  SettingPanelFormGroup,
  SettingsPanelBackground,
  SettingsPanelContainer,
  SettingsPanelContents,
  ThirdLayerFormControlLabel,
  UploadSettings,
} from './SettingsPanel.styles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import text from '../../../global/text.json';
import ProductContext from '../../../context/ProductStore/Product.context';
import { DynamicContentProduct } from 'mid-types';

const settingPanelText = text.settingPanel;

export const SettingsPanel: React.FC = () => {
  const { products } = useContext(ProductContext);

  const productsList: DynamicContentProduct[] = [...products.values()];

  const initialSelectedProduct = productsList.length > 0 ? productsList[0].contentId : '';

  const [selectedProduct, setSelectedProduct] = useState(initialSelectedProduct);

  const handleProductChange = (event: SelectChangeEvent) => {
    setSelectedProduct(event.target.value);
  };

  return (
    <SettingsPanelBackground>
      <SettingsPanelContainer>
        <Typography variant="h4">{settingPanelText.outputSetting}</Typography>
        <SettingsPanelContents>
          <OutputTypesSettings elevation={2}>
            <Typography variant="h6">{settingPanelText.generate}</Typography>

            <SettingPanelFormGroup>
              <FormControl>
                <InputLabel id="product-select-label">{settingPanelText.product}</InputLabel>
                <Select aria-labelledby="product-select-label" value={selectedProduct} onChange={handleProductChange}>
                  {productsList.map((product) => (
                    <MenuItem key={product.contentId} value={product.contentId}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel control={<Checkbox checked disabled />} label={settingPanelText.allAvailable} />
              <SecondLayerFormControlLabel control={<Checkbox checked disabled />} label={settingPanelText.revitFamily} />
              <SecondLayerFormControlLabel control={<Checkbox disabled />} label={settingPanelText.inventorDrawing} />
              <SecondLayerFormControlLabel control={<Checkbox disabled />} label={settingPanelText.shopDrawing} />
              <SecondLayerFormControlLabel
                control={<Checkbox disabled checked />}
                label={settingPanelText.billOfMaterials}
              />
              <ThirdLayerFormControlLabel control={<Checkbox disabled checked />} label={settingPanelText.variant} />
              <ThirdLayerFormControlLabel control={<Checkbox disabled />} label={settingPanelText.instance} />
              <ThirdLayerFormControlLabel control={<Checkbox disabled />} label={settingPanelText.aggregate} />
              <SecondLayerFormControlLabel control={<Checkbox disabled />} label={settingPanelText.neutralFormat} />
            </SettingPanelFormGroup>
          </OutputTypesSettings>
          <UploadSettings elevation={2}>
            <Typography variant="h6">{settingPanelText.upload}</Typography>
          </UploadSettings>
        </SettingsPanelContents>
      </SettingsPanelContainer>
    </SettingsPanelBackground>
  );
};
