import {
  DynamicContentVariant,
  DynamicContentVariantLogResponse,
  PostVariantPayload,
  TemplateInputType,
  VariantOutputStatus,
  VariantOutputType,
} from 'mid-types';
import { mockFolder1, mockFolder1a, mockFolder1b } from './mockFolders';

export const mockGetVariantResponse: DynamicContentVariant = {
  contentId: '611a71f9-3d89-4faf-a3ea-918d84fa65c8',
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw1',
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: '',
  schemaVersion: 1,
  tenancyId: 'mock-tenancy-id',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      name: 'width',
      label: 'width',
      value: 5,
      type: TemplateInputType.Numeric,
      unit: 'm',
      readOnly: false,
      visible: true,
    },
    {
      name: 'depth',
      label: 'depth',
      value: 6,
      type: TemplateInputType.Numeric,
      unit: 'm',
      readOnly: false,
      visible: true,
    },
  ],
  outputs: [
    {
      type: VariantOutputType.RFA,
      modelState: 'Master',
      status: VariantOutputStatus.PENDING,
    },
    {
      type: VariantOutputType.THUMBNAIL,
      status: VariantOutputStatus.PENDING,
    },
  ],
  rules: {},
  createdAt: '',
  updatedAt: '',
};

export const mockPostVariantPayload: PostVariantPayload = {
  inputs: [
    {
      name: 'width',
      value: '5',
    },
    {
      name: 'depth',
      value: '6',
    },
  ],
  outputs: [
    {
      modelState: 'Master',
      type: 'RFA',
    },
  ],
};

export const mockPostVariantResponse: DynamicContentVariant = {
  contentId: '611a71f9-3d89-4faf-a3ea-918d84fa65c8',
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw1',
  tenancyId: 'mock-tenancy-id',
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: '',
  schemaVersion: 1,
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      name: 'width',
      label: 'width',
      value: 5,
      type: TemplateInputType.Numeric,
      unit: 'm',
      readOnly: false,
      visible: true,
    },
    {
      name: 'depth',
      label: 'depth',
      value: 6,
      type: TemplateInputType.Numeric,
      unit: 'm',
      readOnly: false,
      visible: true,
    },
  ],
  outputs: [
    {
      type: 'RFA',
      modelState: 'Master',
      status: VariantOutputStatus.PENDING,
      urn: 'MDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwL0lucHV0cy90ZXN0LnppcC9hOGNjMjMxNi00OWE1LTRhZTEtOWY4Ni0yNWNhMmJmNWE5YmQ',
    },
  ],
  rules: {},
  createdAt: '',
  updatedAt: '',
};

export const mockTransformedVariantPayload: PostVariantPayload = {
  inputs: [
    { name: 'HasDoor', value: true },
    { name: 'HasDoorControllingInput', value: true },
    { name: 'HasDoorReadOnly', value: true },
    { name: 'Width', value: 38 },
    { name: 'WidthReadOnly', value: 38 },
    { name: 'MockIProperty', value: 'mock iProperty value' },
    { name: 'MockMultiValueNumeric', value: 2 },
    { name: 'MockMultiValueNumericReadOnly', value: 2 },
    { name: 'MockMultiValueText', value: 'text2' },
    { name: 'MockMultiValueTextReadOnly', value: 'text2' },
  ],
  outputs: [
    { type: 'RFA', modelState: 'BIM' },
    { type: 'RFA', modelState: 'Master' },
  ],
};

export const mockGetVariantLogsResponse: DynamicContentVariantLogResponse = {
  urn: 'ODkwYjVjYzUtNmZiNS00NzhhLWEwM2YtNjIxNDEzY2FmZTgyL0xvZ3MvbG9nLXJlcG9ydC50eHQvYjYyNzM4MzAtMWY1Mi00YjM0LWJjYTctMDhmNzUwMGU5OWJl',
  createdAt: '2023-01-23T22:48:19.454Z',
};

export const mockVariant: DynamicContentVariant = {
  contentId: '11111111-cc9c-44a4-ad79-b509e1c3225f',
  variantId: 'NjExYTcxZjktM2Q4OS00ZmFmLWEzZWEtOTE4ZDg0ZmE2NWM4fDF8ZGVwdGh8Nnx3aWR0aHw1',
  name: 'MyBalcony',
  dataSetLocation: 'mock-dataset',
  thumbnail: 'thumbnail-for-mock-variant-response',
  schemaVersion: 1,
  tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly\\AD - Balcony Assembly.iam',
    projectFile: 'relative/path/project',
    workspace: {
      folderPath: 'urn:adsk.wipstg:fs.folder:co.TGG2NBg7SHGfgyN6HCrOKQ/urn:adsk.wipstg:dm.lineage:k5TbLZ8bQfeVCx4HSpnDbg',
      location: 'BIMDOCS',
    },
    engine: {
      location: 'DA4I',
      version: '2022',
    },
  },
  inputs: [
    {
      name: 'width',
      label: 'width',
      value: 5,
      type: TemplateInputType.Numeric,
      unit: 'm',
      readOnly: false,
      visible: true,
    },
    {
      name: 'depth',
      label: 'depth',
      value: 6,
      type: TemplateInputType.Numeric,
      unit: 'm',
      readOnly: false,
      visible: true,
    },
  ],
  outputs: [
    {
      type: VariantOutputType.RFA,
      modelState: 'Master',
      status: VariantOutputStatus.SUCCESS,
      urn: 'MDAwMDAwMDAtMDAwMC0wMDAwLTAwMDAtMDAwMDAwMDAwMDAwL0lucHV0cy90ZXN0LnppcC9hOGNjMjMxNi00OWE1LTRhZTEtOWY4Ni0yNWNhMmJmNWE5YmQ',
    },
    {
      type: VariantOutputType.THUMBNAIL,
      status: VariantOutputStatus.SUCCESS,
      urn: 'thumbnail-for-mock-variant-response',
    },
    {
      type: VariantOutputType.BOM,
      status: VariantOutputStatus.SUCCESS,
      urn: 'mock urn for bom',
    },
  ],
  rules: {},
  createdAt: '2022-06-27T16:34:41.773',
  updatedAt: '2022-06-28T16:34:41.773',
};

export const mockTransformedInPostVariantPayload: PostVariantPayload = {
  inputs: [
    {
      name: 'width',
      value: 5,
    },
    {
      name: 'depth',
      value: 6,
    },
  ],
  outputs: [
    {
      type: VariantOutputType.BOM,
      modelState: 'Primary',
    },
  ],
};

export const mockVariant2: DynamicContentVariant = {
  contentId: '22222222-cc9c-44a4-ad79-b509e1c3225f',
  variantId: 'mock variant id',
  thumbnail: '11111111cc9c44a4ad79b509e1c3225f',
  context: {
    topLevelAssembly: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.iam',
    engine: {
      location: 'DA4I',
      version: '2023',
    },
    projectFile: 'AD - Balcony Assembly - Inventor 2022\\AD - Balcony Assembly.ipj',
    workspace: {
      folderPath: `${mockFolder1.urn}/${mockFolder1a.urn}/${mockFolder1b.urn}`,
      location: 'BIMDOCS',
    },
  },
  inputs: [
    {
      label: 'Has Door',
      name: 'HasDoor',
      readOnly: false,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      label: 'Has Door - Controlling Input',
      name: 'HasDoorControllingInput',
      readOnly: false,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
      onChange: [
        {
          dependsOn: {
            bindings: {
              dependentValue: 'Width.visible',
              dependsOn: 'HasDoorControllingInput.value',
            },
          },
        },
      ],
    },
    {
      label: 'Has Door - Read Only',
      name: 'HasDoorReadOnly',
      readOnly: true,
      type: TemplateInputType.Boolean,
      value: true,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width',
      max: 48,
      min: 32,
      name: 'Width',
      readOnly: false,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      increment: 6,
      label: 'Door Width - Read Only',
      max: 48,
      min: 32,
      name: 'WidthReadOnly',
      readOnly: true,
      type: TemplateInputType.Numeric,
      unit: 'in',
      value: 38,
      visible: true,
    },
    {
      label: 'Mock IProperty',
      name: 'MockIProperty',
      readOnly: true,
      type: TemplateInputType.IProperty,
      visible: true,
      category: 'mock iProperty category',
      value: 'mock iProperty value',
    },
    {
      label: 'Mock MultiValue Numeric',
      name: 'MockMultiValueNumeric',
      readOnly: false,
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Numeric - Read Only',
      name: 'MockMultiValueNumericReadOnly',
      readOnly: true,
      type: TemplateInputType.MultiValueNumeric,
      visible: true,
      unit: 'in',
      values: [1, 2, 3],
      value: 2,
      allowCustomValue: true,
      onChange: [],
    },
    {
      label: 'Mock MultiValue Text',
      name: 'MockMultiValueText',
      readOnly: false,
      type: TemplateInputType.MultiValueText,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
    },
    {
      label: 'Mock MultiValue Text - Read Only',
      name: 'MockMultiValueTextReadOnly',
      readOnly: true,
      type: TemplateInputType.MultiValueText,
      visible: true,
      unit: 'in',
      values: ['text1', 'text2', 'text3'],
      value: 'text2',
    },
  ],
  outputs: [
    { modelState: 'Primary', type: VariantOutputType.BOM, status: VariantOutputStatus.PENDING },
    {
      category: 'Balcony 0',
      family: 'Balcony  0',
      modelState: 'Master',
      type: VariantOutputType.RFA,
      status: VariantOutputStatus.PENDING,
    },
  ],
  dataSetLocation:
    'https://developer-stg.api.autodesk.com/oss/v2/signedresources/c1979f64-702f-49a1-a96c-ffdc8bb444e9?region=US',
  name: '0 - AD - Balcony Assembly - Inventor 2022',
  rules: {},
  schemaVersion: 1,
  tenancyId: '50353b25-2037-4b6f-b4ef-72d70fe706eb',
  createdAt: '2022-06-27T16:34:41.773',
  updatedAt: '2022-06-28T16:34:41.773',
};

export const mockVariant3 = {
  ...mockVariant,
  contentId: '33333333-cc9c-44a4-ad79-b509e1c3225f',
};

export const mockBatchFetchVariantQuery = [
  {
    productId: mockVariant.contentId,
    projectId: mockVariant.tenancyId,
  },
  {
    productId: mockVariant2.contentId,
    projectId: mockVariant2.tenancyId,
  },
  {
    productId: mockVariant3.contentId,
    projectId: mockVariant3.tenancyId,
  },
];
export const mockBatchFetchVariantQueryWithError = [
  {
    productId: 'product id with error',
    projectId: 'project id with error',
  },
];

export const mockVariantsList = [mockVariant, mockVariant2, mockVariant3];
