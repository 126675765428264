import React, { ReactNode } from 'react';
import MIDNotification from '../../components/Notification/Notification';
import { NotificationStore } from './notificationStore';

const NotificationContext = React.createContext({} as NotificationStore);

interface NotificationsProviderProps {
  children: ReactNode;
  store: NotificationStore;
}
export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children, store }) => (
  <NotificationContext.Provider value={store}>
    {children}
    <MIDNotification />
  </NotificationContext.Provider>
);

export default NotificationContext;
