import { NotificationContext, NOTIFICATION_STATUSES, StateSetter } from 'mid-react-common';
import { logError } from 'mid-utils';
import { useContext, useEffect, useState } from 'react';
import { Parameter, SowElement } from 'types/scopeOfWork';
import ModelSelectionContext from '../../../../context/ModelSelectionStore/ModelSelection.context';
import ProductContext from '../../../../context/ProductStore/Product.context';
import {
  MIDModelContentIdParameterName,
  MIDModelParameterPrefix,
  MIDModelProjectIdParameterName,
  MIDModelVariantIdParameterName,
} from '../../../../global/constants/products';
import text from '../../../../global/text.json';
import viewerService from '../../../../services/viewer/viewerService';
import { Instance } from '../../../../types/product';

const instancePanelText = text.instancePanel;

interface UseMIDElementsFromViewerState {
  instancesLoading: boolean;
}

export const getAllMIDElements = async (
  lmvModelFileId = '',
  setInstances: StateSetter<Instance[] | undefined>,
): Promise<void> => {
  const elements = await viewerService.getAllMIDElements();
  const sowElements = await viewerService.transformRevitElementsToSowElements(elements, lmvModelFileId);
  const transformedElements = sowElements.map((sowElement: SowElement): Instance => {
    const midParameters: Parameter[] = sowElement.parameters.filter((parameter) =>
      parameter.name.includes(MIDModelParameterPrefix),
    );
    const midParametersMap = midParameters.reduce((acc: { [key: string]: string }, current: Parameter) => {
      if (!acc[current.name]) {
        acc[current.name] = current.value;
      }
      return acc;
    }, {});
    const contentIdParameterValue = midParametersMap[MIDModelContentIdParameterName];
    const variantIdParameterValue = midParametersMap[MIDModelVariantIdParameterName];
    const projectIdParameterValue = midParametersMap[MIDModelProjectIdParameterName];
    // Get digits in square brackets before eol: https://regex101.com/r/9ZPGLA/1
    const elementIDregex = /^.*\[(\d*)\]$/gm;
    const elementID = elementIDregex.exec(sowElement.name);

    return {
      id: sowElement.dbId.toString(),
      elementId: elementID ? elementID[1] : '',
      variantName: sowElement.family,
      variantId: variantIdParameterValue,
      contentId: contentIdParameterValue,
      category: sowElement.category,
      productName: sowElement.name,
      familyType: sowElement.familyType,
      projectId: projectIdParameterValue,
    };
  });
  setInstances(transformedElements);
};

const useMIDElementsFromViewer = (): UseMIDElementsFromViewerState => {
  const { setInstances } = useContext(ProductContext);
  const { currentlyOpenModel } = useContext(ModelSelectionContext);
  const { showNotification } = useContext(NotificationContext);

  const [instancesLoading, setInstancesLoading] = useState<boolean>(true);

  // Loading instances from LMV
  useEffect(() => {
    const handler = async () => {
      try {
        setInstancesLoading(true);
        await getAllMIDElements(currentlyOpenModel?.lmvModelFileId, setInstances);
      } catch (err) {
        logError(instancePanelText.failedToFetchMIDElements, { err });
        showNotification({
          severity: NOTIFICATION_STATUSES.ERROR,
          message: instancePanelText.failedToFetchMIDElements,
        });
      } finally {
        setInstancesLoading(false);
      }
    };

    viewerService.viewer.waitForLoadDone().then(handler);
  }, [currentlyOpenModel, setInstances, setInstancesLoading, showNotification]);

  return {
    instancesLoading,
  };
};

export default useMIDElementsFromViewer;
