import { VariantOutputStatus } from 'mid-types';

const DEFAULT_MOCK_POLLING_COUNT = 2;
export function* getMockVariantStatusIteratorForPolling(recurringPendingStatusCount: number): any {
  let count = 0;
  while (true) {
    if (count < recurringPendingStatusCount) {
      count++;
      yield VariantOutputStatus.PENDING;
    } else {
      count = 0;
      yield VariantOutputStatus.SUCCESS;
    }
  }
}
export const mockVariantStatusIterator = getMockVariantStatusIteratorForPolling(DEFAULT_MOCK_POLLING_COUNT);
