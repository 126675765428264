import { Typography } from '@mui/material';
import { useContext, useState, useCallback, useEffect } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import UserContext from '../../../context/UserStore/User.context';
import { getAccounts, getProjects } from '../../../services/bim360';
import { AccountAndProjectLoading } from './ProjectSelectorPanel.style';
import text from '../../../global/text.json';
import { CustomError, UnexpectedError, BIM360LocalStorageKeys } from 'mid-utils';
import { useParams } from 'react-router-dom';
import { NotificationContext, StateSetter } from 'mid-react-common';
import { AccAccount, AccProject } from 'mid-types';

interface UseProjectSelectorPanel {
  accountId: string | null;
  accountDisplayName: string | undefined;
  accountImageURL: string | undefined;
  accounts: AccAccount[] | undefined;
  projectId: string | null;
  projects: AccProject[] | undefined;
  projectsByAccount: AccProject[] | undefined;
  setProjectsByAccount: StateSetter<AccProject[] | undefined>;
  renderAccountProjectName: () => JSX.Element;
  setAnchorElProject: StateSetter<HTMLElement | null>;
  anchorElProject: HTMLElement | null;
  handleOpenProjectMenu: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  handleCloseProjectMenu: () => void;
  handleSearchProjects: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
const projectSelectorText = text.projectSelectorPanel;

export const useProjectSelectorPanel = (): UseProjectSelectorPanel => {
  const { token } = useContext(UserContext);
  const {
    accountId,
    projectId,
    projectName,
    accountDisplayName,
    accountImageURL,
    accountIdInCurrentProject,
    setCurrentProject,
    setCurrentAccount,
    clearAccountData,
    clearProjectData,
  } = useContext(AccountProjectContext);
  const [anchorElProject, setAnchorElProject] = useState<null | HTMLElement>(null);
  const [accounts, setAccounts] = useState<AccAccount[] | undefined>(undefined);
  const [accountsLoading, setAccountsLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<AccProject[] | undefined>(undefined);
  const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
  const [projectsByAccount, setProjectsByAccount] = useState<AccProject[] | undefined>();
  const { projectId: projectIdURLParam } = useParams();
  const { logAndShowNotification } = useContext(NotificationContext);

  const fetchAccounts = useCallback(async () => {
    try {
      setAccountsLoading(true);
      const accounts = await getAccounts(token);
      setAccounts(accounts);
    } catch (error) {
      logAndShowNotification({ error });
    } finally {
      setAccountsLoading(false);
    }
  }, [logAndShowNotification, token]);

  const fetchAllProjects = useCallback(async () => {
    try {
      setProjectsLoading(true);
      const projects = await getProjects(token);
      setProjects(projects);
    } catch (error) {
      logAndShowNotification({ error });
    } finally {
      setProjectsLoading(false);
    }
  }, [logAndShowNotification, token]);

  const filterProjectsForCurrentAccount = useCallback(() => {
    const projectsFilteredByAccount = projects?.filter((project) => project.account_id === accountId);
    setProjectsByAccount(projectsFilteredByAccount);
  }, [accountId, projects]);

  const findAccountBelongingToProject = useCallback(
    (accounts: AccAccount[], accountIdInCurrentProject: string): AccAccount => {
      // We use the account_id inside the project details to retrieve
      // & set the account info We do not store the account info,
      // as this can be retrieved & we don't have to do additional
      // validation on a persisted accountId
      const accountBelongingToProject = accounts.find((account) => account.account_id === accountIdInCurrentProject);
      if (!accountBelongingToProject) {
        throw new UnexpectedError(projectSelectorText.couldNotFindAccountBelongingToProject, {
          context: { accounts, accountIdInCurrentProject, projectId },
        });
      }

      return accountBelongingToProject;
    },
    [projectId],
  );

  useEffect(() => {
    if (token) {
      fetchAccounts();
      fetchAllProjects();
    }
  }, [fetchAccounts, fetchAllProjects, token]);

  // Set the currentProject if there is a projectID in the URL
  // or local storage
  useEffect(() => {
    if (!token || !projects?.length) {
      return;
    }

    // projectId in URL takes #1 precedence
    if (projectIdURLParam && projectIdURLParam !== projectId) {
      const project = projects.find((project) => project.project_id === projectIdURLParam);
      if (project) {
        setCurrentProject(project);
      } else {
        // we do not want to remove the selected project id in
        // local storage here as it may be valid in the next logic block
        clearProjectData({ updateLocalStorage: false });
        logAndShowNotification({
          error: projectSelectorText.couldNotFindProject,
          logExtraData: { projectId: projectIdURLParam },
        });
      }
    } else {
      // projectId in local storage takes #2 precedence
      const projectIdInLocalStorage = window.localStorage.getItem(BIM360LocalStorageKeys.SELECTED_PROJECT_ID);
      if (projectIdInLocalStorage && projectIdInLocalStorage !== projectIdURLParam && !projectId) {
        const project = projects.find((project) => project.project_id === projectIdInLocalStorage);
        if (project) {
          setCurrentProject(project);
        } else {
          clearProjectData({ updateLocalStorage: true });
          logAndShowNotification({
            error: projectSelectorText.couldNotFindProject,
            logExtraData: { projectId: projectIdInLocalStorage },
          });
        }
      }
    }
  }, [clearProjectData, logAndShowNotification, projectId, projectIdURLParam, projects, setCurrentProject, token]);

  useEffect(() => {
    if (accountId) {
      filterProjectsForCurrentAccount();
    }
  }, [accountId, filterProjectsForCurrentAccount]);

  useEffect(() => {
    // Deep link provided the projectId, but we don't have the account yet
    if (accounts?.length && !accountId && accountIdInCurrentProject) {
      try {
        const accountBelongingToProject = findAccountBelongingToProject(accounts, accountIdInCurrentProject);
        setCurrentAccount(accountBelongingToProject);
      } catch (err) {
        clearAccountData();
        clearProjectData({ updateLocalStorage: true });
        if (err instanceof CustomError) {
          logAndShowNotification({ error: err });
        } else {
          throw err;
        }
      }
    }
  }, [
    accountId,
    accountIdInCurrentProject,
    accounts,
    clearAccountData,
    clearProjectData,
    findAccountBelongingToProject,
    logAndShowNotification,
    setCurrentAccount,
  ]);

  const renderAccountProjectName = (): JSX.Element => {
    if (accountsLoading || projectsLoading) {
      return <AccountAndProjectLoading />;
    }

    if (accountDisplayName || projectName) {
      return (
        <Typography variant="body2" noWrap component="div">
          {accountDisplayName} {projectName && `- ${projectName}`}
        </Typography>
      );
    }

    return (
      <Typography variant="body2" noWrap component="div">
        <strong>{projectSelectorText.title}</strong>
      </Typography>
    );
  };

  const handleOpenProjectMenu = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProject(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setAnchorElProject(null);
  };

  const handleSearchProjects = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target;
    const regex = new RegExp(value, 'i');

    const filteredProjects = projects?.filter((project) => {
      const titleLowercase = project.project_display_name.toLowerCase();
      return project.account_display_name === accountDisplayName && regex.test(titleLowercase);
    });
    setProjectsByAccount(filteredProjects);
  };

  return {
    accountId,
    accounts,
    accountDisplayName,
    accountImageURL,
    projectId,
    projects,
    projectsByAccount,
    setProjectsByAccount,
    renderAccountProjectName,
    setAnchorElProject,
    anchorElProject,
    handleOpenProjectMenu,
    handleCloseProjectMenu,
    handleSearchProjects,
  };
};
