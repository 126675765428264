import { useCallback, useContext, useEffect, useState } from 'react';
import { getSubFolders, getFolders, getFolderTree } from '../../../services/bim360';
import UserContext from '../../../context/UserStore/User.context';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';
import ModelSelectionContext from '../../../context/ModelSelectionStore/ModelSelection.context';
import { NotificationContext } from 'mid-react-common';
import { useNavigate } from 'react-router-dom';
import { ForgeDMProjectFolder } from 'mid-types';
import { BIM360FoldersFetchError } from 'mid-utils';
export interface Tree {
  [nodeId: string]: TreeNode[];
}

export interface TreeNode {
  id: string;
  label: string;
}
export interface UseModelFolderBrowser {
  modelFoldersTree: Tree | undefined;
  rootNodes: TreeNode[];
  expandedModelTreeIds: string[];
  handleFolderToggle: (_event: React.SyntheticEvent, nodeIds: string[]) => Promise<void>;
}

export const flattenFolderTree = (
  folderTree: ForgeDMProjectFolder[],
  folderId: string,
): {
  result: Record<string, TreeNode[]>;
  expandedPath: string;
} => {
  const result: Record<string, TreeNode[]> = {};
  const toTreeNode = (item: ForgeDMProjectFolder): TreeNode => ({ id: item.urn, label: item.title });
  result.root = [toTreeNode(folderTree[0])];
  let expandedPath = '';

  const traverseTree = (treeNode: ForgeDMProjectFolder) => {
    if (treeNode.urn === folderId) {
      expandedPath = treeNode.path;
    }
    if (treeNode.folders.length) {
      result[treeNode.urn] = [];
      for (const subItem of treeNode.folders) {
        result[treeNode.urn].push(toTreeNode(subItem));
        traverseTree(subItem);
      }
    }
  };

  traverseTree(folderTree[0]);

  return {
    result,
    expandedPath,
  };
};

const useModelFolderBrowser = (folderUrn = '', selectFolder: (folderUrn: string) => void): UseModelFolderBrowser => {
  const { token } = useContext(UserContext);
  const { expandedModelTreeIds, setExpandedTreeNodeIds, modelFoldersTree, setProductFoldersTree } =
    useContext(ModelSelectionContext);
  const { projectId, clearProjectData } = useContext(AccountProjectContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const [rootNodes, setRootNodes] = useState<TreeNode[]>([]);
  const navigate = useNavigate();

  const getFolderTreeNodes = async (
    token: string,
    projectId: string,
    folderUrn: string,
  ): Promise<{ result: Record<string, TreeNode[]>; expandedPath: string }> => {
    const folderTree = (await getFolderTree(token, projectId, folderUrn)).filter((folder) => folder.title !== 'Plans');

    return flattenFolderTree(folderTree, folderUrn);
  };

  const getRootNodes = useCallback(
    async (token: string, projectId: string): Promise<TreeNode[]> =>
      (await getFolders(token, projectId))
        .filter((folder) => folder.is_root && folder.title !== 'Plans')
        .sort((a, b) => (a.title > b.title ? 1 : -1))
        .map((folder) => ({
          id: folder.urn,
          label: folder.title,
        })),
    [],
  );

  const getRootNodesCallback = useCallback(async () => {
    if (!projectId || modelFoldersTree !== undefined) {
      return;
    }

    // if the folderUrn is provided, it means that the whole tree related to this folder needs to be loaded
    if (folderUrn) {
      try {
        const folderNodes = await getFolderTreeNodes(token, projectId, folderUrn);

        setRootNodes(folderNodes.result.root);
        setProductFoldersTree(folderNodes.result);

        // extract the env-dependent prefix of the folderUrn (URN)
        const folderUrnPrefix = folderUrn.match(/.*\./)![0];
        setExpandedTreeNodeIds(folderNodes.expandedPath.split('/').map((item) => folderUrnPrefix + item));

        selectFolder(folderUrn);
      } catch (error) {
        logAndShowNotification({ error });
        // If folder doesn't exists or returns an error, go back to 'root' folder
        if (error instanceof BIM360FoldersFetchError) {
          navigate('..');
        }
      }
    } else {
      try {
        const rootNodes = await getRootNodes(token, projectId);
        setRootNodes(rootNodes);
        setProductFoldersTree({ root: rootNodes });
      } catch (error) {
        logAndShowNotification({ error });
        // If getting the 'root' folder of a project returns an error
        // ask the user to select a new project (fixes: TRADES-4141)
        if (error instanceof BIM360FoldersFetchError) {
          clearProjectData({ updateLocalStorage: true });
          navigate('/');
        }
      }
    }
  }, [
    projectId,
    modelFoldersTree,
    folderUrn,
    token,
    setProductFoldersTree,
    setExpandedTreeNodeIds,
    selectFolder,
    logAndShowNotification,
    navigate,
    getRootNodes,
    clearProjectData,
  ]);

  useEffect(() => {
    getRootNodesCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleFolderToggle = async (_event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedTreeNodeIds(nodeIds);
    if (!projectId || !nodeIds.length) {
      return;
    }

    try {
      const subFolders = await getSubFolders(token, projectId, nodeIds[0]);
      const subFoldersTreeNodes: TreeNode[] = subFolders.map((subFolder) => ({
        id: subFolder.urn,
        label: subFolder.title,
      }));
      const newTree: Tree = {
        ...modelFoldersTree,
        [nodeIds[0]]: subFoldersTreeNodes,
      };

      setProductFoldersTree(newTree);
    } catch (error) {
      logAndShowNotification({ error });
    }
  };

  return {
    modelFoldersTree,
    rootNodes,
    expandedModelTreeIds,
    handleFolderToggle,
  };
};

export default useModelFolderBrowser;
