import { AddinsMSIVersions, AddinsVersionInfo } from '../types/artifacts';
import { logError, VersionInfoLoadError } from 'mid-utils';
import text from '../global/text.json';
import { ENVIRONMENT, Environment } from 'mid-types';
import { ApiServiceFactory } from 'mid-addin-lib/services/api.service';
import { ServiceConfigMap, ServiceTypes } from 'mid-addin-lib';

const versionInfoText = text.versionInfoService;

const METADATA_FILENAME_PATH = '/metadata.json';
const currentEnv = (process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
export const getMSIVersionInfo = async (): Promise<AddinsVersionInfo | null> => {
  try {
    const apiService = ApiServiceFactory.createApiService(ServiceTypes.MID_ARTIFACTS, {
      token: '',
      env: currentEnv,
    });
    const { response } = apiService.abortableGet(METADATA_FILENAME_PATH);
    const artifactsUrl =
      ServiceConfigMap[ServiceTypes.MID_ARTIFACTS][(process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV]
        .api;
    const data: AddinsMSIVersions = (await response).data;

    // Filter by addin versions by type and sort by date
    // For now, we will only show the 2023 installer versions
    const inventorMSIVersions = data
      .filter((row) => row.addinType === 'inventor' && row.hostApplicationVersion === '2023')
      .sort((x, y) => new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime());
    const revitMSIVersions = data
      .filter((row) => row.addinType === 'revit' && row.hostApplicationVersion === '2023')
      .sort((x, y) => new Date(x.timestamp).getTime() - new Date(y.timestamp).getTime());

    if (!inventorMSIVersions || !revitMSIVersions) {
      throw new VersionInfoLoadError(versionInfoText.versionInfoLoadError);
    }

    //Get Latest version
    const inventorLatestMSI = inventorMSIVersions[inventorMSIVersions.length - 1];
    const revitLatestMSI = revitMSIVersions[revitMSIVersions.length - 1];

    return {
      //Inventor data
      inventorVersion: inventorLatestMSI.version,
      inventorFilename: inventorLatestMSI.name,
      inventorFileURL: artifactsUrl + '/' + inventorLatestMSI.name,

      //Revit Data
      revitVersion: revitLatestMSI.version,
      revitFilename: revitLatestMSI.name,
      revitFileURL: artifactsUrl + '/' + revitLatestMSI.name,
    };
  } catch (e) {
    logError(e);
    throw new VersionInfoLoadError(versionInfoText.versionInfoLoadError);
  }
};
