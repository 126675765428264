import { StateSetter } from 'mid-react-common';
import { AccAccount, AccProject } from 'mid-types';
import { useState } from 'react';
import { generatePath, useNavigate, useMatch } from 'react-router-dom';
import { BIM360LocalStorageKeys } from 'mid-utils';
import { usePrevious } from '../../global/hooks/hooks';
import { routes } from '../../routes';

interface ClearProjectDataArgs {
  updateLocalStorage?: boolean;
}
export interface AccountProjectsStore {
  accountId: string | null;
  projectId: string | null;
  projectName: string | null;
  accountDisplayName: string | undefined;
  accountImageURL: string | undefined;
  accountIdInCurrentProject: string | undefined;
  previousProjectId: string | undefined;
  hasProjectChanged: boolean;
  setCurrentAccount: StateSetter<AccAccount | null>;
  setCurrentProject: (project: AccProject | null, updateLocalStorage?: boolean) => void;
  clearAccountData: () => void;
  clearProjectData: (clearProjectDataArgs: ClearProjectDataArgs) => void;
}

export const useAccountProjectsStore = (): AccountProjectsStore => {
  const [currentAccount, setCurrentAccount] = useState<AccAccount | null>(null);
  const [currentProject, _setCurrentProject] = useState<AccProject | null>(null);
  const previousProjectId = usePrevious(currentProject?.project_id);
  const hasProjectChanged = !!previousProjectId && previousProjectId !== currentProject?.project_id;
  const navigate = useNavigate();
  const isOnProjectIdRoute = !!useMatch(routes.projectId.path + '/*');

  const setCurrentProject = (project: AccProject | null, updateLocalStorage = true) => {
    if (project && updateLocalStorage) {
      window.localStorage.setItem(BIM360LocalStorageKeys.SELECTED_PROJECT_ID, project.project_id);
    }
    if (!project && updateLocalStorage) {
      window.localStorage.removeItem(BIM360LocalStorageKeys.SELECTED_PROJECT_ID);
    }

    if (project) {
      const projectIdPath = generatePath(routes.projectId.path, { projectId: project.project_id });
      if (!isOnProjectIdRoute) {
        navigate(projectIdPath);
      }
    }

    if (!project) {
      navigate(routes.index.path);
    }
    _setCurrentProject(project);
  };
  const clearProjectData = (localStorageArgs: ClearProjectDataArgs) => {
    setCurrentProject(null, localStorageArgs.updateLocalStorage);
  };

  const clearAccountData = () => {
    setCurrentAccount(null);
  };

  return {
    accountId: currentAccount?.account_id || null,
    accountDisplayName: currentAccount?.account_display_name,
    accountImageURL: currentAccount?.image_url,
    projectId: currentProject?.project_id || null,
    // The accountIdInCurrentProject & accountId can be different,
    // as they come from two different sources (error scenario)
    accountIdInCurrentProject: currentProject?.account_id,
    projectName: currentProject?.project_display_name || null,
    previousProjectId,
    hasProjectChanged,
    setCurrentProject,
    setCurrentAccount,
    clearAccountData,
    clearProjectData,
  };
};
