import Button from '@mui/material/Button';
import { StateSetter } from 'mid-react-common';
import React from 'react';
import MissingThumbnail from '../../../../src/images/missingThumbnail.jpg';
import text from '../../../global/text.json';
import ConfirmationDialog from '../../Commons/ConfirmationDialog';
import CustomAccordion from '../../Commons/CustomAccordion';
import { DetailsSidebarContent, DetailsSidebarHeader } from '../../Commons/DetailsSidebar';
import KeyValueTable from '../../Commons/KeyValueTable';
import SidebarThumbnail from '../../Commons/SidebarThumbnail';
import { FolderContentRow } from '../ModelsFolderContent/ModelsFolderContent.types';
import { ModelDetailsSidebar, ModelDetailsSpinner } from './ModelDetails.style';
import useModelDetails from './useModelDetails';

const modelDetailsText = text.modelDetails;
interface ModelDetailsProps {
  folderUrn: string;
  selectedModelFolder: FolderContentRow | null;
  isPreviewLoading: boolean;
  setSelectedModelFolder: StateSetter<FolderContentRow | null>;
  handleModelDetailsClose: () => void;
}

export const ModelDetails: React.FC<ModelDetailsProps> = ({
  folderUrn,
  selectedModelFolder,
  isPreviewLoading,
  setSelectedModelFolder,
  handleModelDetailsClose,
}): JSX.Element => {
  const {
    fileInfo,
    currentlyOpenModel,
    openConfirmUnselect,
    openConfirmReplace,
    handleCloseButtonClick,
    handleOpenModelClick,
    handleShowCloseModelConfirmation,
    handleConfirmReplaceOpen,
    handleConfirmReplaceClose,
    handleDismissCloseModelConfirmation,
    handleCloseModel,
  } = useModelDetails(folderUrn, selectedModelFolder, setSelectedModelFolder, handleModelDetailsClose);

  return (
    <>
      <ModelDetailsSidebar>
        {isPreviewLoading ? (
          <ModelDetailsSpinner size={75} />
        ) : (
          <>
            <DetailsSidebarHeader onClose={handleCloseButtonClick}>
              {!currentlyOpenModel && (
                <Button variant="contained" onClick={handleOpenModelClick} data-testid="modelDetailsOpenButton">
                  {modelDetailsText.openModel}
                </Button>
              )}
              {currentlyOpenModel && currentlyOpenModel.id === selectedModelFolder?.id && (
                <Button variant="outlined" onClick={handleShowCloseModelConfirmation} data-testid="modelDetailsCloseButton">
                  {modelDetailsText.closeModel}
                </Button>
              )}
              {currentlyOpenModel && currentlyOpenModel.id !== selectedModelFolder?.id && (
                <Button
                  variant="contained"
                  onClick={handleConfirmReplaceOpen}
                  size="small"
                  data-testid="modelDetailsChangeButton"
                >
                  {modelDetailsText.changeModel}
                </Button>
              )}
            </DetailsSidebarHeader>
            <DetailsSidebarContent>
              <CustomAccordion title={modelDetailsText.preview}>
                {selectedModelFolder && selectedModelFolder.thumbnail ? (
                  <SidebarThumbnail src={URL.createObjectURL(selectedModelFolder.thumbnail)} />
                ) : (
                  <SidebarThumbnail src={MissingThumbnail} />
                )}
              </CustomAccordion>
              <CustomAccordion title={modelDetailsText.modelDetails}>
                <KeyValueTable data={fileInfo} />
              </CustomAccordion>
            </DetailsSidebarContent>
          </>
        )}
      </ModelDetailsSidebar>
      <ConfirmationDialog
        open={openConfirmReplace}
        message={modelDetailsText.replaceModel}
        onCancel={handleConfirmReplaceClose}
        onConfirm={() => {
          handleOpenModelClick();
          handleConfirmReplaceClose();
        }}
        data-testid="replaceModel"
      />
      <ConfirmationDialog
        open={openConfirmUnselect}
        message={modelDetailsText.unselectModel}
        onCancel={handleDismissCloseModelConfirmation}
        onConfirm={() => {
          handleCloseModel();
          handleDismissCloseModelConfirmation();
        }}
        data-testid="unselectModel"
      />
    </>
  );
};
