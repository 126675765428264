import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import { FormControlLabel, FormGroup } from '@mui/material';

export const SettingsPanelBackground = styled(Box)(() => ({
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
}));

export const SettingsPanelContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',
}));

export const SettingsPanelContents = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '24px',
  gap: '18px',
}));

export const OutputTypesSettings = styled(Card)(() => ({
  flexGrow: '1',
  padding: '24px',
}));

export const UploadSettings = styled(Card)(() => ({
  flexGrow: '3',
  padding: '24px',
}));

export const SettingPanelFormGroup = styled(FormGroup)`
  margin-top: 24px;
`;

export const SecondLayerFormControlLabel = styled(FormControlLabel)`
  margin-left: 24px;
`;

export const ThirdLayerFormControlLabel = styled(FormControlLabel)`
  margin-left: 48px;
`;
