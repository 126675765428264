import {
  ClientSideOAuth2Component,
  IClientSideOAuth2Configuration,
  UserInfoComponent,
} from '@adsk/forge-appfw-authentication';
import { IForgeConfiguration } from '@adsk/forge-appfw-forge-configuration';
import { AuthenticationError, UserInfoError } from 'mid-utils';
import { UserInfo } from '../types/user';
import { authenticationConfig, forgeConfig } from './authConfig';
import text from '../mid-addin-lib.text.json';

export class AuthService {
  public token?: string;

  private readonly authClient: ClientSideOAuth2Component;
  private userInfoClient: UserInfoComponent;

  constructor(authConfig: IClientSideOAuth2Configuration, forgeConfig: IForgeConfiguration) {
    this.authClient = new ClientSideOAuth2Component(authConfig);
    this.userInfoClient = new UserInfoComponent(this.authClient, forgeConfig);
  }

  public async authenticate(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.authClient.bearerTokenFunction((error?: object, token?: string) => {
        if (error) {
          reject(error);
        } else if (token) {
          this.token = token;
          resolve(token);
        } else {
          reject(new AuthenticationError(text.invalidToken));
        }
      });
    });
  }

  public async getUserInfo(): Promise<UserInfo> {
    try {
      await this.userInfoClient.initializeComponent();
      const userInfo = (await this.userInfoClient.getUserInfo()) as UserInfo;

      return { ...userInfo, email: userInfo.emailId };
    } catch (e: unknown) {
      throw new UserInfoError(text.failToGetUserInfo, { error: e });
    }
  }

  public logout(): Location {
    return this.userInfoClient.logout();
  }
}

export default new AuthService(authenticationConfig, forgeConfig);
