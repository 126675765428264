import Bugsnag from '@bugsnag/js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getUserAnalyticsId, getUserProfile } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useContext, useEffect, useState } from 'react';
import NotificationContext from '../../context/NotificationStore/Notification.context';
import { NOTIFICATION_STATUSES } from '../../context/NotificationStore/notificationStore.types';
import { LD_ANALYTICS_KIND } from '../../global/featureFlags';
import text from '../../mid-react-common.text.json';

interface Analytics {
  analyticsId: string | undefined;
}

export function useAnalytics(userId?: string | null, token?: string): Analytics {
  const { showNotification } = useContext(NotificationContext);

  const [analyticsId, setAnalyticsId] = useState<string | undefined>(undefined);
  const ldClient = useLDClient();

  useEffect(() => {
    const getAnalyticsIdAndSetUpBugsnag = async () => {
      try {
        const userOxygenID = userId || (await getUserProfile()).userId;
        const fetchedAnalyticsId = await getUserAnalyticsId(userOxygenID, token);

        setAnalyticsId(fetchedAnalyticsId);
        Bugsnag.setUser(fetchedAnalyticsId);
      } catch (error) {
        logError(error);
        showNotification({
          message: text.notificationGetUserProfileFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    };

    getAnalyticsIdAndSetUpBugsnag();
  }, [setAnalyticsId, showNotification, token, userId]);

  useEffect(() => {
    if (ldClient && analyticsId) {
      ldClient.identify({ kind: LD_ANALYTICS_KIND, key: analyticsId });
    }
  }, [ldClient, analyticsId]);

  return { analyticsId };
}
