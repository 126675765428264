import React from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { TreeItem, TreeView } from '@mui/lab';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import text from '../../../global/text.json';
import { HigFolder, HigFolderOpen } from 'mid-react-common';
import {
  FolderContainer,
  FolderNode,
  FolderNodeContainer,
  TitleContainer,
  TreeViewContainer,
} from './ModelsFolderBrowser.style';
import useModelFolderBrowser, { TreeNode } from './useModelFolderBrowser';
interface ModelsFolderBrowserProps {
  handleFolderClick: (folderUrn: string) => void;
  selectFolder: (folderUrn: string) => void;
  folderUrn?: string;
}
const modelsFolderText = text.modelsFolderBrowser;

export const ModelsFolderBrowser: React.FC<ModelsFolderBrowserProps> = ({ handleFolderClick, selectFolder, folderUrn }) => {
  const { modelFoldersTree, expandedModelTreeIds, handleFolderToggle } = useModelFolderBrowser(folderUrn, selectFolder);

  const renderTree = (children: TreeNode[]) =>
    children.map((child) => {
      const { id, label } = child;
      const childrenNodes =
        modelFoldersTree?.[id] && modelFoldersTree[id].length > 0 ? renderTree(modelFoldersTree[id]) : [<div key={id} />];

      return (
        <TreeItem
          key={id}
          nodeId={id}
          label={
            <FolderNodeContainer>
              <FolderNode>
                {expandedModelTreeIds?.find((nodeId) => nodeId === id) ? <HigFolderOpen /> : <HigFolder />}
              </FolderNode>
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </FolderNodeContainer>
          }
          onClick={() => handleFolderClick(id)}
        >
          {childrenNodes}
        </TreeItem>
      );
    });
  return (
    <FolderContainer>
      <TitleContainer>
        <Typography variant="body1" noWrap>
          {modelsFolderText.selectModel}
        </Typography>
      </TitleContainer>
      <Divider />
      <TreeViewContainer>
        <TreeView
          selected={folderUrn}
          defaultCollapseIcon={<ArrowDropDown />}
          defaultExpandIcon={<ArrowRight />}
          onNodeToggle={handleFolderToggle}
          expanded={expandedModelTreeIds}
        >
          {renderTree(modelFoldersTree?.root || [])}
        </TreeView>
      </TreeViewContainer>
    </FolderContainer>
  );
};
