//TODO: handle pagination for Products and Variants

import { isFulfilled, isRejected } from '../utils/typeGuards';
import { AuthenticationError, FileUrlError, logError, ProductFetchError, VariantFetchError } from 'mid-utils';
import text from '../global/text.json';
import {
  BatchFetchProductsResult,
  BatchFetchVariantsResult,
  DynamicContentProduct,
  DynamicContentVariant,
  Environment,
  ENVIRONMENT,
  VariantsQueryParameters,
} from 'mid-types';
import { ApiServiceFactory } from 'mid-addin-lib/services/api.service';
import { ServiceTypes } from 'mid-addin-lib';

const productsServiceText = text.productsService;
const apiServiceText = text.apiService;
const currentEnv = (process.env.REACT_APP_ENVIRONMENT as Environment) || ENVIRONMENT.DEV;
export async function fetchProducts(token: string, projectId: string): Promise<DynamicContentProduct[] | null> {
  if (!token || !projectId) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });
  const path = `projects/${projectId}/products`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data.results;
  } catch (e) {
    logError(e);
    throw new ProductFetchError(productsServiceText.productsLoadError, { projectId });
  }
}

export async function fetchVariants(
  token: string,
  projectId: string,
  productId: string,
): Promise<DynamicContentVariant[] | null> {
  if (!token || !projectId || !productId) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });
  const path = `projects/${projectId}/products/${productId}/variants`;
  const { response } = apiService.abortableGet(path);

  try {
    return (await response).data.results;
  } catch (e) {
    logError(e);
    throw new VariantFetchError(productsServiceText.variantsLoadError, { productId, projectId });
  }
}

export async function batchFetchProducts(token: string, projectIds: string[]): Promise<BatchFetchProductsResult | null> {
  if (!token) {
    throw new AuthenticationError(apiServiceText.unauthorizedAccessError);
  }
  if (projectIds.length === 0) {
    return null;
  }
  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });

  const resultsPromise = projectIds.map<Promise<DynamicContentProduct[]>>(
    async (projectId): Promise<DynamicContentProduct[]> => {
      const path = `projects/${projectId}/products`;
      const { response } = apiService.abortableGet(path);
      const { data } = await response;
      return data.results;
    },
  );
  const results = await Promise.allSettled<DynamicContentProduct[]>(resultsPromise);
  return processAllSettledResults(results);
}

export async function batchFetchVariants(
  token: string,
  variantsParameters: VariantsQueryParameters[],
): Promise<BatchFetchVariantsResult | null> {
  if (!token) {
    throw new AuthenticationError(apiServiceText.unauthorizedAccessError);
  }
  if (variantsParameters.length === 0) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });
  const resultsPromise = variantsParameters.map(async (variantInfo) => {
    const path = `projects/${variantInfo.projectId}/products/${variantInfo.productId}/variants`;

    const { response } = apiService.abortableGet(path);
    const { data } = await response;
    return data.results;
  });
  const results = await Promise.allSettled<DynamicContentVariant[]>(resultsPromise);

  return processAllSettledResults(results);
}

export async function fetchFileUrl(token: string, projectId: string, objectKey: string): Promise<string | null> {
  if (!token || !projectId || !objectKey) {
    return null;
  }

  const apiService = ApiServiceFactory.createApiService(ServiceTypes.OFFSITE_API, {
    token,
    env: currentEnv,
  });
  const path = `projects/${projectId}/data/downloadurl`;
  const postData = { objectKey };
  const { response } = apiService.abortablePost(path, postData);

  try {
    return (await response).data.signedUrl;
  } catch {
    throw new FileUrlError("Can't get file download URL", {
      error: Error(productsServiceText.fetchFileURLError),
      url: path,
      objectKey,
    });
  }
}

function processAllSettledResults<T>(resultsPromise: PromiseSettledResult<T>[]) {
  const results = resultsPromise
    .filter(isFulfilled)
    .map<T>((val) => val.value)
    .flat();

  const errors = resultsPromise.filter(isRejected);
  return { results, errors };
}
