import { rest } from 'msw';
import {
  bypassRequest,
  getAccountsHandler,
  getFoldersHandler,
  getProjectsHandler,
  getSubFoldersHandler,
  getAllProductsHandler,
  postVariantHandler,
  getDownloadUrlHandler,
  getVariantHandler,
  postUploadUrls,
  postCompleteUpload,
  putUploadUrl,
  postProduct,
  deleteProductTemplateHandler,
  createNewfolder,
  getVariantLog,
  getUserProfile,
  getAnalyticsId,
  getRulesObject,
  getRulesObjectWithError,
  getManifestHandler,
  getFolderContentHandler,
  getFolderTreeHandler,
  getMSIMetadata,
  getThumbnailHandler,
  getVariantOutputsHandler,
  getVariantsHandler,
} from './handlers';
import { ServiceConfigMap, ServiceTypes } from 'mid-addin-lib/services/serviceAPIConfig';
import { Paths } from 'mid-addin-lib/services/apiPathConfig';

export const handlers = [
  // from FORGE staging API
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.EA_API_PATH}/${Paths.ACCOUNT_ENTITLEMENTS_PATH}`,
    getAccountsHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.EA_API_PATH}/${Paths.PROJECT_ENTITLEMENTS_PATH}`,
    getProjectsHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.DM_STG_API_PATH}/${Paths.PROJECTS}/:projectId/folders`,
    getFoldersHandler,
  ),
  rest.post(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.DM_STG_API_PATH}/${Paths.PROJECTS}/:projectId/folders`,
    createNewfolder,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.DM_STG_API_PATH}/${
      Paths.PROJECTS
    }/:projectId/folders/:folderUrn`,
    getSubFoldersHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.DM_STG_API_PATH}/${
      Paths.PROJECTS
    }/:projectId/folders/:folderId/folder_tree`,
    getFolderTreeHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.DM_STG_API_PATH}/${
      Paths.PROJECTS
    }/:projectId/folders/:folder/documents`,
    getFolderContentHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.MODEL_DERIVATIVE_PATH}/:documentId/manifest`,
    getManifestHandler,
  ),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.DOCUMENT_THUMBNAIL_PATH}/:documentId`,
    getThumbnailHandler,
  ),
  rest.get(`${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.USERPROFILE_API_PATH}/users/@me`, getUserProfile),
  rest.get(
    `${ServiceConfigMap[ServiceTypes.FORGE_API].stg.api}/${Paths.IDENTITY_API_PATH}/users/:userId/analytics`,
    getAnalyticsId,
  ),

  // Local SVG and PNG
  rest.get('http://localhost:3000/static/media/filing_cabinet_grey.*.svg', bypassRequest),
  rest.put('https://s3.aws.com/path/to/thumbnail.png', putUploadUrl),
  // Rules
  rest.get('https://s3.aws.com/path/to/rules.json', getRulesObject),
  rest.get('https://s3.aws.com/path/to/rulesWithError.json', getRulesObjectWithError),
  // DC API
  // Download Url
  rest.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:tenancyId/data/downloadurl`,
    getDownloadUrlHandler,
  ),
  // POST variant
  rest.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products/:productId/variants`,
    postVariantHandler,
  ),
  // GET variant by id
  rest.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products/:productId/variants/:variantId`,
    getVariantHandler,
  ),
  // UploadUrl
  rest.post(`${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/data/uploadurls`, postUploadUrls),
  // Complete Upload
  rest.post(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/data/completeupload`,
    postCompleteUpload,
  ),
  // Post product
  rest.post(`${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products`, postProduct),
  // Delete product by id
  rest.delete(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products/:productId`,
    deleteProductTemplateHandler,
  ),
  // GET variant log
  rest.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products/:productId/variants/:variantId/logs`,
    getVariantLog,
  ),
  // GET all products
  rest.get(`${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products`, getAllProductsHandler),

  // Get variants
  rest.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products/:productId/variants`,
    getVariantsHandler,
  ),
  // Get variant by id
  rest.get(
    `${ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api}/projects/:projectId/products/:productId/variants/:variantId`,
    getVariantHandler,
  ),
  // GET variant output
  rest.get(
    `${
      ServiceConfigMap[ServiceTypes.OFFSITE_API].stg.api
    }/projects/:projectId/products/:productId/variants/:variantId/outputs`,
    getVariantOutputsHandler,
  ),
  // GET metadata
  rest.get(`${ServiceConfigMap[ServiceTypes.MID_ARTIFACTS].stg.api}/metadata.json`, getMSIMetadata),
];
