import { SidebarThumbnailContainer, SidebarThumbnailImage } from './SidebarThumbnail.style';

export interface SidebarThumbnailProps {
  src: string;
  alt?: string;
}

export const SidebarThumbnail = ({ src, alt }: SidebarThumbnailProps): JSX.Element => (
  <SidebarThumbnailContainer>
    <SidebarThumbnailImage src={src} alt={alt || ''} />
  </SidebarThumbnailContainer>
);
