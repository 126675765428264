import { AccAccount } from 'mid-types';

export const mockAccount: AccAccount = {
  account_id: 'acct-001',
  account_display_name: 'acct-001',
  image_url: 'http://img-acct-001',
  account_url: 'http://acc-acct-001',
  region: 'US',
};

export const mockAccount2: AccAccount = {
  account_id: 'acct-002',
  account_display_name: 'acct-002',
  image_url: 'http://img-acct-002',
  account_url: 'http://acc-acct-002',
  region: 'US',
};
