import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AccProject } from 'mid-types';
import text from '../../../global/text.json';
import { AccountDetails } from '../AccountDetails/AccountDetails';
import { ProjectListContainer, ProjectsListTitle, ProjectsMenuList, ProjectsMenuListItem } from './ProjectsList.style';
import { useProjectsList } from './useProjectsList';

interface ProjectsListProps {
  projects: AccProject[] | undefined;
  visible: boolean;
  handleCloseProjectMenu: () => void;
  accountName: string | undefined;
  accountImageURL: string | undefined;
}

const projectListText = text.projectList;
export const ProjectsList: React.FC<ProjectsListProps> = ({
  projects,
  visible,
  handleCloseProjectMenu,
  accountName,
  accountImageURL,
}): JSX.Element => {
  const { handleProjectSelectionClick } = useProjectsList({ handleCloseProjectMenu });

  return (
    <ProjectListContainer visible={visible}>
      <AccountDetails projectsQty={projects?.length} accountName={accountName} accountImageURL={accountImageURL} />
      <Divider />
      <ProjectsListTitle>
        <Typography variant="h6">{projectListText.projects}</Typography>
      </ProjectsListTitle>
      {projects && (
        <ProjectsMenuList data-testid="projects-list">
          {projects.map((project) => (
            <ProjectsMenuListItem
              key={`${project.account_id}${project.project_id}`}
              onClick={() => handleProjectSelectionClick(project)}
            >
              <Typography variant="body2">{project.project_display_name}</Typography>
            </ProjectsMenuListItem>
          ))}
        </ProjectsMenuList>
      )}
    </ProjectListContainer>
  );
};
