import Warning from '@mui/icons-material/Warning';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import text from '../../global/text.json';
import { IconWrapper } from './MIDWVersionInfo.styles';

const versionInfoText = text.versionInfoPanel;

export const MIDWVersionInfoNotAvailable: React.FC = (): JSX.Element => (
  <>
    <ListItemButton disabled data-testid={'version-info-not-available'}>
      <IconWrapper>
        <Warning />
      </IconWrapper>
      <ListItemText primary={`${versionInfoText.addinsInformationNotAvailable}`} />
    </ListItemButton>
  </>
);
