import { floor, isNaN } from 'lodash';
import { TemplateInputType } from 'mid-types';

export interface IProperty {
  category: string;
  displayName: string;
  id: string;
  value: string;
  label?: string;
}

export interface InventorParameter {
  name: string;
  parameterType: string;
  unitType: string;
  value: string;
  isKey: boolean;
  label?: string;
  options?: string[] | number[];
}

export interface InventorProperties {
  parameters: InventorParameter[];
  iProperties: IProperty[];
}

export const isMultivaluedInventorParameter = (p: InventorParameter): boolean => !!p.options;
export const isBooleanInventorParameter = (p: InventorParameter): boolean => p.unitType.toLowerCase() === 'boolean';

// *** Both single value & multi-value text parameters ***
// are returned as type: "text" by Inventor
// We differentiate between the two by checking if the options key exists or not
export const isTextInventorParameter = (p: InventorParameter): boolean =>
  p.unitType.toLowerCase() === 'text' && !isMultivaluedInventorParameter(p);

export const getInputTypeFromInventorParameter = (
  param: InventorParameter,
): Exclude<TemplateInputType, TemplateInputType.IProperty> => {
  if (isBooleanInventorParameter(param)) {
    return TemplateInputType.Boolean;
  }
  if (isTextInventorParameter(param)) {
    return TemplateInputType.Text;
  }
  if (isMultivaluedInventorParameter(param)) {
    if (param.options && areOptionsNumeric(param.options)) {
      return TemplateInputType.MultiValueNumeric;
    }
    return TemplateInputType.MultiValueText;
  }
  return TemplateInputType.Numeric;
};

export const areOptionsNumeric = (options: Array<string | number>): boolean => options.every(isNumeric);

export const isNumeric = (x: string | number): boolean =>
  (typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x));

export const arrayToNumericArray = (array: string[] | number[]): number[] => array.map(eightDecimalPlacesMax);

export const eightDecimalPlacesMax = (number: string | number): number => floor(Number(number), 8);
