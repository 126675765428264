import { AlertColor } from '@mui/material';
import { useCallback, useState } from 'react';
import { HandleCloseReason, NOTIFICATION_STATUSES } from './notificationStore.types';

import { CustomError, extractErrorMessage, logError, logWarn } from 'mid-utils';
import text from '../../mid-react-common.text.json';
import {
  AnchorHorizontalPosition,
  AnchorVerticalPosition,
  ANCHOR_HORIZONTAL_POSITIONS,
  ANCHOR_VERTICAL_POSITIONS,
} from './notification.constants';

interface ShowNotificationProps {
  severity: AlertColor;
  message: string;
  autoDismiss?: boolean;
}

interface LogAndShowNotificationProps extends Partial<ShowNotificationProps> {
  logExtraData?: any;
  error?: Error | CustomError | unknown;
}

export interface NotificationStore {
  open: boolean;
  severity: AlertColor;
  message: string;
  anchor: { vertical: AnchorVerticalPosition; horizontal: AnchorHorizontalPosition };
  isAutoHideDurationEnable: boolean;
  showNotification: (props: ShowNotificationProps) => void;
  logAndShowNotification: (props: LogAndShowNotificationProps) => void;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: HandleCloseReason) => void;
}

export const useNotificationStore = (): NotificationStore => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>(NOTIFICATION_STATUSES.INFO);
  const [message, setMessage] = useState('');
  const [isAutoHideDurationEnable, setAutoHideDurationEnable] = useState(true);
  const anchor = { vertical: ANCHOR_VERTICAL_POSITIONS.TOP, horizontal: ANCHOR_HORIZONTAL_POSITIONS.RIGHT };

  const showNotification = useCallback(({ severity, message, autoDismiss = true }: ShowNotificationProps) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
    setAutoHideDurationEnable(autoDismiss);
  }, []);

  const logAndShowNotification = useCallback(
    ({ severity = NOTIFICATION_STATUSES.ERROR, message, error, logExtraData }: LogAndShowNotificationProps) => {
      let messageToLog = '',
        messageToShow = '';

      if (!message && !error) {
        throw new Error(text.insufficientArguments);
      }

      // determine what to show to user and log based on passed parameters
      if (!message && error) {
        messageToLog = messageToShow = extractErrorMessage(error);
      } else if (message && !error) {
        messageToLog = messageToShow = message;
      } else if (message && error) {
        messageToShow = message;
        messageToLog = `${extractErrorMessage(error)},${message}`;
      }

      if (severity === NOTIFICATION_STATUSES.WARNING) {
        logWarn(messageToLog, logExtraData);
      } else {
        logError(messageToLog, logExtraData);
      }
      showNotification({ severity, message: messageToShow });
    },
    [showNotification],
  );

  /**
   * event: The event source of the callback.
   *
   * reason: Can be: "timeout" (autoHideDuration expired), "clickaway", or "escapeKeyDown"
   */
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: HandleCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return {
    open,
    severity,
    message,
    anchor,
    isAutoHideDurationEnable,
    showNotification,
    logAndShowNotification,
    handleClose,
  };
};
