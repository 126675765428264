import { AccAccount } from 'mid-types';
import { useContext } from 'react';
import AccountProjectContext from '../../../context/AccountProjectStore/AccountProject.context';

interface UseAccountsListState {
  handleAccountItemClick: (account: AccAccount) => void;
}

export const useAccountsList = (): UseAccountsListState => {
  const { setCurrentAccount, clearProjectData } = useContext(AccountProjectContext);

  const handleAccountItemClick = (account: AccAccount) => {
    setCurrentAccount(account);
    clearProjectData({ updateLocalStorage: true });
  };

  return {
    handleAccountItemClick,
  };
};
