import { ThemeProvider } from '@mui/material/';
import AppBar from '@mui/material/AppBar';
import logoMid from '../../../images/logo.png';
import { darkTheme } from '../../../utils/theme';
import { MIDWVersionInfo } from '../../MIDWVersionInfo/MIDWVersionInfo';
import ProjectSelectorPanel from '../ProjectSelectorPanel';
import UserSettingsPanel from '../UserSettingsPanel';
import { AppLogoContainer, AppLogoImage, HeaderAppBarContainer, HeaderToolbarContainer } from './HeaderAppBar.style';
export function HeaderAppBar(): JSX.Element {
  return (
    <HeaderAppBarContainer>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" elevation={0}>
          <HeaderToolbarContainer variant="dense">
            <AppLogoContainer>
              <AppLogoImage src={logoMid} alt="" />
            </AppLogoContainer>
            <MIDWVersionInfo MIDW_VERSION={process.env.REACT_APP_MIDW_VERSION} />
            <ProjectSelectorPanel />
            <UserSettingsPanel />
          </HeaderToolbarContainer>
        </AppBar>
      </ThemeProvider>
    </HeaderAppBarContainer>
  );
}
