import {
  BooleanInput,
  DynamicContentInput,
  DynamicContentProduct,
  IPropertyInput,
  MultiValueNumericInput,
  MultiValueTextInput,
  NumericInput,
  ProjectFolder,
  TemplateContext,
  TemplateInputType,
  TemplateOutput,
  TextInput,
} from 'mid-types';
import {
  DeleteDraftError,
  InvalidDraftOutputError,
  SaveDraftError,
  ThumbnailError,
  isDeprecatedDraft,
  isDraftTemplateIProperty,
  logWarn,
} from 'mid-utils';
import { Result } from '../interfaces/cefSharp';
import {
  IProperty,
  InventorParameter,
  arrayToNumericArray,
  getInputTypeFromInventorParameter,
} from '../interfaces/inventorProperties';
import {
  DeprecatedDraftTemplate,
  DraftTemplate,
  DraftTemplateIProperty,
  DraftTemplateInput,
  DraftTemplateInputParameter,
  DraftTemplateOutput,
  DraftTemplatePublishResult,
  InputRule,
  MetaInfo,
  MetaInfoPath,
  OutputType,
  SerializedBlocklyWorkspaceState,
} from '../interfaces/templates';
import text from '../mid-addin-lib.text.json';
import browserApiService from '../services/browserApiService';
import { getUUID } from '../services/uuid.service';
import { BatchDeprecatedDraftMigrationResult } from '../types/drafts';
import { getContentFromObjectKey } from './cloudStorage';
import { publishProductFromDraft } from './publish';
import { removeDoubleQuotes, removeDoubleQuotesFromStringArray, removeQuotesFromTextParameters } from './tools';
import { getProjectFolders } from './workspace';

export const batchSaveDrafts = async (drafts: DraftTemplate[]): Promise<DraftTemplate[]> => {
  const updatedDrafts = drafts.map((draft) => {
    // Update lastUpdate time
    const updatedDraft: DraftTemplate = { ...draft, lastUpdated: Date.now() };
    // if draft doesn't have an id, it's a new draft,
    // so we'll need to generate one and add the new draft to the list of drafts
    if (!updatedDraft.id) {
      const draftId: string = getUUID();
      updatedDraft.id = draftId;
    }

    return updatedDraft;
  });
  const result: Result<boolean> = await browserApiService.saveDrafts(JSON.stringify(updatedDrafts));

  // throw error if drafts couldn't be saved
  if (!result.value) {
    throw new SaveDraftError(text.notificationSavedDraftFailed, {
      error: Error(result.errorMessage!),
    });
  }

  return updatedDrafts;
};

export const batchMigrateDeprecatedDrafts = async (
  originalDrafts: (DraftTemplate | DeprecatedDraftTemplate)[],
): Promise<BatchDeprecatedDraftMigrationResult> => {
  let foundDeprecatedDraft = false;
  const updatedDrafts = originalDrafts.map((draft) => {
    if (isDeprecatedDraft(draft)) {
      logWarn(text.foundDeprecatedDraft, { draftName: draft.name });
      foundDeprecatedDraft = true;
      const { parameters, iProperties, ...remainingProperties } = draft;
      const migratedDraft: DraftTemplate = {
        ...remainingProperties,
        inputs: [...parameters, ...iProperties],
      };
      return migratedDraft;
    }
    return draft;
  });

  if (foundDeprecatedDraft) {
    const migratedDrafts = await batchSaveDrafts(updatedDrafts);
    return { foundDeprecatedDraft, drafts: migratedDrafts };
  }

  return { foundDeprecatedDraft, drafts: originalDrafts };
};

export const getDrafts = async (): Promise<DraftTemplate[]> => {
  const loadedDrafts: Result<string> = await browserApiService.loadDrafts();
  if (loadedDrafts.value === null) {
    throw new Error(`${loadedDrafts.errorMessage}`);
  }

  // if we get null back, then there are no drafts yet, so we return an empty array
  const drafts: DraftTemplate[] = loadedDrafts.value.length ? JSON.parse(loadedDrafts.value) : [];

  const migratedDraftsResult: BatchDeprecatedDraftMigrationResult = await batchMigrateDeprecatedDrafts(drafts);
  const trimmedDrafts = migratedDraftsResult.drafts.map((draft) => ({
    ...draft,
    inputs: removeQuotesFromTextParameters(draft.inputs),
  }));

  return trimmedDrafts;
};

export const saveDraft = async (draft: DraftTemplate): Promise<DraftTemplate> => {
  // read drafts from storage
  const drafts: DraftTemplate[] = await getDrafts();

  // Update lastUpdate time
  const updatedDraft: DraftTemplate = {
    ...draft,
    lastUpdated: Date.now(),
  };
  // if draft doesn't have an id, it's a new draft,
  // so we'll need to generate one and add the new draft to the list of drafts
  if (!updatedDraft.id) {
    const draftId: string = getUUID();
    updatedDraft.id = draftId;
    drafts.push(updatedDraft);
  } else {
    const index = drafts.findIndex(({ id }) => id === draft.id);
    drafts[index] = draft;
  }

  const result: Result<boolean> = await browserApiService.saveDrafts(JSON.stringify(drafts));

  // throw error if drafts couldn't be saved
  if (!result.value) {
    throw new SaveDraftError(text.notificationSavedDraftFailed, {
      error: Error(result.errorMessage!),
    });
  }

  return updatedDraft;
};

export const deleteDrafts = async (draftIds: string[]): Promise<DraftTemplate[]> => {
  const drafts: DraftTemplate[] = await getDrafts();

  const restDrafts = drafts.filter((draft) => !!draft.id && !draftIds.includes(draft.id));

  const result: Result<boolean> = await browserApiService.saveDrafts(JSON.stringify(restDrafts));

  if (!result.value) {
    throw new DeleteDraftError(text.notificationDeleteDraftFailed, {
      error: Error(result.errorMessage!),
      draftIds,
    });
  }

  return restDrafts;
};

export const getThumbnailImgPath = async (iamPath: string): Promise<string | undefined> => {
  const result = await browserApiService.getThumbnailImage(iamPath);
  if (result.value === null) {
    throw new ThumbnailError(text.notificationThumbnailFailed, {
      error: Error(result.errorMessage!),
    });
  }

  return result.value;
};

export const getTopLevelAssemblyPath = (draftTopLevelFolder: string, draftAssembly: string): string => {
  // The  DA4I plugin expects the assembly path to include the top-folder name,
  // e.g. "Wall w Door\\Wall w Door.iam".
  const topLevelFolderPath = draftTopLevelFolder.replace(/\//g, '\\');
  const datasetFolderName = topLevelFolderPath.substring(topLevelFolderPath.lastIndexOf('\\') + 1);

  // Rely on the fact that the draft's assembly path begins with a directory separator.
  return `${datasetFolderName}${draftAssembly.replace(/\//g, '\\')}`;
};

export const getPathSeparator = (path: string): '\\' | '/' => (/\\/.test(path) ? '\\' : '/');

export const createFullPath = (topLevelFolder: string, relativePathToFile: string): string => {
  const separator = getPathSeparator(topLevelFolder);
  const rawJoinedPath = [topLevelFolder, relativePathToFile].join(separator);
  const normalizedPath = separator === '\\' ? rawJoinedPath.replace(/\\\\/g, '\\') : rawJoinedPath.replace(/\/\//g, '/');
  return normalizedPath;
};

const dcContextToDraftSourceContent = (
  productContext: TemplateContext,
): { topLevelFolder: string; inventorProject: string; assembly: string } => {
  const assembly = productContext.topLevelAssembly.substring(
    productContext.topLevelAssembly.indexOf(getPathSeparator(productContext.topLevelAssembly)),
    productContext.topLevelAssembly.length,
  );
  const topLevelFolder = productContext.topLevelAssembly.substring(
    0,
    productContext.topLevelAssembly.indexOf(getPathSeparator(productContext.topLevelAssembly)),
  );
  return {
    topLevelFolder,
    inventorProject: productContext.projectFile,
    assembly,
  };
};

const validateProductOutputType = (output: TemplateOutput) =>
  Object.values(OutputType).some((value) => value.toUpperCase() === output.type.toUpperCase());

// TODO: Temporary solution until we have API calls to properly reconstruct folder path
const createDraftFolderPathFromProduct = async (product: DynamicContentProduct): Promise<MetaInfoPath> => {
  const productFolderUrns = product.context.workspace.folderPath.split('/');
  const productFolders: MetaInfo[] = [];
  const promiseToRetrieveAllProjectFolders: Promise<ProjectFolder[]>[] = [];
  for (let i = 0; i < productFolderUrns.length; i++) {
    if (i === 0) {
      // Initially, we want to retrieve all folders, not subfolders
      // So we don't pass a urn
      promiseToRetrieveAllProjectFolders.push(getProjectFolders(product.tenancyId));
    } else {
      // Moving on to subfolders
      promiseToRetrieveAllProjectFolders.push(getProjectFolders(product.tenancyId, productFolderUrns[i - 1]));
    }
  }

  // Handling all promises at once
  const allProjectFolders: ProjectFolder[] = (await Promise.all(promiseToRetrieveAllProjectFolders)).reduce(
    (acc, next) => acc.concat(next),
    [],
  );

  // Extracting only the URNs we need
  productFolderUrns.forEach((urn) => {
    allProjectFolders.some((projectFolder) => {
      if (projectFolder.urn === urn) {
        // Pushing folders to start of the array to create the parentPath below
        productFolders.unshift({
          id: projectFolder.urn,
          name: projectFolder.title,
        });
      }
    });
  });

  // Creating MetaInfoPath object
  const folderPublishLocation: MetaInfoPath = {
    id: productFolders[0].id,
    name: productFolders[0].name,
    parentPath: Array.from(
      productFolders.slice(1), // removing folder product was published in
      (folder) => ({ id: folder.id, name: folder.name } as MetaInfo),
    ),
  };

  return folderPublishLocation;
};

export const productTemplateToDraftTemplate = async (
  accountInfo: MetaInfo | undefined,
  projectInfo: MetaInfo | undefined,
  selectedDownloadLocation: string,
  product: DynamicContentProduct,
): Promise<DraftTemplate> => {
  // SOURCE CONTENT
  const { topLevelFolder, inventorProject, assembly } = dcContextToDraftSourceContent(product.context);

  // THUMBNAIL
  const thumbnail = await getThumbnailImgPath(
    createFullPath(`${selectedDownloadLocation}${getPathSeparator(selectedDownloadLocation)}${topLevelFolder}`, assembly),
  );

  // INPUTS
  const inputs: DraftTemplateInput[] = [];
  product.inputs.forEach((input) => {
    if (input.type === TemplateInputType.IProperty) {
      inputs.push({ ...input, id: input.name });
    } else {
      inputs.push(input);
    }
  });

  let rules: InputRule[] = [];
  if (product.rules) {
    const productRules = product.rules;
    rules = Object.keys(productRules).map((rule) => ({
      key: rule,
      code: productRules[rule].code,
      errorMessage: productRules[rule].errorMessage,
      label: productRules[rule].ruleLabel,
    }));
  } else if (product.rulesKey) {
    rules = await getContentFromObjectKey<InputRule[]>(product.tenancyId, product.rulesKey);
  }

  // CODE BLOCK WORKSPACE
  let codeBlocksWorkspace: SerializedBlocklyWorkspaceState = {};
  if (product.codeBlocksWorkspace) {
    codeBlocksWorkspace = JSON.parse(product.codeBlocksWorkspace);
  } else if (product.codeBlocksWorkspaceKey) {
    codeBlocksWorkspace = await getContentFromObjectKey<SerializedBlocklyWorkspaceState>(
      product.tenancyId,
      product.codeBlocksWorkspaceKey,
    );
  }

  // OUTPUTS
  const outputs: DraftTemplateOutput[] = product.outputs.map((output) => {
    if (validateProductOutputType(output)) {
      return {
        type: output.type.toUpperCase() as OutputType,
        options: output.options,
      };
    }
    throw new InvalidDraftOutputError(text.invalidOutputTypeOnProduct, { output });
  });

  // PUBLISH LOCATION
  const accountPublishLocation: MetaInfo = {
    id: accountInfo?.id || '',
    name: accountInfo?.name || '',
  };
  const projectPublishLocation: MetaInfo = {
    id: projectInfo?.id || '',
    name: projectInfo?.name || '',
  };
  // Extracting and building folder path from project
  const folderPublishLocation = await createDraftFolderPathFromProduct(product);

  // Create the draft
  const newDraftFromProduct: DraftTemplate = {
    id: '', // Generated automatically when you save draft
    name: product.name,

    // Source Content and thumbnail
    topLevelFolder: `${selectedDownloadLocation}${getPathSeparator(selectedDownloadLocation)}${topLevelFolder}`,
    inventorProject,
    assembly,
    thumbnail: thumbnail || '',

    // Inputs, rules, and outputs
    inputs,
    rules,
    codeBlocksWorkspace,
    outputs,

    // Publish location
    account: accountPublishLocation,
    project: projectPublishLocation,
    folder: folderPublishLocation,

    lastUpdated: 0,
  };

  return newDraftFromProduct;
};

export const convertDCInputstoDraftTemplateInputs = (inputs: DynamicContentInput[]): DraftTemplateInput[] =>
  inputs.map((input) => {
    if (input.type === TemplateInputType.IProperty) {
      return { id: input.name, ...input };
    }
    return input;
  });

export const draftToDCTemplate = (
  draft: DraftTemplate,
  thumbnail: string,
  datasetUrn: string,
  engineVersion: string,
  codeBlocksWorkspaceKey?: string,
  rulesKey?: string,
  engine = 'DA4I',
  workspaceLocation = 'BIMDOCS',
): DynamicContentProduct => {
  // Template Inputs
  const inputs: DynamicContentInput[] = draft.inputs.reduce<DynamicContentInput[]>((allInputs, input) => {
    if (isDraftTemplateIProperty(input)) {
      const iPropertyInput: IPropertyInput = {
        category: input.category,
        label: input.label,
        name: input.name,
        value: input.value,
        readOnly: input.readOnly,
        type: input.type,
        visible: input.visible,
      };
      allInputs.push(iPropertyInput);
    } else {
      allInputs.push(input);
    }
    return allInputs;
  }, []);

  const outputs = draft.outputs.map((output) => ({
    type: output.type,
    options: output.options,
  }));

  const dcProduct: DynamicContentProduct = {
    name: draft.name,
    schemaVersion: 1,
    dataSetLocation: datasetUrn,
    tenancyId: draft.project.id,
    thumbnail,
    context: {
      projectFile: draft.inventorProject,
      topLevelAssembly: getTopLevelAssemblyPath(draft.topLevelFolder, draft.assembly),
      engine: {
        location: engine,
        version: engineVersion,
      },
      workspace: {
        location: workspaceLocation,
        folderPath: getFullFolderPath(draft.folder),
      },
    },
    rulesKey,
    codeBlocksWorkspaceKey,
    inputs,
    outputs,
  };

  return dcProduct;
};

export const toDraftTemplateInputParameter = (param: InventorParameter): DraftTemplateInputParameter => {
  const type = getInputTypeFromInventorParameter(param);

  switch (type) {
    case TemplateInputType.Boolean: {
      const input: BooleanInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: /true/i.test(param.value),
        onChange: [],
      };

      return input;
    }
    case TemplateInputType.Text: {
      const input: TextInput = {
        type: TemplateInputType.Text,
        value: removeDoubleQuotes(param.value),
        unit: param.unitType,
        name: param.name,
        label: param.label ?? '',
        visible: true,
        readOnly: false,
      };

      return input;
    }
    case TemplateInputType.Numeric: {
      const input: NumericInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        value: Number(param.value),
        unit: param.unitType,
        onChange: [],
      };

      return input;
    }
    case TemplateInputType.MultiValueNumeric: {
      const input: MultiValueNumericInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        values: arrayToNumericArray(param.options || []),
        unit: param.unitType,
        value: Number(param.value),
        onChange: [],
        allowCustomValue: true,
      };

      return input;
    }
    case TemplateInputType.MultiValueText: {
      const input: MultiValueTextInput = {
        type,
        visible: true,
        readOnly: false,
        label: param.label ?? '',
        name: param.name,
        values: removeDoubleQuotesFromStringArray(param.options as string[]),
        unit: param.unitType,
        value: removeDoubleQuotes(param.value),
      };

      return input;
    }
  }
};

export const toDraftTemplateIProperty = (p: IProperty): DraftTemplateIProperty => ({
  id: p.id,
  type: TemplateInputType.IProperty,
  category: p.category,
  name: p.displayName,
  label: p.label ?? '',
  readOnly: false,
  value: p.value,
  visible: true,
});

export const getFullFolderPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.id) ?? []), metaInfo.id]?.join('/');

export const getFullFolderNamedPath = (metaInfo: MetaInfoPath): string =>
  [...(metaInfo.parentPath?.map((p) => p.name) ?? []), metaInfo.name]?.join('/');

export const publishDraftTemplate = async (draftTemplate: DraftTemplate): Promise<DraftTemplatePublishResult> =>
  await publishProductFromDraft(draftTemplate);
