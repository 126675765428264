import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const SidebarThumbnailContainer = styled(Box)(() => ({
  margin: '0px 12px',
  textAlign: 'center',
  padding: '12px',
  backgroundColor: 'white',
  borderRadius: '3px',
  border: '1px solid #aaa',
  width: '350px',
  height: '230px',
}));

export const SidebarThumbnailImage = styled('img')(() => ({
  maxHeight: '200px',
  maxWidth: '100%',
  width: 'auto',
  height: 'auto',
}));
