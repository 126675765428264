import { RevitElement } from 'types/scopeOfWork';
import { viewerServiceConstants } from '../../global/constants/viewerService';

/**
 * Aggregate element by categories
 * @param pdb
 * @param targetCategories
 */
export default function userFunction(pdb: any, targetCategories: string[]): { [key: string]: RevitElement[] } {
  const buckets: { [key: string]: RevitElement[] } = {};
  let categoryAttrId = 0;
  pdb.enumAttributes((i: number, _attrDef: any, attrRaw: any[]) => {
    const name = attrRaw[0];
    const category = attrRaw[1];

    if (category === viewerServiceConstants.PROPERTY_CATEGORY && name === viewerServiceConstants.CATEGORY) {
      categoryAttrId = i;
    }
  });

  pdb.enumObjects((pdbId: number) => {
    let categoryValId = 0;

    pdb.enumObjectProperties(pdbId, (attrId: number, valId: number) => {
      if (attrId === categoryAttrId) {
        categoryValId = valId;
      }
    });

    if (categoryValId) {
      const category = pdb.getAttrValue(categoryAttrId, categoryValId);

      for (let i = 0; i < targetCategories.length; i++) {
        const targetCategory = targetCategories[i];

        if (category !== targetCategory) {
          continue;
        }

        if (!buckets.hasOwnProperty(targetCategory)) {
          buckets[targetCategory] = [];
        }
        buckets[targetCategory].push(pdb.getObjectProperties(pdbId, null, false, null));
        return;
      }
    }
  });

  return buckets;
}
