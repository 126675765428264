export type AnchorHorizontalPosition = typeof ANCHOR_HORIZONTAL_POSITIONS[keyof typeof ANCHOR_HORIZONTAL_POSITIONS];
export type AnchorVerticalPosition = typeof ANCHOR_VERTICAL_POSITIONS[keyof typeof ANCHOR_VERTICAL_POSITIONS];

export const ANCHOR_VERTICAL_POSITIONS = {
  TOP: 'top',
  BOTTOM: 'bottom',
} as const;

export const ANCHOR_HORIZONTAL_POSITIONS = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
} as const;
