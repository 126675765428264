import {
  DeprecatedDraftTemplate,
  DraftTemplate,
  DraftTemplateIProperty,
  DraftTemplateInput,
  DraftTemplateInputParameter,
} from 'mid-addin-lib';
import { BooleanInput, TemplateInputType } from 'mid-types';

export function isDraftTemplateIProperty(input: DraftTemplateInput): input is DraftTemplateIProperty {
  return input.type === TemplateInputType.IProperty;
}

export function isNotDraftTemplateIProperty(input: DraftTemplateInput): input is DraftTemplateInputParameter {
  return !isDraftTemplateIProperty(input);
}

export function isBooleanInput(input: DraftTemplateInput): input is BooleanInput {
  return input.type === TemplateInputType.Boolean;
}

export const isDeprecatedDraft = (draft: DraftTemplate | DeprecatedDraftTemplate): draft is DeprecatedDraftTemplate =>
  draft.hasOwnProperty('parameters') || draft.hasOwnProperty('iProperties');
