import { createNewFolder } from 'mid-addin-lib';
import { DynamicContentProduct } from 'mid-types';
import { BIM360FolderCreationError, logError } from 'mid-utils';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import ModalContext from '../../context/Modal.context';
import NotificationContext from '../../context/NotificationStore/Notification.context';
import { NOTIFICATION_STATUSES } from '../../context/NotificationStore/notificationStore.types';
import { ModalState, initialModalState } from '../../context/modalStore';
import text from '../../mid-react-common.text.json';
import { TreeItem } from '../MIDTree/MIDTree.types';
import CreateNewFolderModal from './CreateNewFolderModal';
import { filterProductsByParentFolder, getEmptyFolderDataView, getEmptyProductDataView } from './ProductFolderBrowser.utils';

interface UseProductFolderBrowserProps {
  projectId: string;
  rootFolders: TreeItem[];
  rootFoldersLoading: boolean;
  rootFoldersError: Error | null;
  selectedFolderTreeElement?: TreeItem;
  products: DynamicContentProduct[] | null;
  productsLoading: boolean;
  productsError: Error | null;
  onProductClick?: (contentId: string | undefined) => void;
}
interface UseProductFolderBrowserState {
  emptyFolderDataView: ReactNode;
  emptyProductDataView: ReactNode;
  productsInSelectedFolder: DynamicContentProduct[] | null;
  handleProductClick: (contentId: string | undefined) => () => void;
  handleNewFolderClick: () => void;
  newFolderTreeNodeCreated: TreeItem | undefined;
}

const confirmButtonLabel = text.buttonCreateFolder;
const cancelButtonLabel = text.buttonCancel;
const createNewFolderModalState: ModalState = {
  ...initialModalState,
  confirmButtonLabel,
  cancelButtonLabel,
};

export const useProductFolderBrowser = ({
  projectId,
  rootFolders,
  rootFoldersLoading,
  rootFoldersError,
  selectedFolderTreeElement,
  products,
  productsLoading,
  productsError,
  onProductClick,
}: UseProductFolderBrowserProps): UseProductFolderBrowserState => {
  const { setModalState } = useContext(ModalContext);
  const { showNotification } = useContext(NotificationContext);

  const emptyFolderDataView: ReactNode = getEmptyFolderDataView(
    projectId,
    rootFolders,
    rootFoldersLoading,
    rootFoldersError,
  );

  const selectedFolderUrn: string | undefined = selectedFolderTreeElement?.id;
  const selectedFolderLabel = selectedFolderTreeElement?.label;
  const productsInSelectedFolder: DynamicContentProduct[] | null = filterProductsByParentFolder(products, selectedFolderUrn);
  const emptyProductDataView: ReactNode = getEmptyProductDataView(
    productsLoading,
    productsError,
    selectedFolderUrn,
    productsInSelectedFolder,
  );
  const [newFolderName, setNewFolderName] = useState<string | undefined>();
  const [newFolderTreeNodeCreated, setNewFolderTreeNodeCreated] = useState<TreeItem | undefined>();
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  const _handleCreateNewFolder = useCallback(async () => {
    try {
      setIsCreatingFolder(true);
      if (!newFolderName || !selectedFolderUrn) {
        throw new BIM360FolderCreationError(text.missingRequiredParamaterCreateNewFolder, {
          newFolderName,
          selectedFolderUrn,
        });
      }
      const response = await createNewFolder(projectId, selectedFolderUrn, newFolderName);
      const newFolderTreeNode: TreeItem = {
        id: response.urn,
        parentId: response.parentUrn,
        label: response.title,
        value: response.urn,
        isExpandable: true,
        children: [],
      };
      setNewFolderTreeNodeCreated(newFolderTreeNode);
      setNewFolderName(undefined);
      showNotification({
        message: text.successCreatingNewFolder,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (error) {
      logError(error);
      showNotification({
        message: text.errorCreatingNewFolder,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    } finally {
      setIsCreatingFolder(false);
    }
  }, [newFolderName, projectId, selectedFolderUrn, showNotification]);

  const newFolderMessageCallback = useCallback(
    () => (
      <CreateNewFolderModal
        newFolderName={newFolderName}
        setNewFolderName={setNewFolderName}
        selectedFolderLabel={selectedFolderLabel}
        isCreatingFolder={isCreatingFolder}
      />
    ),
    [newFolderName, setNewFolderName, selectedFolderLabel, isCreatingFolder],
  );

  const handleNewFolderClick = () => {
    setModalState({
      ...createNewFolderModalState,
      isOpen: true,
      message: newFolderMessageCallback(),
      disableConfirmButton: !newFolderName,
      onConfirmCallback: _handleCreateNewFolder,
    });
  };

  useEffect(() => {
    if (typeof newFolderName !== 'undefined') {
      setModalState({
        ...createNewFolderModalState,
        isOpen: true,
        message: newFolderMessageCallback(),
        disableConfirmButton: !newFolderName,
        onConfirmCallback: _handleCreateNewFolder,
      });
    }
  }, [newFolderName, _handleCreateNewFolder, newFolderMessageCallback, setModalState]);

  const handleProductClick = (contentId: string | undefined) => () => {
    if (onProductClick) {
      onProductClick(contentId);
    }
  };

  return {
    emptyFolderDataView,
    emptyProductDataView,
    productsInSelectedFolder,
    handleProductClick,
    handleNewFolderClick,
    newFolderTreeNodeCreated,
  };
};
