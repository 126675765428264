import { ReactNode } from 'react';
import { useAnalytics } from './useAnalytics';

interface UserAnalyticsProviderProps {
  children: ReactNode;
  userId?: string | null;
  token?: string;
}

const UserAnalyticsProvider: React.FC<UserAnalyticsProviderProps> = ({ children, userId, token }) => {
  // Set analytics id to Bugsnag and Launchdarkly
  useAnalytics(userId, token);

  return <>{children}</>;
};

export default UserAnalyticsProvider;
