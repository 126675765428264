import { styled } from '@mui/material/styles';

export const SummaryTableWrapper = styled('table')`
  border-collapse: collapse;
  border: 2px solid ${({ theme }) => theme.colors.primary.charcoal100};
  width: 100%;

  tr:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colors.primary.charcoal100};
  }

  td {
    text-align: left;
    padding: ${({ theme }) => `${theme.var.paddingBase}px`};
  }

  td:first-of-type {
    width: 40%;
  }
`;
