import { HostApi, Result } from '../interfaces/cefSharp';
import { Base64Info } from '../interfaces/fileSystem';
import { GenerateOutputsResult } from '../interfaces/inventorAutomation';
import { InventorProperties } from '../interfaces/inventorProperties';
import { CachedFileInfo } from '../interfaces/localCache';
import { SelectedRFAInstance } from '../interfaces/selectedRFA';
import { Environment } from 'mid-types';

declare let hostApi: HostApi;

declare global {
  interface Window {
    chrome: {
      webview: {
        hostObjects: {
          hostApi: any;
        };
      };
    };
    hostApi: any;
  }
}

class BrowserApiService implements HostApi {
  private isCefSharp?: boolean = void 0;

  private webview2Host = window.chrome?.webview?.hostObjects.hostApi;

  isCefSharpAvailable(): boolean | undefined {
    if (this.isCefSharp === void 0) {
      // if the browser is Cefsharp, we will register a hostApi object from C#, so window['hostApi'] should exist.
      // if the browser is Webview2, the window['hostApi'] should be undefined
      if (window['hostApi']) {
        this.isCefSharp = true;
      } else {
        this.isCefSharp = false;
      }
    }
    return this.isCefSharp;
  }

  async getOAuth2Token(): Promise<string | null> {
    return this.isCefSharpAvailable() ? hostApi.getOAuth2Token() : await this.webview2Host.getOAuth2Token();
  }

  async loadDrafts(): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.loadDrafts();
    }
    const result = await this.webview2Host.loadDrafts().sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveDrafts(drafts: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.saveDrafts(drafts);
    }
    const result = await this.webview2Host.saveDrafts(drafts).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async loadProductDefinitions(): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.loadProductDefinitions();
    }
    const result = await this.webview2Host.loadProductDefinitions().sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async saveProductDefinitions(productDefinitions: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.saveProductDefinitions(productDefinitions);
    }
    const result = await this.webview2Host.saveProductDefinitions(productDefinitions).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getThumbnailImage(documentPath: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getThumbnailImage(documentPath);
    }
    const result = await this.webview2Host.getThumbnailImage(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getPartOrAssemblyProperties(path: string): Promise<Result<InventorProperties>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getPartOrAssemblyProperties(path);
    }
    const result = await this.webview2Host.getPartOrAssemblyProperties(path).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    const properties: InventorProperties = JSON.parse(result.content);
    return { value: properties, errorMessage: result.errorMessage };
  }

  async selectFolder(topFolder: string): Promise<string> {
    return this.isCefSharpAvailable()
      ? hostApi.selectFolder(topFolder)
      : await this.webview2Host.selectFolder(topFolder).sync();
  }

  async selectFile(topFolder: string, filter: string, multiSelect: boolean): Promise<string[]> {
    return this.isCefSharpAvailable()
      ? hostApi.selectFile(topFolder, filter, multiSelect)
      : await this.webview2Host.selectFile(topFolder, filter, multiSelect).sync();
  }

  async getEnvironment(): Promise<Environment> {
    return this.isCefSharpAvailable() ? hostApi.getEnvironment() : await this.webview2Host.getEnvironment().sync();
  }

  async getModelStates(documentPath: string): Promise<Result<string[]>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getModelStates(documentPath);
    }
    const result = await this.webview2Host.getModelStates(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getDcApiUrl(): Promise<string> {
    return this.isCefSharpAvailable() ? hostApi.getDcApiUrl() : await this.webview2Host.getDcApiUrl().sync();
  }

  async getMIDWebAppUrl(): Promise<string> {
    return this.isCefSharpAvailable() ? hostApi.getMIDWebAppUrl() : await this.webview2Host.getMIDWebAppUrl().sync();
  }

  async fileToBase64String(filePath: string): Promise<Result<Base64Info>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.fileToBase64String(filePath);
    }
    const result = await this.webview2Host.fileToBase64String(filePath).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async compressFolder(folderPath: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.compressFolder(folderPath);
    }
    const result = await this.webview2Host.compressFolder(folderPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async extractZipFileToFolder(zipFile: string, targetFolderPath: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.extractZipFileToFolder(zipFile, targetFolderPath);
    }
    const result = await this.webview2Host.extractZipFileToFolder(zipFile, targetFolderPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async generateOutputs(
    topFolderPath: string,
    documentPath: string,
    inputs: string,
    requestedOutputs: string,
  ): Promise<GenerateOutputsResult> {
    if (this.isCefSharpAvailable()) {
      return hostApi.generateOutputs(topFolderPath, documentPath, inputs, requestedOutputs);
    }
    const result = await this.webview2Host.generateOutputs(topFolderPath, documentPath, inputs, requestedOutputs).sync();
    const outputsResult: GenerateOutputsResult = { success: result.success, report: result.report };
    if (result.outputFiles) {
      outputsResult.outputFiles = [];
      for (const proxy of result.outputFiles) {
        outputsResult.outputFiles.push(this.getValueFromProxy({ type: '', modelState: '', filePath: '' }, proxy));
      }
    }
    return outputsResult;
  }

  async insertRFA(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
  ): Promise<void> {
    if (this.isCefSharpAvailable()) {
      hostApi.insertRFA(tenancyId, contentId, variantId, rfaSignedUrl, familyName, category, engineVersion);
    } else {
      await this.webview2Host.insertRFA(tenancyId, contentId, variantId, rfaSignedUrl, familyName, category, engineVersion);
    }
  }

  async downloadFileFromUrl(url: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.downloadFileFromUrl(url);
    }
    const result = await this.webview2Host.downloadFileFromUrl(url).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async downloadFileFromUrlWithName(url: string, fileName: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.downloadFileFromUrlWithName(url, fileName);
    }
    const result = await this.webview2Host.downloadFileFromUrlWithName(url, fileName).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getLocallyCached(key: string): Promise<CachedFileInfo[]> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getLocallyCached(key);
    }
    const resultString = await this.webview2Host.getLocallyCached(key).sync();
    return JSON.parse(resultString);
  }

  async downloadFileToLocalCache(
    key: string,
    signedUrl: string,
    name: string,
    type: string,
    metaDataJson: string | null,
  ): Promise<void> {
    return this.isCefSharpAvailable()
      ? hostApi.downloadFileToLocalCache(key, signedUrl, name, type, metaDataJson)
      : await this.webview2Host.downloadFileToLocalCache(key, signedUrl, name, type, metaDataJson);
  }

  async writeToCache(key: string, type: string, metaDataJson: string): Promise<void> {
    return this.isCefSharpAvailable()
      ? hostApi.writeToCache(key, type, metaDataJson)
      : await this.webview2Host.writeToCache(key, type, metaDataJson);
  }

  async getAssemblyVersion(): Promise<string> {
    return this.isCefSharpAvailable() ? hostApi.getAssemblyVersion() : await this.webview2Host.getAssemblyVersion().sync();
  }

  async openExternalUrl(url: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.openExternalUrl(url);
    }
    const result = await this.webview2Host.openExternalUrl(url).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async deleteFile(filePath: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.deleteFile(filePath);
    }
    const result = await this.webview2Host.deleteFile(filePath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async openDraftTemplateDocument(documentPath: string, inputs: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.openDraftTemplateDocument(documentPath, inputs);
    }
    const result = await this.webview2Host.openDraftTemplateDocument(documentPath, inputs).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async isDocumentOpenInTheEditor(documentPath: string): Promise<Result<boolean>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.isDocumentOpenInTheEditor(documentPath);
    }
    const result = await this.webview2Host.isDocumentOpenInTheEditor(documentPath).sync();
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async checkAndGenerateThumnailInBase64(filePath: string, documentPath: string): Promise<Result<Base64Info>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.checkAndGenerateThumnailInBase64(filePath, documentPath);
    }
    const result = await this.webview2Host.checkAndGenerateThumnailInBase64(filePath, documentPath).sync();
    if (result.errorMessage) {
      return { value: null, errorMessage: result.errorMessage };
    }
    let base64: Base64Info = { name: '', base64: '' };
    base64 = this.getValueFromProxy(base64, result.content);
    return { value: base64, errorMessage: result.errorMessage };
  }

  async getApplicationVersionNumber(): Promise<string> {
    return this.isCefSharpAvailable()
      ? hostApi.getApplicationVersionNumber()
      : await this.webview2Host.getApplicationVersionNumber().sync();
  }

  async saveToFile(content: string, fileName: string, fileExtension: string): Promise<Result<string>> {
    if (this.isCefSharpAvailable()) {
      return hostApi.saveToFile(content, fileName, fileExtension);
    }
    const result = await this.webview2Host.saveToFile(content, fileName, fileExtension);
    return { value: result.content, errorMessage: result.errorMessage };
  }

  async getSelectedRFAInstance(): Promise<SelectedRFAInstance> {
    if (this.isCefSharpAvailable()) {
      return hostApi.getSelectedRFAInstance();
    }
    const result = await this.webview2Host.getSelectedRFAInstance().sync();
    return JSON.parse(result);
  }

  getValueFromProxy(value: any, proxy: any): any {
    for (const property in value) {
      value[property] = proxy[this.capitalizeFirstLetter(property)];
    }
    return value;
  }

  capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  async editInstance(
    tenancyId: string,
    contentId: string,
    variantId: string,
    rfaSignedUrl: string,
    familyName: string,
    category: string,
    engineVersion: string,
  ): Promise<void> {
    if (this.isCefSharpAvailable()) {
      hostApi.editInstance(tenancyId, contentId, variantId, rfaSignedUrl, familyName, category, engineVersion);
    } else {
      await this.webview2Host.editInstance(
        tenancyId,
        contentId,
        variantId,
        rfaSignedUrl,
        familyName,
        category,
        engineVersion,
      );
    }
  }
}

export default new BrowserApiService();
